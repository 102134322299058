import React from 'react';
import Close from '../../../../assets/img/svg/Close';

export default class TagMultipleElement extends React.Component {
    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.handleSelect(this.props.value);
    }
    
    render() {
        return (
            <div 
                onClick={this.handleClick} 
                selected={this.props.isSelected}
                value={this.props.value}
                index={this.props.index}
                class={this.props.isSelected ? 'filter-badge-tag-selected filter-badge-tag' : 'filter-badge-tag'}
            >

                <span class="mr-2">{this.props.label}</span>

                <Close fill="#2B2B2B" width="8"/>

            </div>
        )
    }
}
