import React from 'react';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import H1 from '../../elements/title/H1';
import PmyBtn from '../../button/PmyBtn';
import Add from '../../../../assets/img/svg/Add';
import PlayerVideo from '../../../../assets/img/svg/animate/PlayerVideo';
import Loader from '../../../components/elements/Loader';
import FullModal from '../../../components/partials/modals/FullModal';
import PosterEN from '../../../../assets/img/png/illustrations/posters/poster-writing-franklin-fr.png';
import PosterFR from '../../../../assets/img/png/illustrations/posters/poster-writing-franklin-en.png';
import VideoOnboardingEN from '../../../../assets/video/onboarding-writing-franklin-en.mov';
import VideoOnboardingFR from '../../../../assets/video/onboarding-writing-franklin-fr.mov';

class Onboarding extends React.Component {

    render() {

        const { t } = this.props;

        const video = 
            <div class="video-container-onboarding d-flex align-items-center justify-content-center position-relative w-100 cursor-pointer" style={ i18n.language !== 'fr' ? { backgroundImage: 'url(' + PosterFR + ')' } : { backgroundImage: 'url(' + PosterEN + ')' } }>
                <div class="play-btn position-relative"><PlayerVideo/></div>
                <span class="video-tooltip position-absolute color-light rounded py-1 px-3">{t('writingFranklin.onboarding.tooltipVideo')}</span>
            </div>;
        
        return (
            <div className="block-onboarding mt-3">
                <H1 title={t('writingFranklin.onboarding.h1')} className="mb-3"/>
                <PmyBtn onClick={this.props.handleCreateNewSubject} type="button" btnIsMediumPmyOutlineLight textBtn={t('writingFranklin.sidebar.ctaNewArticle')} title={t('writingFranklin.sidebar.ctaNewArticle')} iconBtnBefore={<Add width="14" fill="#673AB7"/>} className="fz-16-index"/>
                <div class="onboarding-content mt-4 pt-3">
                    <p class="fw-600 mb-2">{t('writingFranklin.onboarding.informations')}</p>
                    <p class="mb-5">{t('writingFranklin.onboarding.informations2')}</p>
                    <FullModal modalShowed={true} trigger={video}>
                        {/* <Loader loaderDisplayed content={t('actions.loading')} className="position-absolute h-100 w-100"/> */}
                        <video width="100%" height="100%" poster={i18n.language !== 'fr' ? PosterFR : PosterEN} autoplay controls class="video-onboarding rounded" style={{ zIndex: 1 }}>
                            <source src={i18n.language !== 'fr' ? VideoOnboardingEN : VideoOnboardingFR} type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                    </FullModal>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Onboarding);