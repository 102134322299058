import React from 'react';
import i18n from '../../../../i18n';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Container, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Blob from '../../../../assets/img/svg/decorating/Blob';
import Dots from '../../../../assets/img/svg/decorating/Dots';
import H1 from '../../../components/elements/title/H1';
import Input from '../../../components/form/Input';
import Checkbox from '../../../components/form/Checkbox';
import PmyBtn from '../../../components/button/PmyBtn';
import EyeShowHide from '../../../../assets/img/svg/switch/EyeShowHide';
import ArrowTextLink from '../../../components/elements/link/ArrowTextLink';
import Alert from '../../../components/elements/Alert';

class SignUp extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            firstname: '',
            lastname: '',
            company: '',
            password: '',
            privacy: 0,
            laDepeche: 0,
            newsletter: 0,
            pwdDefaultType: 'password',
            success: false,
            alertIsShowed: false,
            emailIsAlreadyTaken: false
        }
        this.handleEmail = this.handleEmail.bind(this);
        this.handleFirstName = this.handleFirstName.bind(this);
        this.handleLastName = this.handleLastName.bind(this);
        this.handleCompany = this.handleCompany.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.handleInputType = this.handleInputType.bind(this);
        this.handlePrivacy = this.handlePrivacy.bind(this);
        this.handleLaDepeche = this.handleLaDepeche.bind(this);
        this.handleNewsletter = this.handleNewsletter.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        var token = localStorage.getItem('af_token');
        if(token) {
            this.props.history.push(i18n.t('url.home'));
        }
    }

    customHeadElement() {
        return (
            <Helmet>
                <title>{this.props.t('title.signUp')}</title>
                <meta name="description" content={this.props.t('description.signUp')}/>
                <meta name="robots" content="noindex, follow"/>
            </Helmet>
        );
    }

    handleEmail(e) {
        this.setState({
            email: e.target.value
        });
    }

    handleFirstName(e) {
        this.setState({
            firstname: e.target.value
        });
    }

    handleLastName(e) {
        this.setState({
            lastname: e.target.value
        });
    }

    handleCompany(e) {
        this.setState({
            company: e.target.value
        });
    }

    handlePassword(e) {
        this.setState({
            password: e.target.value
        });
    }

    handleInputType() {
        this.setState({ pwdDefaultType: this.state.pwdDefaultType === 'password' ? 'text' : 'password' });
    }

    handlePrivacy() {
        this.setState({ privacy: this.state.privacy === 0 ? 1 : 0 });
    }

    handleLaDepeche() {
        this.setState({ laDepeche: this.state.laDepeche === 0 ? 1 : 0 });
    }

    handleNewsletter() {
        this.setState({ newsletter: this.state.newsletter === 0 ? 1 : 0 });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.handleCloseAlert();
        if(this.state.email.length > 0 && this.state.password.length > 0 && this.state.privacy && this.state.firstname.length > 0 && this.state.lastname.length > 0 && this.state.company.length > 0) {
            fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/signup', {
                method: 'POST',
                body: JSON.stringify({ 
                    email: this.state.email, 
                    password: this.state.password,
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                    company: this.state.company,
                    privacy: this.state.privacy ? 1 : 0,
                    laDepeche: this.state.laDepeche ? 1 : 0,
                    newsletter: this.state.newsletter ? 1 : 0,
                    language:i18n.languages[0]
                })
            })
            .then(res => {
                return res.json();
            })
            .then(res => {
                if(res.error) {
                    console.log(res.error);
                } 
                else {
                    if(res.message === 'This username already exist') {
                        this.setState({
                            alertIsShowed: true,
                            emailIsAlreadyTaken: true
                        });
                    }
                    if (res.message === 'Password should have Caps,                          Special chars, Numbers') {
                        this.setState({
                            alertIsShowed: true
                        });
                    }
                    else {
                        if(this.state.laDepeche) {
                            fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/register-newsletter', {
                                method: 'POST',
                                body: JSON.stringify({ 
                                    firstname: this.state.firstname,
                                    email: this.state.email
                                })
                            })
                        }
                        this.setState({
                            success: true,
                            alertIsShowed: true
                        });
                    }
                }
            })
        }
    }

    handleCloseAlert() {
        this.setState({
            success: false,
            alertIsShowed: false,
            emailIsAlreadyTaken: false
        });
    }

    render() {
        
        const { t } = this.props;

        const general = ' pt-5 px-4 px-md-5 mx-auto position-relative bgc-light border-right border-left';

        return (
            <div id="signUp" class={this.props.bannerIsActive ? 'layout-style-banner pb-0' : 'layout-style pb-0'}>

                {this.customHeadElement()}

                {this.state.success && <Alert onClick={this.handleCloseAlert} className={this.state.alertIsShowed && !this.props.bannerIsActive ? 'alert-msg-visible alert-msg-no-banner' : this.state.alertIsShowed ? 'alert-msg-visible' : ''} alertId="successMessage" msg={t('alert.signUp.success')}/> }
                {this.state.emailIsAlreadyTaken && <Alert onClick={this.handleCloseAlert} className={this.state.alertIsShowed && !this.props.bannerIsActive ? 'alert-msg-visible alert-msg-no-banner' : this.state.alertIsShowed ? 'alert-msg-visible' : ''} alertId="errorMessage" msg={t('alert.signUp.emailAlreadyTaken')}/> }
                
                <Blob blob="3" yellow className="blob-1"/>
                <Container className="px-0 position-relative">
                    <Col xs="12" sm="10" lg="8" xl="6" className={this.props.bannerIsActive ? 'h-100-vh-banner' + general : 'h-100-vh' + general}>
                        <Blob blob="2" red className="blob-2"/>
                        <Dots className="dots-1 d-none d-sm-block"/>
                        <H1 className="mb-5" title={t('sign.register.h1')}/>
                        <form onSubmit={this.handleSubmit} method="POST" id="signUpForm">
                            <Input 
                                onChange={this.handleFirstName} 
                                value={this.state.firstname}
                                type="text" 
                                label={t('form.label.firstname')}
                                for="firstname"
                                required={true}
                                infoMsg={this.state.firstname.length < 1 && t('alert.form.fieldRequired')}
                            />
                            <Input 
                                onChange={this.handleLastName} 
                                value={this.state.lastname}
                                type="text" 
                                label={t('form.label.lastname')}
                                for="lastname"
                                required={true}
                                infoMsg={this.state.lastname.length < 1 && t('alert.form.fieldRequired')}
                            />
                            <Input 
                                onChange={this.handleCompany} 
                                value={this.state.company}
                                type="text" 
                                label={t('form.label.company')}
                                labelInfo={t('form.label.companyInfo')}
                                for="company"
                                required={true}
                                infoMsg={this.state.company.length < 1 && t('alert.form.fieldRequired')}
                            />
                            <Input 
                                onChange={this.handleEmail} 
                                value={this.state.email}
                                type="email" 
                                label={t('form.label.email')}
                                for="email"
                                required={true}
                                infoMsg={this.state.email.length < 1 ? t('alert.form.fieldRequired') : !this.state.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/) && t('alert.form.patternEmail')}
                            />
                            <Input 
                                containerStyle="input-password-column"
                                onChange={this.handlePassword} 
                                value={this.state.password}
                                type={this.state.pwdDefaultType} 
                                label={t('form.label.password')}
                                labelInfo={[t('form.label.passwordPattern1'), <br class="d-block d-sm-none"/>, t('form.label.passwordPattern2')]}
                                minLength={8} 
                                for="password"
                                onClick={this.handleInputType}
                                inputHasIcon={<EyeShowHide width="16" icon={this.state.pwdDefaultType === 'text' ? 'hide' : null}/>} 
                                required={true}
                                infoMsg={!this.state.password.match(/^(?=.*\d)(?=.*[a-zA-Z0-9]).{8,}$/) && t('alert.form.patternPassword')}
                            />
                            <Checkbox 
                                label={[ t('form.checkbox.labelTcs-1'), <Link to={t('url.tcs')} target="_blank" rel="noopener" title={t('titleElementBrowser.tcs')} class="fz-16">{t('form.checkbox.labelTcs-2')}</Link>, <em class="fz-14 ml-1">{t('form.checkbox.labelTcs-3')}</em>]} 
                                onChange={this.handlePrivacy} 
                                for="checkPrivacy"
                                value={this.state.privacy} 
                                required={true}
                                className="pb-3"
                            />
                            {i18n.language === 'fr' &&
                                <Checkbox 
                                    label={[ t('form.checkbox.labelLaDepeche'), <em class="fz-14">{t('form.checkbox.labelLaDepecheInfos')}</em>]} 
                                    onChange={this.handleLaDepeche} 
                                    for="checkLaDepeche"
                                    value={this.state.laDepeche}
                                    className="pb-3"
                                />
                            }   
                            <Checkbox 
                                label={[ t('form.checkbox.labelNewsletter'), <em class="fz-14">{t('form.checkbox.labelNewsletterRhythm')}</em>]} 
                                onChange={this.handleNewsletter} 
                                for="checkNewsletterSignUp"
                                value={this.state.newsletter} 
                                className="mb-3 pb-3"
                            />
                            <PmyBtn 
                                type="submit" 
                                title={!this.state.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/) || !this.state.password.match(/^(?=.*\d)(?=.*[a-zA-Z0-9]).{8,}$/) || this.state.privacy === 0 ? t('titleElementBrowser.form.registerError') : t('sign.register.cta')}
                                isDisabled={!this.state.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/) || !this.state.password.match(/^(?=.*\d)(?=.*[a-zA-Z0-9]).{8,}$/) || this.state.privacy === 0 || this.state.success} 
                                btnIsMediumPmyFull 
                                textBtn={t('sign.register.cta')} 
                                className="w-sm-100"
                            />
                        </form>
                        <div class="d-flex flex-column mt-5 pt-4 pb-5 border-top">
                            <ArrowTextLink redirectTo={t('url.signIn')} textLink={t('sign.register.linkSignIn')}/>
                        </div>
                    </Col>
                </Container>
                <Blob blob="2" blue className="blob-3 d-none d-xl-block"/>
            </div>
        )
    }
}

export default withTranslation()(SignUp);