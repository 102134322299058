import React from 'react';

export default class Pro extends React.Component {

    render() {

        switch(this.props.icon) {

            case 'desktop':
                return (
                    <svg class="pro-member" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.056 40.037" width="20">
                        <g transform="translate(0 -85.287)">
                            <path d="M55.008,90.3a4.993,4.993,0,0,0-4.751,6.553L46.2,99.438A6.245,6.245,0,0,1,38,98.123l-3.976-4.87a5,5,0,1,0-8.051,0L22,98.124a6.245,6.245,0,0,1-8.194,1.315L9.751,96.857A5,5,0,1,0,4.563,100.3l2.955,17.753A1.251,1.251,0,0,0,8.751,119.1H51.258a1.251,1.251,0,0,0,1.233-1.047L55.446,100.3a5.008,5.008,0,0,0-.438-10Z" fill="#ffc107"/>
                            <ellipse cx="21.214" cy="6.26" rx="21.214" ry="6.26" transform="translate(8.79 111.554)" fill="#ffa000"/>
                            <path d="M86.47,313.652C75.3,313.652,64,311.073,64,306.14s11.3-7.512,22.47-7.512,22.47,2.579,22.47,7.512S97.637,313.652,86.47,313.652Zm0-12.52c-13.112,0-19.973,3.255-19.973,5.008s6.861,5.008,19.973,5.008,19.973-3.255,19.973-5.008-6.861-5.007-19.973-5.007Z" transform="translate(-56.465 -188.328)" fill="#ffd54f"/>
                        </g>
                    </svg>
                    /*
                    <svg class="pro-member" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 139.815 63.841" width="52">
                        <g transform="translate(33 -191.455)">
                            <g transform="translate(-6.397 198.426)">
                                <path d="M114.193,133.252c-1.1-16.229-53.365-15.125-56.931-15.031-44.533.523-54.018-10.942-56-15.239L8.5,125.28.981,129.7c1.1,16.231,53.367,15.125,56.931,15.032,44.146-.519,53.846,10.739,55.946,15.121l-7.174-21.371Z" transform="translate(-0.981 -102.982)" fill="#e94969" />
                                <text transform="translate(44.747 35.979)" fill="#fff" font-size="20" font-family="Assistant-ExtraBold, Assistant" font-weight="900" letter-spacing="0.05em">
                                    <tspan x="0" y="0">PRO</tspan>
                                </text>
                            </g>
                            <g transform="translate(-33 106.159)">
                                <path d="M55.008,90.3a4.993,4.993,0,0,0-4.751,6.553L46.2,99.438a6.245,6.245,0,0,1-8.2-1.315l-3.976-4.87a5,5,0,1,0-8.051,0L22,98.124a6.245,6.245,0,0,1-8.194,1.315L9.751,96.857A4.953,4.953,0,0,0,10,95.312,5,5,0,1,0,4.563,100.3l2.955,17.753A1.251,1.251,0,0,0,8.751,119.1H51.258a1.251,1.251,0,0,0,1.233-1.047L55.446,100.3a5.008,5.008,0,0,0-.438-10Z" fill="#ffc107" />
                                <ellipse cx="21.214" cy="6.26" rx="21.214" ry="6.26" transform="translate(8.79 111.554)" fill="#ffa000" />
                                <path d="M86.47,313.652c-11.167,0-22.47-2.579-22.47-7.512s11.3-7.512,22.47-7.512,22.47,2.579,22.47,7.512S97.637,313.652,86.47,313.652Zm0-12.52c-13.112,0-19.973,3.255-19.973,5.008s6.861,5.008,19.973,5.008,19.973-3.255,19.973-5.008S99.582,301.133,86.47,301.133Z" transform="translate(-56.465 -188.328)" fill="#ffd54f" />
                            </g>
                        </g>
                    </svg>
                    */
                );

            case 'mobile':
                return (
                    <svg class="pro-member" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.056 40.037" width="12">
                        <g transform="translate(0 -85.287)">
                            <path d="M55.008,90.3a4.993,4.993,0,0,0-4.751,6.553L46.2,99.438A6.245,6.245,0,0,1,38,98.123l-3.976-4.87a5,5,0,1,0-8.051,0L22,98.124a6.245,6.245,0,0,1-8.194,1.315L9.751,96.857A5,5,0,1,0,4.563,100.3l2.955,17.753A1.251,1.251,0,0,0,8.751,119.1H51.258a1.251,1.251,0,0,0,1.233-1.047L55.446,100.3a5.008,5.008,0,0,0-.438-10Z" fill="#ffc107"/>
                            <ellipse cx="21.214" cy="6.26" rx="21.214" ry="6.26" transform="translate(8.79 111.554)" fill="#ffa000"/>
                            <path d="M86.47,313.652C75.3,313.652,64,311.073,64,306.14s11.3-7.512,22.47-7.512,22.47,2.579,22.47,7.512S97.637,313.652,86.47,313.652Zm0-12.52c-13.112,0-19.973,3.255-19.973,5.008s6.861,5.008,19.973,5.008,19.973-3.255,19.973-5.008-6.861-5.007-19.973-5.007Z" transform="translate(-56.465 -188.328)" fill="#ffd54f"/>
                        </g>
                    </svg>
                )

        }

    }
}