import React from 'react';
import { refreshTokenFnc } from '../../../../utils/refreshToken';
import { withTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import KebabMenu from '../../../../assets/img/svg/KebabMenu';
import PmyBtn from '../../button/PmyBtn';
import ReloadCreditsModal from '../../../components/partials/modals/ReloadCreditsModal';

class Section extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            tagParamIsOpen: false,
            content: '',
            contentLength: null,
            minLength: 150,
            isFocus: false,
            type: 'paragraph',
            isLoading: false,
            interactId: 0,
            prevLength: 0,
            noCredits: false,
            modalCredits: false,
        }
        this.handleUpdate = this.handleUpdate.bind(this);
        this.generateContent = this.generateContent.bind(this);
        this.setH2 = this.setH2.bind(this);
        this.setH3 = this.setH3.bind(this);
        this.setText = this.setText.bind(this);
        this.openMenuSetTag = this.openMenuSetTag.bind(this);
        this.closeMenuSetTag = this.closeMenuSetTag.bind(this);
        this.blockIsFocus = this.blockIsFocus.bind(this);
        this.blockIsNotFocus = this.blockIsNotFocus.bind(this);
        this.tick = this.tick.bind(this);
        this.timer = null;
        this.handleOpenReloadCredits = this.handleOpenReloadCredits.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    handleUpdate(e) {
        var content = e.target.value;
        this.setState({
            content: content,
            contentLength: content.length
        }, () => {
            var token = localStorage.getItem('af_token');
            fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/articles/sections/write', {
                headers: {
                    'Authorization': token
                },
                method: 'POST',
                body: JSON.stringify({ id: this.props.sectionId, content: content })
            })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                this.props.refreshArticleContent(this.props.articleId);
                this.props.refreshLastSaved();
            })
        });
    }

    componentDidMount() {
        this.setState({
            type: this.props.type,
            content: this.props.content
        });
    }

    generateContent(e) {
        if (this.state.content.length === 0 && e.keyCode === 8) {
            this.props.handleDeleteSection(this.props.sectionId);
            this.blockIsNotFocus();
        }
        if (this.props.countdown < 1 && this.state.content.length >= this.state.minLength) {
            if(e.type == 'mousedown' || e.keyCode === 9) {
                this.setState({
                    isLoading: true
                });
                var token = localStorage.getItem('af_token');
                fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/generate-text', {
                    headers: {
                        'Authorization': token
                    },
                    method: 'POST',
                    body: JSON.stringify({ prompt: this.state.content.split(' ').splice(-60).join(' '), tokens: this.props.tokens, creativity: this.props.creativity })
                })
                .then((res) => {
                    return res.json();
                })
                .then((resParent) => {
                    if(resParent.message.no_credit) {
                        this.setState({
                            noCredits: true,
                            modalCredits: true
                        });
                    }
                    else {
                        this.props.handleCountDown(30);
                        this.setState({
                            isLoading: false,
                            prevLength: (this.state.content + resParent['message']['choices'][0]['text']).length
                        }, () => {


                            var token = localStorage.getItem('af_token');
                            fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/articles/sections/write', {
                                headers: {
                                    'Authorization': token
                                },
                                method: 'POST',
                                body: JSON.stringify({ id: this.props.sectionId, content: this.state.content})
                            })
                            .then((res) => {
                                return res.json();
                            })
                            .then((res) => {
                                this.props.refreshLastSaved();
                                this.props.refreshCredits(resParent.message.credits);
                            })
                        })
                        this.timer = setInterval(() => this.tick(), 1000);
                    }
                })
                .catch((error) => {
                    refreshTokenFnc(this.generateContent, false);
                })
                e.preventDefault();  
            }
        }
    }

    tick() {
        const current = this.props.countdown;
        if (current === 0) {
            this.transition();
        } 
        else {
            this.props.handleCountDown(current - 1);
        } 
    }
    
    transition() {
        clearInterval(this.timer);
        this.timer = null;
    }

    setH2() {
        var token = localStorage.getItem('af_token');
        fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/articles/sections/update-type', {
            headers: {
                'Authorization': token
            },
            method: 'POST',
            body: JSON.stringify({ id: this.props.sectionId, type: 'h2' })
        })
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            this.props.refreshArticleContent(this.props.articleId);
            this.setState({
                type: 'h2'
            }, () => {
                this.closeMenuSetTag();
                this.interactBlock.blur();
                this.props.refreshLastSaved();
            });
        })
    }

    setH3() {
        var token = localStorage.getItem('af_token');
        fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/articles/sections/update-type', {
            headers: {
                'Authorization': token
            },
            method: 'POST',
            body: JSON.stringify({ id: this.props.sectionId, type: 'h3' })
        })
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            this.props.refreshArticleContent(this.props.articleId);
            this.setState({
                type: 'h3'
            }, () => {
                this.closeMenuSetTag();
                this.interactBlock.blur();
                this.props.refreshLastSaved();
            });
        })
    }

    setText() {
        var token = localStorage.getItem('af_token');
        fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/articles/sections/update-type', {
            headers: {
                'Authorization': token
            },
            method: 'POST',
            body: JSON.stringify({ id: this.props.sectionId, type: 'paragraph' })
        })
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            this.props.refreshArticleContent(this.props.articleId);
            this.setState({
                type: 'paragraph'
            }, () => {
                this.closeMenuSetTag();
                this.interactBlock.blur();
                this.props.refreshLastSaved();
            });
        })
    }

    openMenuSetTag() {
        this.setState({
            tagParamIsOpen: true
        });
    }

    closeMenuSetTag() {
        this.setState({
            tagParamIsOpen: false
        });
    }

    blockIsFocus() {
        this.setState({
            isFocus: true
        });
    }

    blockIsNotFocus() {
        this.setState({
            isFocus: false
        });
    }

    handleOpenReloadCredits() {
        this.setState({
            modalCredits: this.state.modalCredits ? false : true
        });
    }

    hideModal() {
        this.setState({
            modalCredits: false
        });
    }

    render() {

        const { t } = this.props;

        const nbrChar = (this.state.prevLength + this.state.minLength) - this.state.content.length;

        const tagOpt = ' article-menu-tag position-absolute flex-column rounded';
        
        return (
            <div class="section" onBlur={this.blockIsNotFocus}>
                <div class="d-flex flex-row">
                    <TextareaAutosize 
                        onChange={this.handleUpdate}
                        onFocus={this.blockIsFocus} 
                        onKeyDown={this.generateContent}
                        placeholder={this.state.type === 'h2' ? t('form.input.placeholderWritingFranklin.h2') : this.state.type === 'h3' ? t('form.input.placeholderWritingFranklin.h3') : t('form.input.placeholderWritingFranklin.paragraph')}
                        class={this.state.type === 'h2' ? 'h2' : this.state.type === 'h3' && 'h3'}
                        data-id={this.props.sectionId}
                        value={this.state.content}
                        autoFocus={true}
                    />
                    <div onFocus={this.openMenuSetTag} onBlur={this.closeMenuSetTag} tabIndex={0} ref={c => this.interactBlock = c} class="state-interaction-element">
                        <KebabMenu/>
                        <ul class={this.state.tagParamIsOpen ? 'd-flex' + tagOpt : 'd-none' + tagOpt}>
                            <li onClick={this.setH2} data-type="h2" class={this.state.type === 'h2' && 'tag-active'}>{t('writingFranklin.writingMode.setTag.h2')}</li>
                            <li onClick={this.setH3} data-type="h3" class={this.state.type === 'h3' && 'tag-active'}>{t('writingFranklin.writingMode.setTag.h3')}</li>
                            <li onClick={this.setText} data-type="paragraph" class={this.state.type === 'paragraph' && 'tag-active'}>{t('writingFranklin.writingMode.setTag.paragraph')}</li>
                        </ul>
                    </div>
                </div>
                {this.state.isFocus && this.state.type === 'paragraph' &&
                    <div class="d-flex flex-column flex-lg-row">
                        {this.state.noCredits ?
                            <>
                                <PmyBtn onMouseDown={this.handleOpenReloadCredits} type="button" btnIsSmallPmyFull textBtn={t('writingFranklin.heading.mainCta')} className="fz-16-index"/>
                                <p class="ml-lg-3 mt-2 mt-lg-0 fz-14 color-danger">{t('writingFranklin.writingMode.noCredits')}</p>
                            </>
                        :
                            <>
                                <PmyBtn onMouseDown={this.generateContent} type="button" isDisabled={(this.state.content.length < this.state.minLength) || (this.props.countdown > 1) || (this.state.content.length < this.state.prevLength + this.state.minLength)} btnIsSmallPmyFull textBtn={this.state.isLoading ? <div class="spinner spinner-light"></div> : t('writingFranklin.writingMode.ctaGenerate')} title={this.state.isLoading ? t('actions.loading') : t('writingFranklin.writingMode.ctaGenerate')} className="generate-content fz-16-index"/>
                                {this.props.countdown < 1 && this.state.content.length < this.state.minLength && 
                                    <p class="ml-lg-3 mt-2 mt-lg-0 fz-14">{t('writingFranklin.writingMode.countChar1') + (this.state.minLength - this.state.content.length) + t('writingFranklin.writingMode.countChar2')}</p>
                                }
                                {this.props.countdown > 1 && 
                                    <p class="ml-lg-3 mt-2 mt-lg-0 fz-14">{t('writingFranklin.writingMode.countdown1') + this.props.countdown + t('writingFranklin.writingMode.countdown2')}</p>
                                }
                                {this.state.content.length > this.state.minLength && this.props.countdown < 1 && this.state.content.length < this.state.prevLength + this.state.minLength && 
                                    <p class="ml-lg-3 mt-2 mt-lg-0 fz-14">{t('writingFranklin.writingMode.rewriteChar', { nbrChar } )}</p>
                                }
                            </>
                        }
                    </div>
                }
                {this.state.isFocus &&
                    <div class="add-section mt-3 mb-3">
                        <div onMouseDown={this.props.handleAddSection} class="state-interaction-element mx-auto fz-32" title={t('titleElementBrowser.writingFranklin.newBlock')}>+</div>
                    </div>
                }
                {this.state.noCredits &&
                    <ReloadCreditsModal 
                        modalShowed={this.state.modalCredits}   
                        onHide={true}
                        handleHideModal={this.hideModal} 
                        modalTitle={t('writingFranklin.heading.mainCta')} 
                        isPro={this.props.isPro}
                    />
                }
            </div>
        )
    }
}

export default withTranslation()(Section);