import React from 'react';

export default class PlayerVideo extends React.Component {

    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.946 78.946" width="80" height="80">
                <g transform="translate(8 8)">
                    <path d="M31.473,0A31.473,31.473,0,1,0,62.946,31.473,31.473,31.473,0,0,0,31.473,0ZM42.351,33.141,26.614,42.977a1.967,1.967,0,0,1-3.01-1.668V21.638a1.967,1.967,0,0,1,3.01-1.668L42.351,29.8a1.968,1.968,0,0,1,0,3.337Z"/>
                </g>
            </svg>
        )
    }
}