import React from 'react';
import Modal from 'react-bootstrap/Modal';

export default class FullModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modalShowed: false
        }
        this.handleShowModal = this.handleShowModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleShowModal() {
		this.setState({ 
            modalShowed: true
        });
	}

    handleCloseModal() {
		this.setState({ 
            modalShowed: false
        });
    }

    render() {
        return (
            <>

                <div onClick={this.handleShowModal} class={this.props.triggerClassName ? this.props.triggerClassName + ' w-100 h-100' : 'w-100 h-100'}>{this.props.trigger}</div>

                <Modal show={this.state.modalShowed} onHide={this.handleCloseModal} dialogClassName="full-modal">
                    {this.props.children}
                </Modal>

            </>
        )
    }
}