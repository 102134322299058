import React from 'react';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import { Redirect, Link } from 'react-router-dom';
import PlayerVideo from '../../../assets/img/svg/animate/PlayerVideo';
import Loader from '../../components/elements/Loader';
import H1 from '../../components/elements/title/H1';
import H2 from '../../components/elements/title/H2';
import Tick from '../../../assets/img/svg/Tick';
import PmyBtn from '../../components/button/PmyBtn';
import FormRequestFranklin from '../../components/form/FormRequestFranklin';
import ArrowTextLink from '../../components/elements/link/ArrowTextLink';
import FullModal from '../../components/partials/modals/FullModal';
import Logo1 from '../../../assets/img/png/partners/logo-argos-veterinaire.png';
import Logo2 from '../../../assets/img/png/partners/logo-sud-ouest.png';
import Logo3 from '../../../assets/img/png/partners/logo-3Dnatives.png';
import Logo4 from '../../../assets/img/png/partners/logo-timetonic.png';
import Logo5 from '../../../assets/img/png/partners/logo-urgo.png';
import Logo6 from '../../../assets/img/png/partners/logo-open-ai.png';
import CardTestimonial from '../../components/elements/cards/CardTestimonial';
import HeleneHemon from '../../../assets/img/png/testimonials/helene-hemon.png';
import ArnaudCamut from '../../../assets/img/png/testimonials/arnaud-camut.png';
import MelanieRenard from '../../../assets/img/png/testimonials/melanie-renard.png';
import Feature1 from '../../../assets/img/png/illustrations/features/feature-1.jpg';
import Feature1FR from '../../../assets/img/png/illustrations/features/feature-1-fr.jpg';
import Feature2 from '../../../assets/img/png/illustrations/features/feature-2.jpg';
import Feature2FR from '../../../assets/img/png/illustrations/features/feature-2-fr.jpg';
import Feature3 from '../../../assets/img/png/illustrations/features/feature-3.jpg';
import Feature3FR from '../../../assets/img/png/illustrations/features/feature-3-fr.jpg';
import FeaturesCta from '../../../assets/img/svg/switch/FeaturesCta';
import CardBlog from '../../components/elements/cards/CardBlog';
import Accordion from 'react-bootstrap/Accordion';
import AccordionItem from '../../components/elements/AccordionItem';
import WaveSectionSeparator from '../../../assets/img/svg/decorating/waves/WaveSectionSeparator';
import Blob from '../../../assets/img/svg/decorating/Blob';
import Dots from '../../../assets/img/svg/decorating/Dots';

const accordionItems = {
"fr": 
    [
        {
            questions: [
                {
                    key: "0",
                    question: "Est-ce que je peux tester Asking Franklin gratuitement ?",
                    content: <p>Vous pouvez effectivement utiliser l’outil gratuitement ! <br/>
                        Il suffit de vous inscrire et vous pourrez ainsi accéder à Asking Franklin :
                        <ul class="my-3">
                            <li>&bull; 3 requêtes par jour</li>
                            <li>&bull; 2000 crédits de rédaction à utiliser comme bon vous semble (ces crédits ne sont pas renouvelés)</li>
                        </ul>
                        Il vous est ensuite possible de souscrire à une offre payante si l’outil vous plait.
                    </p>
                },
                {
                    key: "1",
                    question: "Asking Franklin peut-il m’être utile si je ne suis pas très bon ou peu inspiré en rédaction ?",
                    content: <p>Absolument ! L’une des fonctionnalités majeures d’Asking Franklin est l’aide à la rédaction assistée par l’IA. <br/> 
                    Cela signifie qu’une proposition de texte vous est générée, que vous pouvez modifier ou valider à votre guise. Ainsi, vous n’êtes jamais à court d’idées !</p>
                },
                {
                    key: "2",
                    question: "Asking Franklin peut-il m’être utile si je suis un(e) rédacteur(rice) confirmé(e) ?",
                    content: <p>Effectivement, car même en étant un(e) rédacteur(rice) confirmé(e), Asking Franklin peut vous permettre d’explorer des pistes auxquelles vous n’auriez pas pensé ! D’ailleurs, la fonctionnalité de détection des recherches formulées par les internautes peut aussi vous donner des idées de création de contenus.</p>
                },
                {
                    key: "3",
                    question: "Pourquoi choisir Asking Franklin plutôt qu’un freelance ou une agence ?",
                    content: <p>Comme on le dit bien souvent, on n’est jamais mieux servi que par soi même ! Il existe de très bonnes agences et de très bons freelances, mais ils ne sont pas dans votre tête. Asking Franklin vous assiste personnellement, ainsi ce sont vos idées qui sont utilisées et vous pouvez choisir ce que vous souhaitez utiliser ou non. <br/> Et le coût est également bien inférieur !</p>
                },
                {
                    key: "4",
                    question: "À quelles fonctionnalités supplémentaires j'ai accès en version payante ?",
                    content: <p>Tout dépend de l’offre choisie. Un tableau récapitulatif juste ici vous permettra de connaitre les différences : <Link to="/fr/tarifs">voir les différentes offres</Link></p>
                },
                {
                    key: "5",
                    question: "Est-ce que je peux être remboursé ?",
                    content: <p>Si dans les 14 jours après votre première souscription vous n’êtes pas convaincu, vous pouvez nous contacter pour annuler votre abonnement et nous vous rembourserons. <br/> À partir du 15ème jour, le remboursement n’est plus possible et la mensualité est due, ainsi que celles entamées par la suite.</p>
                }
            ]
        }
    ],
"en": 
    [
        {
            questions: [
                {
                    key: "0",
                    question: "Can I test Asking Franklin for free ?",
                    content: <p>Absolutely, you can use the tool for free !<br/>
                        You just have to register and you will be able to access Asking Franklin :
                        <ul class="my-3">
                            <li>&bull; 3 requests per day</li>
                            <li>&bull; 2000 writing credits to use as you wish (these credits are not renewed)</li>
                        </ul>
                        It is then possible to subscribe to a paying offer if you like the tool.
                    </p>
                },
                {
                    key: "1",
                    question: "Can Asking Franklin help me if I'm not very good or inspired at writing ?",
                    content: <p>Absolutely ! One of the major features of Asking Franklin is the AI assisted writing. <br/> 
                    This means that a text proposal is generated for you, which you can modify or validate as you wish. By this way, you are never short of ideas !</p>
                },
                {
                    key: "2",
                    question: "Can Asking Franklin help me if I am an experienced writer ?",
                    content: <p>Yes, even if you are an experienced writer, Asking Franklin can help you to explore ideas you might not have thought of ! In fact, the search engine feature can also give you ideas for content creation.</p>
                },
                {
                    key: "3",
                    question: "Why choose Asking Franklin rather than a freelancer or an agency ?",
                    content: <p>As the saying goes, if you want something done, do it yourself ! There are some very good agencies and freelancers, but they are not in your head. Asking Franklin assists you personally, so it’s your ideas that are used and you can choose what you want to use or not. <br/> The cost is also much lower !</p>
                },
                {
                    key: "4",
                    question: "What additional features do I have access to with the paid version ?",
                    content: <p>It all depends on the package you choose. Our overview table will allow you to know the differences : <Link to="/en/pricing">see the different offers</Link></p>
                },
                {
                    key: "5",
                    question: "Can I get my money back ?",
                    content: <p>If you are not convinced within 14 days after your first subscription, you can contact us to cancel your subscription and we will refund you. <br/> From the 15th day onwards, the refund is no longer possible and the monthly payment is due, as well as all subsequent payments.</p>
                }
            ]
        }
    ]
}
    
class Home extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            keywordSearch: '',
            languageSearchCode: i18n.t('form.filters.languages.selectedCode'),
            countrySearchCode: i18n.t('form.filters.countries.selectedCode'),
            redirect: false,
            selectedCategoryIndex: 0
        }
        this.handleKeywordChange = this.handleKeywordChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.requestFanklin = this.requestFanklin.bind(this);
    }
    
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    customHeadElement() {
        return (
            <Helmet>
                <title>{this.props.t('title.homepage')}</title>
                <meta name="description" content={this.props.t('description.homepage')}/>
                <meta name="robots" content="index, follow"/>
            </Helmet>
        );
    }

    handleKeywordChange(e) {
        this.setState({
            keywordSearch: e.target.value
        });
    }

    handleCountryChange(value) {
        this.setState({
            countrySearchCode: value
        });
    }
    
    handleLanguageChange(value) {
        this.setState({
            languageSearchCode: value
        });
    }

    requestFanklin = () => {
        this.setState({
            redirect: true
        });
    }

    render() {

        const { t } = this.props;
        if(this.state.redirect) {
            return <Redirect to={ t('url.resultAF') + this.state.keywordSearch.replace(/ /g, '-') + '?lang=' + this.state.languageSearchCode + '&country=' + this.state.countrySearchCode}/>
        }

        const video = 
            <div class="video-container-home d-flex align-items-center justify-content-center position-relative w-100 cursor-pointer">
                <div class="play-btn position-relative"><PlayerVideo/></div>
                <span class="video-tooltip position-absolute color-light rounded py-1 px-3">{t('homepage.hero.discoverVideo')}</span>
            </div>;

        return (
            <div id="home">
                {this.customHeadElement()}
                <div id="top"></div>

                {/* Hero */}
                <section class={this.props.bannerIsActive ? 'page-section-1 pt-hero-banner position-relative' : 'page-section-1 pt-hero position-relative'}>
                    <Container className="d-flex flex-column flex-xl-row position-relative">
                        <Col xs="12" md="10" lg="9" xl="6" className="px-0 mx-auto">
                            <H1 
                                title={i18n.language !== 'fr' ? t('homepage.hero.h1a')
                                : [<span class="d-block mb-2 fw-600">{t('homepage.hero.h1a')}</span>, <span class="fz-26">{t('homepage.hero.h1b')}</span>]} 
                                className="catchphrase mb-4 lh-1"
                            />
                            <ul class="catch-features">
                                <li class="d-flex flex-row align-items-baseline mb-3 fz-20">
                                    <Tick width="16"/><span class="ml-2 pl-1">{t('homepage.hero.dots1')}</span>
                                </li>
                                <li class="d-flex flex-row align-items-baseline fz-20">
                                    <Tick width="16"/><span class="ml-2 pl-1">{t('homepage.hero.dots2')}</span>
                                </li>
                            </ul>
                            {
                                !this.props.isConnected ?
                                    <PmyBtn redirectTo={t('url.signUp')} linkIsLargePmyFull textLink={t('homepage.cta.1')} containerStyle="mt-5" customBtnClass="w-sm-100"/>
                                :
                                    <>
                                        <FormRequestFranklin
                                            selectedSavedCountry={t('form.filters.countries.selected')}
                                            selectedSavedLanguage={t('form.filters.languages.selected')}
                                            onSubmit={this.requestFanklin} 
                                            onChange={this.handleKeywordChange} 
                                            handleLanguageChange={this.handleLanguageChange}
                                            handleCountryChange={this.handleCountryChange}
                                            value={this.state.keywordSearch} 
                                            keyword={this.state.keywordSearch}
                                            hideLabel={true}
                                            isDisabled={this.state.keywordSearch.length === 0}
                                        />
                                        <ArrowTextLink redirectTo={t('url.editorWF')} textLink={t('homepage.hero.startWriting')} className="d-block mt-3 article-direct"/>
                                    </>
                            }
                        </Col>
                        <Col xs="12" md="10" lg="9" xl="6" className="col-video-ctn px-0 mt-6 mt-xl-0 pl-xl-5 mx-auto d-flex align-items-start justify-content-center justify-content-xl-end">
                            <Blob blob="2" yellow className="blob-2"/>
                            <Blob blob="3" blue className="blob-3"/>
                            <FullModal modalShowed={true} trigger={video} triggerClassName="ml-xl-5">
                                <Loader loaderDisplayed content={t('actions.loading')} className="position-absolute h-100 w-100"/>
                                <iframe width="100%" height="100%" src={i18n.language !== 'fr' ? 'https://www.youtube.com/embed/__9LuCu5o-4?autoplay=1&enablejsapi=1' : 'https://www.youtube.com/embed/__9LuCu5o-4?autoplay=1&enablejsapi=1'} style={{ borderRadius: '.5rem', zIndex: 1 }} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen"></iframe>
                            </FullModal>
                        </Col>
                        <Dots className="dots-1"/>
                    </Container>
                    <Blob blob="1" red className="blob-1 d-none d-lg-block"/>
                </section>

                {/* Partners */}
                <section class="page-section-2">
                    <Container>
                        <H2 className="mb-5 pb-5" title={t('homepage.h2.partners')}/>
                        <div class="grid-container">
                            <img src={Logo1} loading="lazy" alt="Logo Argos Vétérinaire" class="img-fluid partners partners-md"/>
                            <img src={Logo2} loading="lazy" alt="Logo Sud Ouest" class="img-fluid partners partners-lg"/>
                            <img src={Logo3} loading="lazy" alt="Logo La French Tech" class="img-fluid partners partners-md"/>
                            <img src={Logo4} loading="lazy" alt="Logo Sortvoices" class="img-fluid partners partners-lg"/>
                            <img src={Logo5} loading="lazy" alt="Logo Urgo" class="img-fluid partners partners-sm"/>
                            <img src={Logo6} loading="lazy" alt="Logo OpenAI" class="img-fluid partners partners-sm"/>
                        </div>
                    </Container>
                </section>

                {/* Testimonials */}
                <section class="page-section-3 position-relative px-0 px-lg-4 px-xl-5 my-5 py-5">
                    <Container className="slider-container position-relative d-flex flex-row justify-content-xl-around pb-3 pb-lg-0 px-0 px-xl-0">
                        <Col md="12" lg="4" xl="3" className="slider-item px-0">
                            <CardTestimonial
                                img={HeleneHemon}
                                testimonial={t('homepage.testimonials.1.text')}
                                fullname={t('homepage.testimonials.1.author')}
                                jobAndCompany={t('homepage.testimonials.1.about')}
                            />
                        </Col>
                        <Col md="12" lg="4" xl="3" className="slider-item px-0">
                            <CardTestimonial   
                                img={ArnaudCamut}
                                testimonial={t('homepage.testimonials.2.text')}
                                fullname={t('homepage.testimonials.2.author')}
                                jobAndCompany={t('homepage.testimonials.2.about')}
                            />
                        </Col>
                        <Col md="12" lg="4" xl="3" className="slider-item px-0">
                            <CardTestimonial 
                                img={MelanieRenard}
                                testimonial={t('homepage.testimonials.3.text')}
                                fullname={t('homepage.testimonials.3.author')}
                                jobAndCompany={t('homepage.testimonials.3.about')}
                            />  
                        </Col>
                        <Blob blob="3" purple className="blob-4"/>
                    </Container>
                </section>

                {/* Features */}
                <section class="page-section-4 position-relative py-5">
                    <WaveSectionSeparator className="wave-top-1"/>
                    <Container className="d-flex flex-column position-relative">
                        <Blob blob="2" blue className="blob-5"/>
                        <H2 title={t('homepage.h2.featuresMain')} className="py-5 my-5 text-center catchphrase"/>

                        {/* Feature 1 */}
                        <Row className="d-flex flex-column flex-lg-row justify-content-between my-5 pb-lg-5">
                            <Col md="12" lg="5" className="d-flex flex-column justify-content-center px-0">
                                <H2 
                                    className="mb-5" 
                                    title={
                                        i18n.language !== 'fr' ? 
                                            [<span class="fw-600 color-primary">Step 1 :</span>, <br/>, <span class="fw-600">Generate tons of content ideas</span>, ' from a single keyword']
                                        :
                                            [<span class="fw-600 color-primary">Étape 1 :</span>, <br/>, 'Puisez dans une ', <span class="fw-600">mine d’or d’idées de contenus </span>, 'basée sur ce que recherchent les internautes']
                                    }
                                />
                                <p class="fz-18 mb-5">
                                    {
                                        i18n.language !== 'fr' ?
                                            <>
                                                We provide you, from a single keyword, all the user requests on Google. It will help you to find new articles ideas that will fit to your audience’s requests. <br/>
                                                With a beautiful and effective visualization, save time on your writing process and find other content ideas.
                                            </>
                                        :
                                            <>
                                                20% des requêtes faites sur Google chaque jour sont nouvelles. Soyez directement connecté à ce que veulent vos clients ! Rentrez votre mot-clé, en un clic vous avez accès à leurs questions, les comparaisons qu’ils font... <br/>
                                                <span class="fw-600">Optimisez votre création</span> de contenu, de produits et de services en vous basant sur ce que veulent vos clients.
                                            </>
                                    }
                                </p>
                                <PmyBtn redirectTo={!this.props.isConnected ? t('url.signUp') : '#top'} linkIsLargePmyOutlineFull textLink={t('homepage.cta.features.1')} iconLinkBefore={<FeaturesCta icon="idea"/>} customBtnClass="w-sm-100"/>
                            </Col>
                            <Col md="12" lg="6" className="d-flex justify-content-center mt-5 mt-lg-0 px-0">
                                <img src={i18n.language === 'fr' ? Feature1FR : Feature1} alt={t('altImg.homepage.1')} class="img-fluid img-feature"/>
                            </Col>
                        </Row>

                        {/* Feature 2 */}
                        <Row className="d-flex flex-column flex-lg-row justify-content-between my-5 py-5">
                            <Col md="12" lg="6" className="d-flex justify-content-center order-1 order-lg-0 mt-5 mt-lg-0 px-0">
                                <Blob blob="2" yellow className="blob-6 d-none d-md-block"/>
                                <img src={i18n.language === 'fr' ? Feature2FR : Feature2} alt={t('altImg.homepage.2')} class="img-fluid img-feature"/>
                            </Col>
                            <Col md="12" lg="5" className="d-flex flex-column justify-content-center order-0 order-lg-1 px-0">
                                <H2 
                                    className="mb-5" 
                                    title={
                                        i18n.language !== 'fr' ? 
                                            [<span class="fw-600 color-primary">Step 2 :</span>, <br/>, 'Choose the most trendy subject with data and ', <span class="fw-600">engage your audience</span>]
                                        :
                                            [<span class="fw-600 color-primary">Étape 2 :</span>, <br/>, 'Choisissez le sujet tendance qui va faire ', <span class="fw-600">décoller votre engagement</span>]
                                    }
                                />
                                <p class="fz-18 mb-5">
                                    {
                                        i18n.language !== 'fr' ?
                                           <>
                                                For all requests found about your keyword, retrieve trends to help you to prioritize opportunities. It will able you to save time and find the best subject to write about : you will get a global and historical vision of search’s trends.
                                           </>
                                       :
                                           <>
                                                Identifiez l’intérêt de recherche parmi toutes les requêtes. <br/>
                                                Misez sur une question souvent posée pour y apporter une réponse ou une solution concrète. <br/>
                                                <span class="fw-600">Gagnez en efficacité et crédibilité</span> en ciblant les vraies questions de vos clients.
                                           </>
                                    }
                                </p>
                                <PmyBtn redirectTo={!this.props.isConnected ? t('url.signUp') : '#top'} linkIsLargePmyOutlineFull textLink={t('homepage.cta.features.2')} iconLinkBefore={<FeaturesCta icon="trends"/>} customBtnClass="w-sm-100"/>
                            </Col>
                        </Row>

                        {/* Feature 3 */}
                        <Row className="d-flex flex-column flex-lg-row justify-content-between my-5 pt-lg-5">
                            <Col md="12" lg="5" className="d-flex flex-column justify-content-center px-0">
                                <H2 
                                    className="mb-5" 
                                    title={
                                        i18n.language !== 'fr' ? 
                                            [<span class="fw-600 color-primary">Step 3 :</span>, <br/>, <span class="fw-600">Write your content 80% faster</span>, ' on any subject with our AI-assistant']
                                        :
                                            [<span class="fw-600 color-primary">Étape 3 :</span>, <br/>, 'Laissez notre IA rédiger et ', <span class="fw-600">économisez +80% de votre temps</span>]
                                    }
                                />
                                <p class="fz-18 mb-5">
                                    {
                                        i18n.language !== 'fr' ?
                                           <>
                                                Start typing some text and our writing assistant powered by AI will generate the content for you !
                                                We provide you seo and content subtitles features to help you rank in the SERP and writer better content. <br/>
                                                After that, you can also manage your articles in a single place and export it easily.
                                           </>
                                       :
                                           <>
                                                Écrivez-lui une portion de texte et elle s’occupera de générer le contenu pour vous !
                                                Avec nos fonctionnalités seo et de générations de contenus liés, soyez sûrs de ranker dans la SERP et d’écrire un contenu encore plus performant ! <br/>
                                                Gagnez du temps en créant du contenu, des produits et des services basés sur ce que veulent vos clients.
                                           </>
                                    }
                                </p>
                                <PmyBtn redirectTo={!this.props.isConnected ? t('url.signUp') : '#top'} linkIsLargePmyOutlineFull textLink={t('homepage.cta.features.3')} iconLinkBefore={<FeaturesCta icon="write" width="16"/>} customBtnClass="w-sm-100"/>
                            </Col>
                            <Col md="12" lg="6" className="img-feature-ctn d-flex justify-content-center mt-5 mt-lg-0 px-0">
                                <Blob blob="3" red className="blob-7"/>
                                <img src={i18n.language === 'fr' ? Feature3FR : Feature3} alt={t('altImg.homepage.3')} class="img-fluid img-feature"/>
                                <Dots className="dots-2"/>
                            </Col>
                        </Row>

                    </Container>
                    <WaveSectionSeparator className="wave-bottom-1"/>
                </section>

                {/* Usecases */}
                <section class="page-section-5 mt-6 position-relative">
                    <Blob blob="3" purple className="blob-8"/>
                    <Container className="px-0">
                        <H2 className="mb-5" title={[ t('homepage.h2.usecase1'), <br/>, t('homepage.h2.usecase2') ]}/>
                        <Row className="d-flex flex-column flex-lg-row align-items-center align-items-lg-start position-relative">
                            {i18n.language === 'fr' ?
                                <>
                                    <Col xs="12" sm="8" md="6" lg="4" className="blog-post-item px-0 mt-5 mr-lg-5">
                                        <CardBlog
                                            redirectTo="https://blog.askingfranklin.com/comment-optimiser-votre-redaction-pour-un-contenu-seo-friendly/"
                                            img="https://blog.askingfranklin.com/wp-content/uploads/2021/05/Comment-optimiser-votre-rédaction-pour-un-contenu-SEO-friendly.png"
                                            title="Comment optimiser votre rédaction pour un contenu SEO friendly ?"
                                            readMore="Voir le cas d'usage"
                                        />
                                    </Col>
                                    <Col xs="12" sm="8" md="6" lg="4" className="blog-post-item px-0 mt-5 mr-lg-5">
                                        <CardBlog
                                            redirectTo="https://blog.askingfranklin.com/comment-rediger-pour-etre-lu-et-augmenter-votre-engagement/"
                                            img="https://blog.askingfranklin.com/wp-content/uploads/2021/05/écrire-du-contenu-qui-engage-son-audience.jpg"
                                            title="Comment rédiger pour être lu et augmenter votre engagement ?"
                                            readMore="Voir le cas d'usage"
                                        />
                                        <Dots className="dots-3"/>
                                    </Col>
                                </>
                            :
                                <Col xs="12" sm="8" md="6" lg="4" className="blog-post-item px-0 mt-5 mr-lg-5">
                                    <CardBlog
                                        redirectTo="https://blog.askingfranklin.com/auto-write-a-super-engaging-blog-pos/"
                                        img="https://blog.askingfranklin.com/wp-content/uploads/2021/06/Auto-write-a-super-engaging-blog-post-in-lightning-speed.jpg"
                                        title="⚡️ Auto-write a super engaging blog post in lightning speed"
                                        readMore="See the use case"
                                    />
                                    <Dots className="dots-3"/>
                                </Col>
                            }
                        </Row>
                    </Container>
                </section>
                

                {/* FAQ */}
                <section class="page-section-6 position-relative">
                    <Container>
                        <H2 title={t('homepage.h2.faq')} className="pb-5"/>
                        <Row className="mx-0 my-5 w-100 d-flex flex-column">
                            <Dots className="dots-4 d-none d-lg-block"/>
                            <Col sm="12" className="question-faq d-flex flex-column text-left px-0">
                                <Accordion defaultActiveKey="-1">
                                    {
                                        i18n.languages && (i18n.languages[0] !== 'fr' || i18n.languages[0] !== 'en') ?  accordionItems[i18n.languages[0]][this.state.selectedCategoryIndex].questions.map((accordionItem) =>
                                            <AccordionItem eventKey={accordionItem.key} title={accordionItem.question} content={accordionItem.content}/>
                                        ) 
                                    : 
                                        accordionItems['fr'][this.state.selectedCategoryIndex].questions.map((accordionItem) =>
                                            <AccordionItem eventKey={accordionItem.key} title={accordionItem.question} content={accordionItem.content}/>
                                        )
                                    }
                                </Accordion>
                            </Col>
                            <Blob blob="1" blue className="blob-9"/>
                        </Row>
                    </Container>
                </section>

                {/* CTA */}
                <section class="page-section-7 position-relative d-flex flex-column align-items-center">
                    <H2 title={t('homepage.h2.cta')} className="my-5 pb-5"/>
                    <PmyBtn redirectTo={!this.props.isConnected ? t('url.signUp') : this.props.isPro ? '#top' : t('url.pricing')} linkIsLargePmyFull textLink={!this.props.isConnected ? t('homepage.cta.1') : t('homepage.cta.2')} customBtnClass="w-sm-100"/>
                </section>

            </div>
        )
    }    
}

export default withTranslation()(Home);