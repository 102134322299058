import React from 'react';

export default class KebabMenu extends React.Component {

    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3 15" width="3" height="15" fill="#2B2B2B">
                <g transform="translate(-183 -280)">
                    <circle cx="1.5" cy="1.5" r="1.5" transform="translate(183 280)"/>
                    <circle cx="1.5" cy="1.5" r="1.5" transform="translate(183 286)"/>
                    <circle cx="1.5" cy="1.5" r="1.5" transform="translate(183 292)"/>
                </g>
            </svg>
        )
    }
}