import React from 'react';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import H1 from '../../components/elements/title/H1';
import H2 from '../../components/elements/title/H2';
import H3 from '../../components/elements/title/H3';
import ToggleSwitch from '../../components/form/ToggleSwitch';
import FeaturesList from '../../components/elements/FeaturesList';
import PmyBtn from '../../components/button/PmyBtn';
import Reinsurance from '../../../assets/img/svg/switch/Reinsurance';
import Accordion from 'react-bootstrap/Accordion';
import AccordionItem from '../../components/elements/AccordionItem';
import ContactInfo from '../../components/elements/ContactInfo';
import Blob from '../../../assets/img/svg/decorating/Blob';
import Dots from '../../../assets/img/svg/decorating/Dots';
import WaveSectionSeparator from '../../../assets/img/svg/decorating/waves/WaveSectionSeparator';
import { loadStripe } from '@stripe/stripe-js';


const stripePromise = loadStripe('pk_live_WgN7E438RIWhbBUughEppG0S00G1SQfY87');

const accordionItems = {
"fr": 
    [
        {
            questions: [
                {
                    key: "0",
                    question: "À quoi correspond un crédit d'écriture ?",
                    content: <p><span class="d-block mb-3">Le crédit est la valeur utilisée pour pouvoir dialoguer avec notre IA d'assistance à la rédaction.</span>
                    Un crédit correspond à un caractère envoyé et à un caractère reçu. Par exemple, si vous envoyez 100 caractères et que vous en recevez 100, cela comptera donc 200 crédits.</p>
                },
                {
                    key: "1",
                    question: "Quelles solutions s'offrent à moi lorsque j'ai épuisé tous mes crédits ?",
                    content: <p><span class="d-block mb-3">Vous avez la possibilité de recharger votre solde de crédits à partir de plusieurs offres quand vous le désirez, ainsi vous pourrez toujours continuer à rédiger !</span>
                    Pour cela, une fois connecté, rendez-vous sur « <span class="fw-600">Mon compte</span> », puis dans « <span class="fw-600">Paramètres</span> » et enfin dans l'onglet « <span class="fw-600">Abonnement</span> », vous pourrez alors avoir accès à vos crédits. <br/>
                    Vous pouvez également recharger votre solde de crédits depuis la page de rédaction.</p>
                },
                {
                    key: "2",
                    question: "Quelles langues sont prises en charge ?",
                    content: <p><span class="d-block mb-3">Actuellement, nous prenons en charge <span class="fw-600">l’anglais, le français, l’allemand, l’espagnol, le néerlandais et l’italien.</span></span>
                    Concernant la partie de génération de contenu, <span class="fw-600">l’anglais et le français</span> sont actuellement pris en charge, mais nous développons également d’autres langues !</p>
                },
                {
                    key: "3",
                    question: "Est-ce que je peux utiliser Asking Franklin pour n’importe quel sujet ?",
                    content: <p>Oui, nous sommes en mesure de traiter n’importe quel sujet !</p>
                },
                {
                    key: "4",
                    question: "Quels sont les différents moyens de paiement disponibles ?",
                    content: <p>Vous pouvez payer par carte bancaire : <span class="fw-600">CB, MasterCard, Visa, American Express... etc.</span> Toutes les cartes dans le monde entier sont supportées.</p>
                },
                {
                    key: "5",
                    question: "Est-ce que je suis forcément engagé si je souscris à une offre payante ?",
                    content: <p><span class="d-block mb-3">Non, si Asking Franklin ne vous convient finalement pas, nous vous remboursons.</span>
                    Vous avez pour cela 14 jours à partir de la date de souscription pour nous en formuler la demande.</p>
                }
            ]
        }
    ],
"en": 
    [
        {
            questions: [
                {
                    key: "0",
                    question: "What is a writing credit ?",
                    content: <p><span class="d-block mb-3">A credit is the value used to be able to communicate with our writing assistant powered by AI.</span>
                    A credit corresponds to one character sent and one character received. For example, if you send 100 characters and receive 100, it will count 200 credits.</p>
                },
                {
                    key: "1",
                    question: "What are my options when all my credits are used ?",
                    content: <p><span class="d-block mb-3">You can reload your credits balance from several offers whenever you want, so you can keep writing !</span>
                    To do this, once connected, go to « <span class="fw-600">My account</span> », then on « <span class="fw-600">Parameters</span> » and finally in the tab « <span class="fw-600">Subscription</span> », you will be able to access your credits and recharge them. <br/>
                    You can also reload your credits balance from the <span class="fw-600">editing page</span>.</p>
                },
                {
                    key: "2",
                    question: "Which languages are supported ?",
                    content: <p><span class="d-block mb-3">Currently we support <span class="fw-600">english, french, german, spanish, dutch and italian languages.</span></span>
                    For the content generation part, only <span class="fw-600">english and french </span> languages are currently supported, but we are also developing other languages !</p>
                },
                {
                    key: "3",
                    question: "Can I use Asking Franklin for any topic ?",
                    content: <p>Yes, we are able to handle any topic !</p>
                },
                {
                    key: "4",
                    question: "Which payment methods are available ?",
                    content: <p>You can pay with your credit card : <span class="fw-600">CB, MasterCard, Visa, American Express... etc.</span> All credit card on the world are available.</p>
                },
                {
                    key: "5",
                    question: "Am I necessarily committed if I subscribe to a paid offer ?",
                    content: <p><span class="d-block mb-3">No, if Asking Franklin does not suit you, we will refund you.</span>
                    You have 14 days from the subscription date to request refund.</p>
                }
            ]
        }
    ]
}

class Pricing extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedPlan: 0,
            periodBase: i18n.t('funnel.pricing.toggleSwitch.period1'),
            switchPeriodSelected: this.props.alreadySelected ? true : false,
            currencyBase: i18n.t('funnel.pricing.toggleSwitch.currency1'),
            switchCurrencySelected: this.props.alreadySelected ? true : false,
            selectedCategoryIndex: 0,
        }
        this.handleSwitchPeriod = this.handleSwitchPeriod.bind(this);
        this.handleSwitchCurrency = this.handleSwitchCurrency.bind(this);
        this.selectStarter = this.selectStarter.bind(this);
        this.selectAdvanced = this.selectAdvanced.bind(this);
        this.selectExpert = this.selectExpert.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    customHeadElement() {
        return (
            <Helmet>
                <title>{this.props.t('title.pricing')}</title>
                <meta name="description" content={this.props.t('description.pricing')}/>
                <meta name="robots" content="index, follow"/>
            </Helmet>
        );
    }

    handleSwitchPeriod() {
        this.setState({
            periodBase: '',
            switchPeriodSelected: this.state.switchPeriodSelected ? false : true
        });
    }
    
    handleSwitchCurrency() {
        this.setState({
            currencyBase: '',
            switchCurrencySelected: this.state.switchCurrencySelected ? false : true
        }, () => {
            if (this.state.switchCurrencySelected) {
                console.log('currency conversion...');
            }
        });
    }

    formatManipulator($item, $var) {
        let isMonthly = this.state.switchPeriodSelected;
        let isEuro = this.state.switchCurrencySelected;
        let rate = 1.22;

        if ($item === 'yearly') {
            return (
                isEuro ? Math.floor($var / rate).toFixed(0) + '€' : '$' + $var
            );
        }

        if ($item === 'global') {
            return (
                (isMonthly && isEuro) ? Math.floor(($var * 0.8) / rate).toFixed(0) + '€'
                : (isMonthly && !isEuro) ? '$' + Math.floor($var * 0.8).toFixed(0)
                : (!isMonthly && isEuro) ? Math.floor($var / rate).toFixed(0) + '€'
                : (!isMonthly && !isEuro) && '$' + $var
            );
        }

    }

    async selectStarter() {
        const stripe = await stripePromise;
        var token = localStorage.getItem('af_token');
        fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/create-checkout-session', {
            headers: {
                'Authorization': token
            },
            method: 'POST',
            body: JSON.stringify({
                price_id: !this.state.switchPeriodSelected ? 'price_1J3dc9LB03GdYRbhFQw0QKRK' : 'price_1J3delLB03GdYRbhpt6hg9Il',
                mode: 'subscription',
                success_url: 'https://www.askingfranklin.com' + this.props.t('url.paymentConfirm'),
                cancel_url: 'https://www.askingfranklin.com' + this.props.t('url.pricing')
            })
        })
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            stripe.redirectToCheckout({
                sessionId: res.message
            });
        })
    }

    async selectAdvanced() {
        const stripe = await stripePromise;
        var token = localStorage.getItem('af_token');
        fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/create-checkout-session', {
            headers: {
                'Authorization': token
            },
            method: 'POST',
            body: JSON.stringify({
                price_id: !this.state.switchPeriodSelected ? 'price_1J3dd1LB03GdYRbhje0KYbBu' : 'price_1J3dfXLB03GdYRbhsYrWqRcv',
                mode: 'subscription',
                success_url: 'https://www.askingfranklin.com' + this.props.t('url.paymentConfirm'),
                cancel_url: 'https://www.askingfranklin.com' + this.props.t('url.pricing')
            })
        })
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            stripe.redirectToCheckout({
                sessionId: res.message
            });
        })
    }

    async selectExpert() {
        const stripe = await stripePromise;
        var token = localStorage.getItem('af_token');
        fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/create-checkout-session', {
            headers: {
                'Authorization': token
            },
            method: 'POST',
            body: JSON.stringify({
                price_id: !this.state.switchPeriodSelected ? 'price_1J3ddNLB03GdYRbhRphBJMyR' : 'price_1J3dg2LB03GdYRbh5gzAaTf2',
                mode: 'subscription',
                success_url: 'https://www.askingfranklin.com' + this.props.t('url.paymentConfirm'),
                cancel_url: 'https://www.askingfranklin.com' + this.props.t('url.pricing')
            })
        })
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            stripe.redirectToCheckout({
                sessionId: res.message
            });
        })
    }

    render() {

        const { t } = this.props;

        const isMonthly = this.state.switchPeriodSelected;
        const isEuro = this.state.switchCurrencySelected;

        const starter = 19, advanced = 39, expert = 69;

        return (
            <div id="pricing">
                {this.customHeadElement()}

                <section class={this.props.bannerIsActive ? 'page-section-1 pt-hero-banner position-relative' : 'page-section-1 pt-hero position-relative'}>
                    <Blob blob="1" red className="blob-1 d-none d-md-block"/>
                    <Container className="px-0 position-relative">
                        <Blob blob="3" blue className="blob-2"/>
                        <H1 title={t('funnel.pricing.h1')}/>
                        <p class="mt-4 mb-5 pb-5 fz-20">{t('funnel.pricing.subtitle')}</p>

                        <div id="offers"></div>
                        <ToggleSwitch 
                            handleSwitch={this.handleSwitchPeriod} 
                            for="switchPeriod" 
                            containerStyle="mx-sm-auto"
                            labelBefore={t('funnel.pricing.toggleSwitch.period1')} 
                            labelAfter={t('funnel.pricing.toggleSwitch.period2')} 
                            afterInfo={t('funnel.pricing.toggleSwitch.period2Info')} 
                            value={
                                isMonthly ? t('funnel.pricing.toggleSwitch.period2') 
                                : t('funnel.pricing.toggleSwitch.period1')
                            } 
                            isChecked={
                                this.state.periodBase === t('funnel.pricing.toggleSwitch.period1') ? false 
                                : isMonthly ? true : false
                            }
                        />
                        {/*
                        <ToggleSwitch 
                            handleSwitch={this.handleSwitchCurrency} 
                            for="switchCurrency" 
                            containerStyle="mx-sm-auto mt-4"
                            labelBefore={t('funnel.pricing.toggleSwitch.currency1')}
                            labelAfter={t('funnel.pricing.toggleSwitch.currency2')}
                            value={
                                isEuro ? t('funnel.pricing.toggleSwitch.currency2') 
                                : t('funnel.pricing.toggleSwitch.currency1')
                            } 
                            isChecked={
                                this.state.currencyBase === t('funnel.pricing.toggleSwitch.currency1') ? false 
                                : isEuro ? true : false
                            }
                        />
                        */}

                        <Row className="col-12 mx-0 mt-5 px-0 position-relative">

                            <Col md="12" lg="6" xl="3" className="block-item d-flex flex-column mb-5 px-3 pt-3 pb-4 bgc-light rounded">
                                <H2 title={t('funnel.pricing.pack.name.0')} className="mb-3"/>
                                <p>{t('funnel.pricing.pack.desc.0')}</p>
                                <div class="pricing py-3 mt-3 mb-4 text-center">
                                    <span class="price mr-2">{this.state.switchCurrencySelected ? '0€' : '$0'}</span>
                                    <span class="fz-14 fw-600">{t('funnel.pricing.pack.pricing.rhythm')}</span>
                                </div>
                                <FeaturesList pack0={true}/>
                                <PmyBtn redirectTo={!this.props.isConnected ? t('url.signUp') : t('url.home')} linkIsLargePmyOutlineLight textLink={t('funnel.pricing.pack.ctaFree')} type="button" idLink="btnPricingFree" containerStyle="d-flex align-items-end h-100" customBtnClass="w-100 mt-4"/>
                            </Col>

                            <Col md="12" lg="6" xl="3" className="block-item d-flex flex-column mb-5 px-3 pt-3 pb-4 bgc-light rounded">
                                <H2 title={t('funnel.pricing.pack.name.1')} className="mb-3"/>
                                <p>{t('funnel.pricing.pack.desc.1')}</p>
                                <div class="pricing d-flex flex-row align-items-center justify-content-center py-3 mt-3 mb-4 text-center">
                                    <p class="price mr-2">{this.formatManipulator('global', starter)}</p>
                                    <p class="d-flex flex-column text-left">
                                        <span class={isMonthly ? 'price-yearly' : 'invisible price-yearly'}>{this.formatManipulator('yearly', starter)}</span>
                                        <span class="fz-14 fw-600">{t('funnel.pricing.pack.pricing.rhythm')}</span>
                                    </p>
                                </div>
                                <FeaturesList pack1={true}/>
                                <PmyBtn onClick={this.selectStarter} redirectTo={!this.props.isConnected ? t('url.signUp') : this.selectStarter} linkIsLargePmyOutlineFull textLink={t('funnel.pricing.pack.ctaPro')} type="button" idLink="btnPricingStarter" containerStyle="d-flex align-items-end h-100" customBtnClass="w-100 mt-4"/>
                            </Col>

                            <Col md="12" lg="6" xl="3" className="block-item block-item-popular d-flex flex-column position-relative overflow-hidden mb-5 px-3 pt-3 pb-4 bgc-light rounded">
                                <H2 title={t('funnel.pricing.pack.name.2')} className="mb-3"/>
                                <p>{t('funnel.pricing.pack.desc.2')}</p>
                                <span class="block-item-popular-banner position-absolute text-center bgc-light color-primary fz-14 py-1 px-4">{t('funnel.pricing.pack.banner')}</span>
                                <div class="pricing d-flex flex-row align-items-center justify-content-center py-3 mt-3 mb-4 text-center">
                                    <p class="price mr-2">{this.formatManipulator('global', advanced)}</p>
                                    <p class="d-flex flex-column text-left">
                                        <span class={isMonthly ? 'price-yearly' : 'invisible price-yearly'}>{this.formatManipulator('yearly', advanced)}</span>
                                        <span class="fz-14 fw-600">{t('funnel.pricing.pack.pricing.rhythm')}</span>
                                    </p>
                                </div>
                                <FeaturesList pack2={true}/>
                                <PmyBtn onClick={this.selectAdvanced} redirectTo={!this.props.isConnected ? t('url.signUp') : this.selectAdvanced} linkIsLargePmyOutlineLight textLink={t('funnel.pricing.pack.ctaPro')} type="button" idLink="btnPricingAdvanced" containerStyle="d-flex align-items-end h-100" customBtnClass="w-100 mt-4"/>
                            </Col>

                            <Col md="12" lg="6" xl="3" className="block-item d-flex flex-column  mb-5 px-3 pt-3 pb-4 bgc-light rounded">
                                <H2 title={t('funnel.pricing.pack.name.3')} className="mb-3"/>
                                <p>{t('funnel.pricing.pack.desc.3')}</p>
                                <div class="pricing d-flex flex-row align-items-center justify-content-center py-3 mt-3 mb-4 text-center">
                                <p class="price mr-2">{this.formatManipulator('global', expert)}</p>
                                    <p class="d-flex flex-column text-left">
                                        <span class={isMonthly ? 'price-yearly' : 'invisible price-yearly'}>{this.formatManipulator('yearly', expert)}</span>
                                        <span class="fz-14 fw-600">{t('funnel.pricing.pack.pricing.rhythm')}</span>
                                    </p>
                                </div>
                                <FeaturesList pack3={true}/>
                                <PmyBtn onClick={this.selectExpert} redirectTo={!this.props.isConnected ? t('url.signUp') : this.selectExpert} linkIsLargePmyOutlineFull textLink={t('funnel.pricing.pack.ctaPro')} type="button" idLink="btnPricingExpert" containerStyle="d-flex align-items-end h-100" customBtnClass="w-100 mt-4"/>
                            </Col>

                        </Row>
                        <Blob blob="2" yellow className="blob-3"/>
                        <Dots className="dots-1"/>
                    </Container>
                </section>

                <section class="page-section-2 position-relative py-5">
                    <WaveSectionSeparator className="wave-top-4"/>
                    <Container className="d-flex flex-column flex-lg-row position-relative">
                        <Row className="d-flex flex-column flex-lg-row align-items-center align-items-lg-start justify-content-lg-around w-100 my-5">
                            <Col xs="12" sm="8" lg="4" className="block-item block-style d-flex flex-column justify-content-center align-items-center p-4 mb-5 text-center">
                                <Reinsurance icon="lock" height="24"/>
                                <H3 title={t('funnel.pricing.reinsuranceBlock.title1')} className="py-3"/>
                                <p>{t('funnel.pricing.reinsuranceBlock.desc1')}</p>
                            </Col>
                            <Col xs="12" sm="8" lg="4" className="block-item block-style d-flex flex-column justify-content-center align-items-center p-4 mb-5 text-center">
                                <Reinsurance icon="thumb" height="24"/>
                                <H3 title={t('funnel.pricing.reinsuranceBlock.title2')} className="py-3"/>
                                <p>{t('funnel.pricing.reinsuranceBlock.desc2')}</p>
                            </Col>
                            <Col xs="12" sm="8" lg="4" className="block-item block-style d-flex flex-column justify-content-center align-items-center p-4 mb-5 text-center">
                                <Reinsurance icon="support" height="24"/>
                                <H3 title={t('funnel.pricing.reinsuranceBlock.title3')} className="py-3"/>
                                <p>{t('funnel.pricing.reinsuranceBlock.desc3')}</p>
                            </Col>
                        </Row>
                        <Blob blob="3" purple className="blob-4"/>
                    </Container>
                    <WaveSectionSeparator className="wave-bottom-4"/>
                </section>

                <section class="page-section-3 pt-5 position-relative">
                    <Blob blob="2" yellow className="blob-5"/>
                    <Container>
                        <H2 title={t('funnel.pricing.help.faq')} className="pb-5"/>
                        <Row className="mx-0 my-5 w-100 d-flex flex-column">
                            <Col sm="12" className="question-faq d-flex flex-column text-left px-0">
                                <Accordion defaultActiveKey="-1">
                                    {accordionItems[i18n.languages[0]][this.state.selectedCategoryIndex].questions.map((accordionItem) =>
                                        <AccordionItem eventKey={accordionItem.key} title={accordionItem.question} content={accordionItem.content}/>
                                    )}
                                </Accordion>
                            </Col>
                        </Row>
                        <H3 title={t('funnel.pricing.help.ctaLabel')} className="mt-5 py-5 text-center"/>
                        <PmyBtn redirectTo="#offers" linkIsLargePmyFull textLink={t('funnel.pricing.help.cta')} type="button" containerStyle="text-center"/>
                    </Container>
                </section>

                <section class="page-section-4 py-5 mb-5">
                    <Container className="position-relative">
                        <ContactInfo title={t('funnel.pricing.help.title')}/>
                        <Dots className="dots-2"/>
                    </Container>
                </section>

            </div>
        )
    }
}

export default withTranslation()(Pricing);