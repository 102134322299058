import React from 'react';
import { withTranslation } from 'react-i18next';
import Tick from '../../../assets/img/svg/Tick';
import Information from '../../../assets/img/svg/Information';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

class FeaturesList extends React.Component {

    render() {

        const { t } = this.props;

        const featuresUl = ' features-pack';

        const renderTooltipSingleCredits = (props) => (
            <Tooltip id="button-tooltip" {...props}>
                {t('tooltip.pricing.creditsSingle')}<br/>
                {t('tooltip.pricing.creditsUsecase')}
            </Tooltip>
        );

        const renderTooltipCredits = (props) => (
            <Tooltip id="button-tooltip" {...props}>
                {t('tooltip.pricing.creditsPro')}<br/>
                {t('tooltip.pricing.creditsUsecase')}
                {" " + t('tooltip.credits')}
            </Tooltip>
        );

        return ( 

            this.props.packDemo ?
                <ul class={this.props.className ? this.props.className + featuresUl : featuresUl}>
                    <li><Tick/>{t('funnel.features.0')}</li>
                    <li><Tick/>{t('funnel.features.1')}</li>
                    <li><Tick/>{t('funnel.features.2')}</li>
                    <li><Tick/>{t('funnel.features.3')}</li>
                    <li><Tick/>{t('funnel.features.4')}</li>
                    <li><Tick/>{t('funnel.features.5')}</li>
                    <li><Tick/>{t('funnel.features.6c')}</li>
                </ul>

            : this.props.pack0 ?
                <ul class={this.props.className ? this.props.className + featuresUl : featuresUl}>
                    <li><Tick/>{t('funnel.features.0a')}</li>
                    <li><Tick/>{t('funnel.features.1')}</li>
                    <li>
                        <Tick/>
                        <p>
                            <span class="pr-1 fz-20 lh-1 fw-600">{t('funnel.features.6-0')}</span> <span class="mr-2">{t('funnel.features.6b')}</span>
                            <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltipSingleCredits}>
                                <span><Information/></span>
                            </OverlayTrigger>
                        </p>
                    </li>
                </ul>

            : this.props.pack1 ?
                <ul class={this.props.className ? this.props.className + featuresUl : featuresUl}>
                    <li><Tick/>{t('funnel.features.0')}</li>
                    <li><Tick/>{t('funnel.features.1')}</li>
                    <li><Tick/>{t('funnel.features.2')}</li>
                    <li><Tick/>{t('funnel.features.3')}</li>
                    <li>
                        <Tick/>
                        <p>
                            <span class="pr-1 fz-20 lh-1 fw-600">{t('funnel.features.6-1')}</span> <span class="mr-2">{t('funnel.features.6a')} </span>
                            <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltipCredits}>
                                <span><Information/></span>
                            </OverlayTrigger>
                        </p>
                    </li>
                </ul>

            : this.props.pack2 ?
                <ul class={this.props.className ? this.props.className + featuresUl : featuresUl}>
                    <li><Tick/>{t('funnel.features.0')}</li>
                    <li><Tick/>{t('funnel.features.1')}</li>
                    <li><Tick/>{t('funnel.features.2')}</li>
                    <li><Tick/>{t('funnel.features.3')}</li>
                    <li><Tick/>{t('funnel.features.4')}</li>
                    <li><Tick/>{t('funnel.features.5')}</li>
                    <li>
                        <Tick/>
                        <p>
                            <span class="pr-1 fz-20 lh-1 fw-600">{t('funnel.features.6-2')}</span> <span class="mr-2">{t('funnel.features.6a')} </span>
                            <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltipCredits}>
                                <span><Information/></span>
                            </OverlayTrigger>
                        </p>
                    </li>
                </ul>

            : this.props.pack3 &&
                <ul class={this.props.className ? this.props.className + featuresUl : featuresUl}>
                    <li><Tick/>{t('funnel.features.0')}</li>
                    <li><Tick/>{t('funnel.features.1')}</li>
                    <li><Tick/>{t('funnel.features.2')}</li>
                    <li><Tick/>{t('funnel.features.3')}</li>
                    <li><Tick/>{t('funnel.features.4')}</li>
                    <li><Tick/>{t('funnel.features.5')}</li>
                    <li>
                        <Tick/>
                        <p>
                            <span class="pr-1 fz-20 lh-1 fw-600">{t('funnel.features.6-3')}</span> <span class="mr-2">{t('funnel.features.6a')} </span>
                            <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltipCredits}>
                                <span><Information/></span>
                            </OverlayTrigger>
                        </p>
                    </li>
                </ul>

        );
    }
}

export default withTranslation()(FeaturesList);