import React from 'react';

export default class ArrowChevronLight extends React.Component {

    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.364 11.314" width={this.props.width} height={this.props.height} fill={this.props.fill} style={this.props.style}>
                <path d="M8.593,18.157,14.25,12.5,8.593,6.843l-.707.707,4.95,4.95-4.95,4.95Z" transform="translate(-7.886 -6.843)"/>
            </svg>
        )
    }
}