import React from 'react';

export default class Download extends React.Component {

    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 18" width={this.props.width} height={this.props.height} fill={this.props.fill}>
                <path d="M12,4V16.25L17.25,11l.75.664-6.5,6.5L5,11.664,5.75,11,11,16.25V4ZM3,19H4v2H19V19h1v3H3Z" transform="translate(-3 -4)"/>
            </svg>
        )
    }
}