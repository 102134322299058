import React from 'react';

export default class Information extends React.Component {

    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" class="information-icon" width={this.props.width} height={this.props.height} fill={this.props.fill}>
                <path d="M11.5,3A9.5,9.5,0,1,1,2,12.5,9.5,9.5,0,0,1,11.5,3Zm0,1A8.5,8.5,0,1,0,20,12.5,8.5,8.5,0,0,0,11.5,4ZM11,8v2h1V8Zm0,4v5h1V12Z" transform="translate(-2 -3)"/>
            </svg>
        )
    }
}