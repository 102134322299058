import React from 'react';

export default class Quote extends React.Component {

    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 26.724" class="quote" width={this.props.width} height={this.props.height} fill={this.props.fill}>
                <path d="M10.242,19.969A8.479,8.479,0,0,0,7.9,19.627a7.363,7.363,0,0,0-2.938.6c.738-2.7,2.512-7.368,6.046-7.894a.84.84,0,0,0,.685-.6l.772-2.763a.839.839,0,0,0-.694-1.057,5.866,5.866,0,0,0-.79-.054c-4.242,0-8.443,4.427-10.215,10.767-1.041,3.719-1.346,9.311,1.217,12.83A7.652,7.652,0,0,0,8.2,34.581h.033a7.445,7.445,0,0,0,2.007-14.613Z" transform="translate(0 -7.858)"/>
                <path d="M63.343,23.485a7.462,7.462,0,0,0-4.476-3.516,8.478,8.478,0,0,0-2.343-.342,7.365,7.365,0,0,0-2.938.6c.738-2.7,2.512-7.368,6.047-7.894a.84.84,0,0,0,.685-.6l.772-2.763A.839.839,0,0,0,60.4,7.912a5.859,5.859,0,0,0-.79-.054c-4.242,0-8.443,4.427-10.216,10.767-1.04,3.719-1.345,9.311,1.218,12.831a7.651,7.651,0,0,0,6.219,3.126h.033a7.445,7.445,0,0,0,6.482-11.1Z" transform="translate(-32.304 -7.858)"/>
            </svg>
        )
    }
}