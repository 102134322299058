import React from 'react';

export default class Blob extends React.Component {

    render() {

        const colors = 
            this.props.purple ? '#775FA9'
            : this.props.red ? '#D5586F'
            : this.props.blue ? '#4FB1DD'
            : this.props.yellow ? '#FACE8D'
            : '#FFF';

        switch(this.props.blob) {
            case '1':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 135 139.658" width="135" class={this.props.className} fill={colors}>
                        <path d="M56.944-63.788C69.223-52.644,72.525-31.7,70.049-14.361,67.469,2.975,59.007,16.7,49.824,27.534S30.837,46.315,18.248,53.951C5.762,61.483-9.51,68.707-20.345,64.579c-10.938-4.231-17.439-19.812-26-34.465-8.668-14.55-19.4-28.067-17.645-39.625,1.857-11.557,16.1-21.051,29.2-31.989C-21.687-52.541-9.82-65.026,6.794-70.6,23.3-76.274,44.561-74.933,56.944-63.788Z" transform="translate(64.184 73.876)"/>
                    </svg>
                )
                        
            case '2':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 163.205" width="135" class={this.props.className} fill={colors}>
                        <path d="M45.928-50.951C57.235-37.446,64.355-22.788,70.741-5.3s12.04,37.9,6.072,54.755C70.95,66.412,53.466,79.813,35.459,81.907,17.556,83.9-.661,74.473-18.983,67.04-37.3,59.5-55.521,53.953-64,41.6c-8.585-12.354-7.224-31.513-4.921-51.51s5.758-40.726,17.9-54.127C-38.77-77.439-17.726-83.407.6-80.161,18.917-77.02,34.517-64.562,45.928-50.951Z" transform="translate(70.659 81.029)"/>
                    </svg>
                )

            case '3':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 175.24 195.547" width="135" class={this.props.className} fill={colors}>
                        <path d="M62.057-44.265C79.031-27.884,97.549-15.3,100.635.367c3.086,15.55-9.14,34.187-19.7,54.722C70.366,75.744,61.344,98.416,44.607,108.031S3.536,114.323-12.252,102.1s-22.91-33.237-34.661-52.23C-58.546,30.755-74.809,13.781-74.1-1.888-73.266-17.557-55.46-31.8-38.011-48.183-20.561-64.445-3.349-82.844,13.032-81.894S45.082-60.646,62.057-44.265Z" transform="translate(74.119 81.93)"/>
                    </svg>
                )
        } 
    }
}