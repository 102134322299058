import React from 'react';
import Information from '../../../assets/img/svg/Information'

export default class Info extends React.Component {

    render() {
        return (
            <div class={this.props.className ? this.props.className + ' info-msg mx-3 px-3 py-2 rounded' : 'info-msg mx-3 px-3 py-2 rounded'}>
                <p class="d-flex flex-row align-items-center">
                    <span class="d-block mr-2"><Information width="14" fill="#2B2B2B"/></span>
                    <span class={this.props.msgClass ? this.props.msgClass : 'fz-14'}>{this.props.msg}</span>
                </p>
            </div>
        )
    }
}
