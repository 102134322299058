import React from 'react';
import { withTranslation } from 'react-i18next';
import {
    Row, 
    Col 
} from 'react-bootstrap';
import PaperPlane from '../../../assets/img/svg/PaperPlane';
import SpeechBubble from '../../../assets/img/svg//SpeechBubble';
import { Link } from 'react-router-dom'; 

class ContactInfo extends React.Component {

    render() {

        const { t } = this.props;
        const contactInfoCtn = ' faq-contact-infos mx-0 mt-5 pt-4 pb-4 pb-md-5 px-3 w-100 d-flex flex-column flex-nowrap rounded';

        return (
            <Row className={this.props.className ? this.props.className + contactInfoCtn : contactInfoCtn}>
                <p class="text-left fw-600">{this.props.title}</p>
                <div class="d-flex flex-row justify-content-center flex-wrap mt-5">
                    <Col sm="12" md="4" lg="3" className="faq-contact-infos-block px-0">
                        <Link to={t('url.contact')} class="d-flex flex-column align-items-center p-3 rounded">
                            <PaperPlane height="20" fill="#2B2B2B"/>
                            <span class="mt-3 mb-2 fw-600">{t('faq.footer.contactUs')}</span>
                            <span class="text-center">{t('faq.footer.leaveUsMessage')}</span>
                        </Link>
                    </Col>
                    <Col sm="12" md="4" lg="3" className="faq-contact-infos-block px-0 ml-0 ml-md-5 mt-4 mt-md-0">
                        <a href="javascript:void(Tawk_API.toggle())" class="d-flex flex-column align-items-center p-3 rounded">
                            <SpeechBubble height="20" fill="#2B2B2B"/>
                            <span class="mt-3 mb-2 fw-600">{t('faq.footer.chatWithUs')}</span>
                            <span class="text-center">{t('faq.footer.liveExchange')}</span>
                        </a>
                    </Col>
                </div>
            </Row>
        )
    }
}

export default withTranslation()(ContactInfo);