import React from 'react';

export default class Credits extends React.Component {

    render() {
        switch(this.props.icon) {
            case '1':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="129" height="100" viewBox="0 0 129 100" class="img-fluid mx-auto">
                        <g transform="translate(-2236 232)">
                            <rect width="129" height="100" transform="translate(2236 -232)" fill="none"/>
                            <g transform="translate(2113.073 -317.355)">
                                <g transform="translate(163.366 129.509)">
                                    <path d="M34.625,39v5.845c0,1.614,6.006,2.923,13.415,2.923s13.415-1.309,13.415-2.923V39Z" transform="translate(-34.625 -36.076)" fill="#f19920"/>
                                    <ellipse cx="13.415" cy="2.924" rx="13.415" ry="2.924" fill="#ffcb5b"/>
                                </g>
                                <g transform="translate(180.959 135.355)">
                                    <path d="M34.625,39v5.845c0,1.614,6.006,2.923,13.415,2.923s13.415-1.309,13.415-2.923V39Z" transform="translate(-34.625 -36.076)" fill="#f19920"/>
                                    <ellipse cx="13.415" cy="2.924" rx="13.415" ry="2.924" fill="#ffcb5b"/>
                                </g>
                                <g transform="translate(173.87 126.12)">
                                    <path d="M20.875,57.235V63.08C20.875,64.695,26.881,66,34.29,66S47.7,64.694,47.7,63.08V57.235Z" transform="translate(-20.875 -54.311)" fill="#f19920"/>
                                    <ellipse cx="13.415" cy="2.924" rx="13.415" ry="2.924" transform="translate(0 0)" fill="#ffcb5b"/>
                                </g>
                                <g transform="translate(161.049 139.011)">
                                    <path d="M48.375,76.2v4.577c0,1.264,4.7,2.288,10.5,2.288s10.5-1.025,10.5-2.288V76.2Z" transform="translate(-48.375 -73.91)" fill="#f19920"/>
                                    <ellipse cx="10.503" cy="2.289" rx="10.503" ry="2.289" fill="#ffcb5b"/>
                                </g>
                                <g transform="translate(179.692 120.355)">
                                    <path d="M34.625,39v4.577c0,1.264,4.7,2.288,10.5,2.288s10.5-1.025,10.5-2.288V39Z" transform="translate(-34.625 -36.711)" fill="#f19920"/>
                                    <ellipse cx="10.503" cy="2.289" rx="10.503" ry="2.289" fill="#ffcb5b"/>
                                </g>
                                <g transform="translate(192.798 141.201)">
                                    <path d="M34.625,39v4.577c0,1.264,4.7,2.288,10.5,2.288s10.5-1.025,10.5-2.288V39Z" transform="translate(-34.625 -36.711)" fill="#f19920"/>
                                    <ellipse cx="10.503" cy="2.289" rx="10.503" ry="2.289" fill="#ffcb5b"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                );

            case '2':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="129" height="100" viewBox="0 0 129 100" class="img-fluid mx-auto">
                        <g transform="translate(-2236 232)">
                            <rect width="129" height="100" transform="translate(2236 -232)" fill="none"/>
                            <g transform="translate(-288.609 -728.41)">
                                <g transform="translate(2574.045 521.41)">
                                    <path d="M106.308,47.62a4.86,4.86,0,0,1-2.027-4.055A23.163,23.163,0,0,0,97.49,27.145l-4.359-4.359V19.745l3.548-4.46a2.419,2.419,0,0,0,.507-1.419,2.25,2.25,0,0,0-2.23-2.23H91.1L90.09,13.663l-2.027-2.027H83.2a2.25,2.25,0,0,0-2.23,2.23,2.421,2.421,0,0,0,.507,1.419l3.548,4.46v3.041l-4.359,4.359a23.163,23.163,0,0,0-6.792,16.421h0a4.859,4.859,0,0,1-2.027,4.055,4.858,4.858,0,0,0-2.028,4.055h0a2.51,2.51,0,0,0,2.534,2.534H105.8a2.51,2.51,0,0,0,2.534-2.534h0A4.859,4.859,0,0,0,106.308,47.62Z" transform="translate(-69.817 -11.636)" fill="#c97629"/>
                                    <path d="M107.372,47.62a5.183,5.183,0,0,1-2.028-4.156c.1-6.386-2.838-12.366-7.3-16.928l-3.852-3.75V19.745l3.548-4.46a2.276,2.276,0,0,0,.2-2.636,2.252,2.252,0,0,0-2.028-1.014,1.071,1.071,0,0,0-.912,1.419,1.906,1.906,0,0,1,.2.811,2.423,2.423,0,0,1-.507,1.419l-3.548,4.46v3.041H86.085L85.072,23.8h7.1l3.75,3.751c4.156,4.156,5.778,9.832,5.373,15.61a5.072,5.072,0,0,0,1.926,4.359c.1.1.2.2.3.2a2.661,2.661,0,0,1-2.128,4.359H70.982a2.5,2.5,0,0,0,2.433,2.027h33.45a2.51,2.51,0,0,0,2.534-2.534v-.2A4.713,4.713,0,0,0,107.372,47.62Z" transform="translate(-70.88 -11.636)" fill="#b06328"/>
                                    <path d="M231.733,107.767h-9.122a1.559,1.559,0,0,1-1.521-1.521h0a1.559,1.559,0,0,1,1.521-1.52h9.122a1.559,1.559,0,0,1,1.521,1.52h0A1.559,1.559,0,0,1,231.733,107.767Z" transform="translate(-207.913 -96.617)" fill="#ffcb5b"/>
                                    <path d="M233.235,104.727H238.3a.478.478,0,0,1,.505.507h0a.478.478,0,0,1-.505.507h-5.068a.479.479,0,0,1-.508-.507h0A.479.479,0,0,1,233.235,104.727Z" transform="translate(-218.536 -96.618)" fill="#ffe278"/>
                                    <path d="M232.8,104.727h-.507v1.014a.957.957,0,0,1-1.013,1.013h-9.022a1.524,1.524,0,0,0,1.419,1.014H232.8a1.52,1.52,0,1,0,0-3.041Z" transform="translate(-208.976 -96.618)" fill="#f6b545"/>
                                    <path d="M303.052,128h3.04a.478.478,0,0,1,.507.507h0a.478.478,0,0,1-.507.507h-3.04a.478.478,0,0,1-.507-.507h0A.478.478,0,0,1,303.052,128Z" transform="translate(-282.274 -117.864)" fill="#f19920"/>
                                    <rect width="2.027" height="8.109" transform="translate(18.246 11.15)" fill="#f6b545"/>
                                    <path d="M279.273,139.636v1.014h1.013v7.1H281.3v-8.109Z" transform="translate(-261.027 -128.486)" fill="#f19920"/>
                                    <circle cx="8.109" cy="8.109" r="8.109" transform="translate(11.15 18.246)" fill="#f6b545"/>
                                    <path d="M242.916,254.836A7.754,7.754,0,0,1,244.843,260a8.133,8.133,0,0,1-8.109,8.11,7.755,7.755,0,0,1-5.169-1.926,8.044,8.044,0,0,0,6.183,2.939,8.132,8.132,0,0,0,8.109-8.109A7.743,7.743,0,0,0,242.916,254.836Z" transform="translate(-217.474 -233.65)" fill="#b06328"/>
                                    <circle cx="7.095" cy="7.095" r="7.095" transform="translate(12.164 19.259)" fill="#ffcb5b"/>
                                    <path d="M298,314.182a7.049,7.049,0,0,1-7.094,7.094v1.016a8.132,8.132,0,0,0,8.108-8.11Z" transform="translate(-271.65 -287.828)" fill="#f19920"/>
                                    <path d="M205.927,222.1v-1.013a8.133,8.133,0,0,0-8.109,8.109h1.014A7.052,7.052,0,0,1,205.927,222.1Z" transform="translate(-186.668 -202.846)" fill="#ffe27a"/>
                                </g>
                                <g transform="translate(2617.073 536.174)">
                                    <g transform="translate(0 14.656)">
                                        <path d="M34.625,39v4.476c0,1.236,4.6,2.238,10.272,2.238s10.272-1,10.272-2.238V39Z" transform="translate(-34.625 -36.761)" fill="#f19920"/>
                                        <ellipse cx="10.272" cy="2.239" rx="10.272" ry="2.239" transform="translate(0)" fill="#ffcb5b"/>
                                    </g>
                                    <g transform="translate(1.993 9.263)">
                                        <path d="M34.625,39v4.476c0,1.236,4.6,2.238,10.272,2.238s10.272-1,10.272-2.238V39Z" transform="translate(-34.625 -36.761)" fill="#f19920"/>
                                        <ellipse cx="10.272" cy="2.239" rx="10.272" ry="2.239" fill="#ffcb5b"/>
                                    </g>
                                    <g transform="translate(0 3.357)">
                                        <path d="M34.625,39v4.476c0,1.236,4.6,2.238,10.272,2.238s10.272-1,10.272-2.238V39Z" transform="translate(-34.625 -36.761)" fill="#f19920"/>
                                        <ellipse cx="10.272" cy="2.239" rx="10.272" ry="2.239" transform="translate(0)" fill="#ffcb5b"/>
                                    </g>
                                    <g transform="translate(3.79 0)">
                                        <path d="M34.625,39v3.357c0,.927,3.449,1.678,7.7,1.678s7.7-.752,7.7-1.678V39Z" transform="translate(-34.625 -37.321)" fill="#f19920"/>
                                        <ellipse cx="7.704" cy="1.679" rx="7.704" ry="1.679" fill="#ffcb5b"/>
                                    </g>
                                </g>
                                <g transform="translate(2538.608 547.978)">
                                    <g transform="translate(1.329 4.476)">
                                        <path d="M34.625,39v4.476c0,1.236,4.6,2.238,10.272,2.238s10.272-1,10.272-2.238V39Z" transform="translate(-34.625 -36.761)" fill="#f19920"/>
                                        <ellipse cx="10.272" cy="2.239" rx="10.272" ry="2.239" fill="#ffcb5b"/>
                                    </g>
                                    <g transform="translate(0 11.007)">
                                        <path d="M34.625,39v5.716c0,1.579,5.874,2.858,13.119,2.858s13.119-1.28,13.119-2.858V39Z" transform="translate(-34.625 -36.141)" fill="#f19920"/>
                                        <ellipse cx="13.119" cy="2.859" rx="13.119" ry="2.859" fill="#ffcb5b"/>
                                    </g>
                                    <g transform="translate(14.849 5.29)">
                                        <path d="M34.625,39v5.716c0,1.579,5.874,2.858,13.119,2.858s13.119-1.28,13.119-2.858V39Z" transform="translate(-34.625 -36.141)" fill="#f19920"/>
                                        <ellipse cx="13.119" cy="2.859" rx="13.119" ry="2.859" fill="#ffcb5b"/>
                                    </g>
                                    <g transform="translate(20.543 14.48)">
                                        <path d="M34.625,39v4.476c0,1.236,4.6,2.238,10.272,2.238s10.272-1,10.272-2.238V39Z" transform="translate(-34.625 -36.761)" fill="#f19920"/>
                                        <ellipse cx="10.272" cy="2.239" rx="10.272" ry="2.239" fill="#ffcb5b"/>
                                    </g>
                                    <g transform="translate(11.6 0)">
                                        <path d="M34.625,39v4.476c0,1.236,4.6,2.238,10.272,2.238s10.272-1,10.272-2.238V39Z" transform="translate(-34.625 -36.761)" fill="#f19920"/>
                                        <ellipse cx="10.272" cy="2.239" rx="10.272" ry="2.239" fill="#ffcb5b"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                );

            case '3':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="129" height="100" viewBox="0 0 129 100" class="img-fluid mx-auto">
                        <g transform="translate(-2414 232)">
                            <rect width="129" height="100" transform="translate(2414 -232)" fill="none"/>
                            <g transform="translate(2414 -232)">
                                <g transform="translate(0 61.01)">
                                    <g transform="translate(0 13.88)">
                                        <path d="M20.875,57.235v6.024c0,1.664,6.19,3.012,13.824,3.012s13.824-1.349,13.824-3.012V57.235Z" transform="translate(-20.875 -54.222)" fill="#f19920"/>
                                        <ellipse cx="13.824" cy="3.013" rx="13.824" ry="3.013" transform="translate(0 0)" fill="#ffcb5b"/>
                                    </g>
                                    <g transform="translate(12.942 22.804)">
                                        <path d="M48.375,76.2v6.024c0,1.664,6.19,3.012,13.824,3.012s13.824-1.349,13.824-3.012V76.2Z" transform="translate(-48.375 -73.186)" fill="#f19920"/>
                                        <ellipse cx="13.824" cy="3.013" rx="13.824" ry="3.013" fill="#ffcb5b"/>
                                    </g>
                                    <g transform="translate(6.471 5.299)">
                                        <path d="M34.625,39v6.024c0,1.664,6.189,3.012,13.824,3.012s13.824-1.349,13.824-3.012V39Z" transform="translate(-34.625 -35.987)" fill="#f19920"/>
                                        <ellipse cx="13.824" cy="3.013" rx="13.824" ry="3.013" fill="#ffcb5b"/>
                                    </g>
                                    <g transform="translate(16.013 0)">
                                        <path d="M34.625,39v4.716c0,1.3,4.846,2.358,10.824,2.358s10.824-1.056,10.824-2.358V39Z" transform="translate(-34.625 -36.641)" fill="#f19920"/>
                                        <ellipse cx="10.824" cy="2.359" rx="10.824" ry="2.359" fill="#ffcb5b"/>
                                    </g>
                                </g>
                                <g transform="translate(24.576)">
                                    <path d="M145.406,86.175a10.067,10.067,0,0,1-4.2-8.4,47.978,47.978,0,0,0-14.068-34.015l-9.029-9.028v-6.3l7.349-9.239a5.018,5.018,0,0,0,1.05-2.94,4.661,4.661,0,0,0-4.62-4.619h-7.979l-2.1,4.2-4.2-4.2H97.534a4.661,4.661,0,0,0-4.62,4.619,5.018,5.018,0,0,0,1.05,2.94l7.348,9.239v6.3l-9.028,9.029A47.978,47.978,0,0,0,78.217,77.776h0a10.066,10.066,0,0,1-4.2,8.4,10.067,10.067,0,0,0-4.2,8.4h0a5.2,5.2,0,0,0,5.249,5.249h69.29a5.2,5.2,0,0,0,5.249-5.249h0A10.065,10.065,0,0,0,145.406,86.175Z" transform="translate(-69.817 -11.636)" fill="#c97629"/>
                                    <path d="M146.361,86.175a10.737,10.737,0,0,1-4.2-8.609c.21-13.228-5.878-25.616-15.117-35.065l-7.979-7.769v-6.3l7.349-9.239a4.714,4.714,0,0,0,.42-5.459,4.664,4.664,0,0,0-4.2-2.1,2.219,2.219,0,0,0-1.89,2.94,3.95,3.95,0,0,1,.42,1.68,5.018,5.018,0,0,1-1.05,2.94l-7.348,9.239v6.3h-10.5l-2.1,2.1h14.7l7.768,7.769c8.609,8.609,11.969,20.367,11.128,32.335a10.505,10.505,0,0,0,3.99,9.029c.21.21.42.42.63.42,3.149,3.359.419,9.028-4.41,9.028H70.982a5.183,5.183,0,0,0,5.039,4.2H145.31a5.2,5.2,0,0,0,5.249-5.249v-.42A9.761,9.761,0,0,0,146.361,86.175Z" transform="translate(-70.772 -11.636)" fill="#b06328"/>
                                    <path d="M243.138,111.026h-18.9a3.23,3.23,0,0,1-3.148-3.15h0a3.23,3.23,0,0,1,3.148-3.151h18.9a3.229,3.229,0,0,1,3.148,3.151h0A3.228,3.228,0,0,1,243.138,111.026Z" transform="translate(-193.794 -87.928)" fill="#ffcb5b"/>
                                    <path d="M233.775,104.727h10.5a.99.99,0,0,1,1.049,1.05h0a.99.99,0,0,1-1.049,1.05h-10.5a.991.991,0,0,1-1.049-1.05h0A.992.992,0,0,1,233.775,104.727Z" transform="translate(-203.331 -87.929)" fill="#ffe278"/>
                                    <path d="M244.094,104.727h-1.051v2.1a1.983,1.983,0,0,1-2.1,2.1h-18.69a3.161,3.161,0,0,0,2.941,2.1h18.9a3.15,3.15,0,0,0,0-6.3Z" transform="translate(-194.749 -87.929)" fill="#f6b545"/>
                                    <path d="M303.595,128h6.3a.992.992,0,0,1,1.051,1.05h0a.991.991,0,0,1-1.051,1.049h-6.3a.989.989,0,0,1-1.049-1.049h0A.99.99,0,0,1,303.595,128Z" transform="translate(-260.551 -107.003)" fill="#f19920"/>
                                    <rect width="4.199" height="16.797" transform="translate(37.794 23.096)" fill="#f6b545"/>
                                    <path d="M279.273,139.636v2.1h2.1v14.7h2.1v-16.8Z" transform="translate(-241.479 -116.54)" fill="#f19920"/>
                                    <circle cx="16.797" cy="16.797" r="16.797" transform="translate(23.097 37.794)" fill="#f6b545"/>
                                    <path d="M255.083,254.836a16.066,16.066,0,0,1,3.987,10.708,16.845,16.845,0,0,1-16.8,16.8,16.059,16.059,0,0,1-10.708-3.987,16.668,16.668,0,0,0,12.808,6.087,16.846,16.846,0,0,0,16.8-16.8A16.038,16.038,0,0,0,255.083,254.836Z" transform="translate(-202.379 -210.953)" fill="#b06328"/>
                                    <circle cx="14.698" cy="14.698" r="14.698" transform="translate(25.196 39.894)" fill="#ffcb5b"/>
                                    <path d="M305.607,314.182a14.609,14.609,0,0,1-14.7,14.7v2.1a16.845,16.845,0,0,0,16.8-16.8Z" transform="translate(-251.014 -259.591)" fill="#f19920"/>
                                    <path d="M214.616,223.191v-2.1a16.845,16.845,0,0,0-16.8,16.8h2.1A14.607,14.607,0,0,1,214.616,223.191Z" transform="translate(-174.722 -183.297)" fill="#ffe27a"/>
                                </g>
                                <g transform="translate(79.727 63.104)">
                                    <g transform="translate(21.625 20.124)">
                                        <path d="M34.625,39v6.024c0,1.664,6.189,3.012,13.824,3.012s13.824-1.349,13.824-3.012V39Z" transform="translate(-34.625 -35.987)" fill="#f19920"/>
                                        <ellipse cx="13.824" cy="3.013" rx="13.824" ry="3.013" fill="#ffcb5b"/>
                                    </g>
                                    <g transform="translate(23.295 13.242)">
                                        <path d="M34.625,39v4.716c0,1.3,4.846,2.358,10.824,2.358s10.824-1.056,10.824-2.358V39Z" transform="translate(-34.625 -36.641)" fill="#f19920"/>
                                        <ellipse cx="10.824" cy="2.359" rx="10.824" ry="2.359" fill="#ffcb5b"/>
                                    </g>
                                    <g transform="translate(0 17.959)">
                                        <path d="M20.875,57.235v6.024c0,1.664,6.19,3.012,13.824,3.012s13.824-1.349,13.824-3.012V57.235Z" transform="translate(-20.875 -54.222)" fill="#f19920"/>
                                        <ellipse cx="13.824" cy="3.013" rx="13.824" ry="3.013" transform="translate(0 0)" fill="#ffcb5b"/>
                                    </g>
                                    <g transform="translate(5.188 27.455)">
                                        <path d="M48.375,76.2v4.717c0,1.3,4.847,2.358,10.824,2.358s10.824-1.056,10.824-2.358V76.2Z" transform="translate(-48.375 -73.84)" fill="#f19920"/>
                                        <ellipse cx="10.824" cy="2.359" rx="10.824" ry="2.359" fill="#ffcb5b"/>
                                    </g>
                                    <g transform="translate(9.471 10.627)">
                                        <path d="M34.625,39v6.024c0,1.664,6.189,3.012,13.824,3.012s13.824-1.349,13.824-3.012V39Z" transform="translate(-34.625 -35.987)" fill="#f19920"/>
                                        <ellipse cx="13.824" cy="3.013" rx="13.824" ry="3.013" fill="#ffcb5b"/>
                                    </g>
                                    <g transform="translate(16.013 4.349)">
                                        <path d="M34.625,39v4.716c0,1.3,4.846,2.358,10.824,2.358s10.824-1.056,10.824-2.358V39Z" transform="translate(-34.625 -36.641)" fill="#f19920"/>
                                        <ellipse cx="10.824" cy="2.359" rx="10.824" ry="2.359" fill="#ffcb5b"/>
                                    </g>
                                    <g transform="translate(18.719 0)">
                                        <path d="M34.625,39v3.538c0,.977,3.634,1.768,8.118,1.768s8.118-.792,8.118-1.768V39Z" transform="translate(-34.625 -37.231)" fill="#f19920"/>
                                        <ellipse cx="8.118" cy="1.769" rx="8.118" ry="1.769" fill="#ffcb5b"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                ); 

        }   
    }
}