import React from 'react';

export default class Settings extends React.Component {

    render() {
        return (     
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.621 19.015" width={this.props.width} height={this.props.height} fill={this.props.fill}>
                <path d="M19.588,15.492,17.774,14.2a6.483,6.483,0,0,0-.005-3.421l1.82-1.274L18.136,6.993l-2.024.926a6.484,6.484,0,0,0-2.965-1.706L12.953,4H10.047L9.854,6.213A6.483,6.483,0,0,0,6.889,7.919L4.864,6.993,3.412,9.507l1.82,1.274A6.483,6.483,0,0,0,5.226,14.2l-1.814,1.29,1.453,2.5,2.024-.926a6.483,6.483,0,0,0,2.965,1.706l.194,2.213h2.905l.194-2.213a6.484,6.484,0,0,0,2.965-1.706l2.024.926ZM13.505,2.985a.5.5,0,0,1,.5.476L14.183,5.5a7.45,7.45,0,0,1,2.043,1.179l1.85-.863a.5.5,0,0,1,.662.194l2,3.471a.5.5,0,0,1-.163.671L18.907,11.32a7.451,7.451,0,0,1,0,2.359l1.672,1.171a.5.5,0,0,1,.163.671l-2,3.458a.5.5,0,0,1-.662.194l-1.85-.863a7.452,7.452,0,0,1-2.043,1.178L14,21.524a.5.5,0,0,1-.5.476H9.495a.5.5,0,0,1-.5-.476l-.178-2.035A7.453,7.453,0,0,1,6.774,18.31l-1.85.863a.5.5,0,0,1-.662-.194l-2-3.458a.5.5,0,0,1,.163-.671l1.672-1.171a7.451,7.451,0,0,1,0-2.359L2.419,10.148a.5.5,0,0,1-.163-.671l2-3.471a.5.5,0,0,1,.662-.194l1.85.863A7.451,7.451,0,0,1,8.817,5.5L9,3.462a.5.5,0,0,1,.5-.476ZM11.5,9A3.5,3.5,0,1,1,8,12.5,3.5,3.5,0,0,1,11.5,9Zm0,1A2.5,2.5,0,1,0,14,12.5,2.5,2.5,0,0,0,11.5,10Z" transform="translate(-2.19 -2.985)"/>
            </svg>
        )
    }
}