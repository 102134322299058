import React from 'react';
import { withTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import ArrowChevronLight from '../../../../assets/img/svg/ArrowChevronLight';
import ArrowTextLink from '../../../components/elements/link/ArrowTextLink';
import Input from '../../form/Input';
import PmyBtn from '../../button/PmyBtn';
import Loader from '../../elements/Loader';

class Suggests extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            listIsCharged : true,
            featureIsOpened: false,
            feature1Showed: false,
            feature2Showed: false,
            noTitle: false,
            countDown: 0,
            keyword: [''],
            occurences: [],
            limitSuggests: false,
            alreadyClicked: false
        }
        this.handleShowFeature1 = this.handleShowFeature1.bind(this);
        this.handleShowFeature2 = this.handleShowFeature2.bind(this);
        this.handleCloseFeatures = this.handleCloseFeatures.bind(this);
        this.getSuggestions = this.getSuggestions.bind(this);
        this.timer = null;
        this.tick = this.tick.bind(this);
        this.updateKeyword = this.updateKeyword.bind(this);
        this.computeDensity = this.computeDensity.bind(this);
    }

    handleShowFeature1() {
        this.setState({
            featureIsOpened: true,
            feature1Showed: true
        });
    }

    handleShowFeature2() {
        this.setState({
            featureIsOpened: true,
            feature2Showed: true
        });
    }

    handleCloseFeatures() {
        this.setState({
            featureIsOpened: false,
            feature1Showed: false,
            feature2Showed: false
        });
    }

    countOccurences(string, word) {
        return string.toLowerCase().split(word).length - 1;
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.sections !== nextProps.sections) {
            this.setState({
                keyword: [''],
                occurences: []
            });
        }
    }

    getSuggestions() {
        this.setState({
            listIsCharged: false,
            alreadyClicked: true
        });
        var token = localStorage.getItem('af_token');
        fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/articles/' + this.props.articleId, {
            headers: {
                'Authorization': token
            },
            method: 'GET'
        })
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            if(!res.data.title || res.data.title.length < 2) {
                this.setState({
                    noTitle: true,
                    listIsCharged: true
                });
            }
            else {
                fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/suggestions?keyword=' + res.data.title + '&lang=' + 'uk' + '&country=' + 'us', {
                    headers: {
                        'Authorization': token
                    }
                })
                .then((res2) => {
                    return res2.json();
                })
                .then((res2) => {
                    if(res2.blocked) {
                        this.setState({
                            limitSuggests: true,
                            listIsCharged: true
                        });
                    }
                    else {
                        this.props.handleSuggests(res2);
                        this.timer = setInterval(() => this.tick(), 1000);
                        this.setState({
                            listIsCharged: true,
                            countDown: 30
                        });
                    }
                });
            }
        })
    }

    tick() {
        if (this.state.countDown === 0) {
            this.transition();
        } 
        else {
            this.setState({
                countDown: this.state.countDown - 1
            });
        } 
    }

    roundDecimal(nombre, precision) {
        var precision = precision || 2;
        var tmp = Math.pow(10, precision);
        return Math.round( nombre * tmp ) / tmp;
    }

    transition() {
        clearInterval(this.timer);
        this.timer = null;
    }

    updateKeyword(e) {
        if(e.target.value.length === 0) {
            this.setState({
                keyword: e.target.value.split(','),
                occurences: []
            });
        }
        else {
            this.setState({
                keyword: e.target.value.split(',')
            });
        }
    }

    computeDensity(e) {
        if(e.key === 'Enter') {
            e.currentTarget.blur();
        }
        else {
            if(e.type === 'blur') {
                var toRet = [];
                for(var i = 0; i < this.state.keyword.length; i++) {
                    if(this.state.keyword[i].length > 0) {
                        var occur = this.countOccurences(this.props.sections, this.state.keyword[i].toLowerCase());
                        var density = ( occur / this.props.sections.split(' ').length ) * 100;
                        toRet.push({
                            keyword: this.state.keyword[i],
                            density: density
                        });
                    }
                }
                this.setState({
                    occurences: toRet
                });
            }
        }
    }

    render() {
        console.log(this.props)
        const { t } = this.props;

        const containerClass = ' block-style h-max-content ml-xl-4 mt-5 mt-xl-0';

        return (
            <Col lg="12" xl="3" id="seoMenu" className={this.state.featureIsOpened ? 'p-3' + containerClass : 'py-3 px-0' + containerClass}>

                {!this.state.featureIsOpened &&
                    <section class="d-flex flex-column">
                        <p class="my-3 px-3 fw-600">{t('writingFranklin.suggests.title')}</p>
                        <div onClick={this.handleShowFeature1} class="state-interaction-element rounded-0 w-100 h-100">
                            <p class="d-flex flex-row align-items-center justify-content-between w-100 p-3">
                                <span>{t('writingFranklin.suggests.feature1.title')}</span>
                                <span class="d-block ml-2"><ArrowChevronLight fill="#2B2B2B" height="12"/></span>
                            </p>
                        </div>
                        <div onClick={this.handleShowFeature2} class="state-interaction-element rounded-0 w-100 h-100">
                            <p class="d-flex flex-row align-items-center justify-content-between w-100 p-3">
                                <span>{t('writingFranklin.suggests.feature2.title')}</span>
                                <span class="d-block ml-2"><ArrowChevronLight fill="#2B2B2B" height="12"/></span>
                            </p>
                        </div>
                    </section>
                }

                {this.state.featureIsOpened &&
                    <section class="d-flex flex-column">

                        <ArrowTextLink onClick={this.handleCloseFeatures} text={t('writingFranklin.topbar.back')} className="my-3" style={{ marginRight: '1rem' }}/>
                        <p class="mb-1 fw-600">{t('writingFranklin.suggests.title')}</p>

                        {this.state.feature1Showed ?
                            <>
                                <p class="mb-3 fz-14 fw-600">{t('writingFranklin.suggests.feature1.title')}</p>
                                <Input 
                                    onChange={this.updateKeyword} 
                                    onKeyPress={this.computeDensity} 
                                    onBlur={this.computeDensity} 
                                    value={this.state.keyword} 
                                    label={t('form.label.writingFranklin.density')}
                                    type="search" 
                                    for="keywordDensity" 
                                    placeholder={t('form.input.placeholderWritingFranklin.keywordDensity')}
                                    containerStyle="mb-0 pb-0"
                                />
                                <p className="color-disabled mt-3 fz-14">{t('writingFranklin.suggests.feature1.details')}</p>
                                {this.state.occurences.map((occu) =>
                                    <div className="density-rendering py-3 border-bottom">
                                        <p><span class="fw-600">{t('writingFranklin.suggests.feature1.keyword')}</span><span class="bgc-primary color-light rounded px-1 ml-1 fz-14">{occu.keyword}</span></p>
                                        <p><span class="fw-600">{t('writingFranklin.suggests.feature1.density')}</span>{this.roundDecimal(occu.density, 2)}%</p>
                                        <p><span class="fw-600">{t('writingFranklin.suggests.feature1.quality')}</span>{occu.density > 0.98 && occu.density < 2.2 ? '😎' : occu.density > 0.8 && occu.density < 3 ? '🙂' : '😔'}</p>
                                    </div>
                                )}
                            </>

                        : this.state.feature2Showed &&
                            <>
                                <p class="mb-3 fz-14 fw-600">{t('writingFranklin.suggests.feature2.title')}</p>
                                {!this.state.limitSuggests && <PmyBtn onClick={this.getSuggestions} type="button" isDisabled={!this.state.listIsCharged || this.state.countDown > 0} btnIsMediumPmyOutlineLight textBtn={t('writingFranklin.suggests.feature2.title')} title={t('writingFranklin.suggests.btn')} className="m-auto fz-16-index"/> }
                                
                                {this.state.countDown > 0 && <p className="text-center mt-2 fz-14">{this.state.countDown}{t('writingFranklin.suggests.feature2.timer')}</p> }
                                {!this.state.noTitle && !this.state.limitSuggests && <p className="color-disabled my-3 fz-14">{t('writingFranklin.suggests.feature2.details')}</p> }

                                {this.state.noTitle && !this.state.limitSuggests && <p className="mt-2 fz-14">{t('writingFranklin.suggests.feature2.needTitle')}</p> }

                                {this.state.limitSuggests && <p className="mt-2 mb-3">{t('writingFranklin.suggests.feature2.limit')}</p>}
                                {this.state.limitSuggests && <PmyBtn redirectTo={t('url.pricing')} type="button" linkIsMediumPmyFull textLink={t('requestLimit.cta')}/> }
                                
                                {!this.state.listIsCharged ?
                                    <Loader loaderDisplayed content={t('actions.loading')} className="my-5"/>

                                    : this.props.suggests.length > 0 ? this.props.suggests.map((suggest, index) => { 
                                        return (
                                            <div key={index} class="py-2">
                                                <span>{index + 1}. {suggest.charAt(0).toUpperCase() + suggest.slice(1)}</span>
                                            </div>
                                        ); 
                                    }) 

                                    : (this.state.alreadyClicked && !this.state.limitSuggests && !this.state.noTitle) && <p className="mt-2 fz-14">{t('writingFranklin.suggests.feature2.noResult')}</p>
                                }
                            </>
                        }

                    </section>
                }

            </Col>
        )
    }
}

export default withTranslation()(Suggests);