import React from 'react';
import i18n from '../../../../i18n';
import { withTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import Modal from 'react-bootstrap/Modal';
import H2 from '../../elements/title/H2';
import { Container, Col } from 'react-bootstrap';
import Close from '../../../../assets/img/svg/Close';
import ToggleSwitch from '../../../components/form/ToggleSwitch';
import Credits from '../../../../assets/img/svg/switch/Credits';
import PmyBtn from '../../button/PmyBtn';

const stripePromise = loadStripe('pk_live_WgN7E438RIWhbBUughEppG0S00G1SQfY87');

class ReloadCreditsModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modalShowed: false,
            currencyBase: i18n.t('funnel.pricing.toggleSwitch.currency1'),
            switchCurrencySelected: this.props.alreadySelected ? true : false,
            package: '',
        }
        this.handleShowModal = this.handleShowModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleSwitchCurrency = this.handleSwitchCurrency.bind(this);
        this.getPackage = this.getPackage.bind(this);
    }

    handleShowModal() {
		this.setState({ 
            modalShowed: true
        });
	}

    handleCloseModal() {
        this.props.onHide && this.props.handleHideModal();
		this.setState({ 
            modalShowed: false
        });
    }

    handleSwitchCurrency() {
        this.setState({
            currencyBase: '',
            switchCurrencySelected: this.state.switchCurrencySelected ? false : true
        }, () => {
            if (this.state.switchCurrencySelected) {
                console.log('currency conversion...');
            }
        });
    }

    formatManipulator($var) {
        let isEuro = this.state.switchCurrencySelected;
        let rate = 1.22;
        return (
            isEuro ? Math.floor($var / rate).toFixed(0) + '€' : '$' + $var
        );
    }

    async handlePackage(pack) {
        const stripe = await stripePromise;
        var token = localStorage.getItem('af_token');
        fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/create-checkout-session', {
            headers: {
                'Authorization': token
            },
            method: 'POST',
            body: JSON.stringify({
                price_id: pack,
                mode: 'payment',
                success_url: 'https://www.askingfranklin.com' + this.props.t('url.editorWF'),
                cancel_url: 'https://www.askingfranklin.com' + this.props.t('url.editorWF')
            })
        })
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            stripe.redirectToCheckout({
                sessionId: res.message
            });
        })
    }

    getPackage(e) {
        let pack = e.target.dataset.element;
        this.setState({
            package: pack
        }, () => {
            if(this.props.isPro) {
                this.handlePackage(this.state.package);
            }
            else {
                window.location.replace(i18n.t('url.pricing'));
            }
        });
    }

    render() {

        const { t } = this.props;

        const isEuro = this.state.switchCurrencySelected;
        const credits1 = '50 000', credits2 = '150 000', credits3 = '400 000';
        const pack1 = 9, pack2 = 19, pack3 = 39;

        return (
            <>

                <div onClick={this.handleShowModal} title={this.props.triggerTitle}>{this.props.trigger}</div>

                <Modal show={this.props.modalShowed ? this.props.modalShowed : this.state.modalShowed} onHide={this.handleCloseModal} dialogClassName="reload-credits-modal" className="d-flex align-items-center justify-content-center h-100 mx-auto my-0">

                    <Modal.Header className="d-flex flex-row align-items-center justify-content-between px-0 pb-3 mx-4">
                        <H2 className="mr-2" title={this.props.modalTitle}/>
                        <div onClick={this.handleCloseModal} aria-label={t('actions.cross')} title={t('actions.cross')} class="state-interaction-element">
                            <Close width="14" fill="#2B2B2B"/>
                        </div>
                    </Modal.Header>

                    <Modal.Body className="px-3 px-lg-4 py-4">
                        {/*
                        <ToggleSwitch 
                            handleSwitch={this.handleSwitchCurrency} 
                            for="switchCurrency" 
                            containerStyle="mx-sm-auto mb-4"
                            labelBefore={t('funnel.pricing.toggleSwitch.currency1')}
                            labelAfter={t('funnel.pricing.toggleSwitch.currency2')}
                            value={
                                isEuro ? t('funnel.pricing.toggleSwitch.currency2') 
                                : t('funnel.pricing.toggleSwitch.currency1')
                            } 
                            isChecked={
                                this.state.currencyBase === t('funnel.pricing.toggleSwitch.currency1') ? false 
                                : isEuro ? true : false
                            }
                        />
                        */}
                        <Container className="d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-between my-lg-4">
                            <Col xs="12" md="8" lg="4" className="pack-credits d-flex flex-column block-style text-center py-4 px-3">
                                <Credits icon="1"/>
                                <p class="mt-4"><span class="pr-1 fz-20 lh-1 fw-600">{credits1}</span>{t('writingFranklin.modalCredits.credits')}</p>
                                <p class="border-top mt-3 pt-3"><span class="price mr-2">{this.formatManipulator(pack1)}</span><span class="fz-14 fw-600">{t('funnel.pricing.pack.pricing.vat')}</span></p>
                                <PmyBtn onClick={this.getPackage} dataElem="price_1J3dppLB03GdYRbhJRHFyWYL" btnIsLargePmyOutlineFull textBtn={t('writingFranklin.modalCredits.reload') + credits1 + t('writingFranklin.modalCredits.credits')} containerStyle="mt-4 mx-auto w-sm-100" className="w-sm-100 fz-16-index"/> 
                            </Col>
                            <Col xs="12" md="8" lg="4" className="pack-credits d-flex flex-column block-style text-center py-4 px-3 my-4 my-lg-0">
                                <Credits icon="2"/>
                                <p class="mt-4"><span class="pr-1 fz-20 lh-1 fw-600">{credits2}</span>{t('writingFranklin.modalCredits.credits')}</p>
                                <p class="border-top mt-3 pt-3"><span class="price mr-2">{this.formatManipulator(pack2)}</span><span class="fz-14 fw-600">{t('funnel.pricing.pack.pricing.vat')}</span></p>
                                <PmyBtn onClick={this.getPackage} dataElem="price_1J3dqBLB03GdYRbhtpsT4dJ1" btnIsLargePmyOutlineFull textBtn={t('writingFranklin.modalCredits.reload') + credits2 + t('writingFranklin.modalCredits.credits')} containerStyle="mt-4 mx-auto w-sm-100" className="w-sm-100 fz-16-index"/>
                            </Col>
                            <Col xs="12" md="8" lg="4" className="pack-credits d-flex flex-column block-style text-center py-4 px-3">
                                <Credits icon="3"/>
                                <p class="mt-4"><span class="pr-1 fz-20 lh-1 fw-600">{credits3}</span>{t('writingFranklin.modalCredits.credits')}</p>
                                <p class="border-top mt-3 pt-3"><span class="price mr-2">{this.formatManipulator(pack3)}</span><span class="fz-14 fw-600">{t('funnel.pricing.pack.pricing.vat')}</span></p>
                                <PmyBtn onClick={this.getPackage} dataElem="price_1J3dqSLB03GdYRbhJNs7dzcJ" btnIsLargePmyOutlineFull textBtn={t('writingFranklin.modalCredits.reload') + credits3 + t('writingFranklin.modalCredits.credits')} containerStyle="mt-4 mx-auto w-sm-100" className="w-sm-100 fz-16-index"/>
                            </Col>
                        </Container>
                    </Modal.Body>
                    
                </Modal>

            </>
        )
    }
}

export default withTranslation()(ReloadCreditsModal);