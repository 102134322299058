import React from 'react';
import { withTranslation } from 'react-i18next';
import Tick from '../../../../assets/img/svg/Tick';
import Information from '../../../../assets/img/svg/Information';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import PmyBtn from '../../../components/button/PmyBtn';
import ReloadCreditsModal from '../../../components/partials/modals/ReloadCreditsModal';
import Settings from '../../../../assets/img/svg/navigation/Settings';
import LateralModal from '../../partials/modals/LateralModal';
import H3 from '../../elements/title/H3';
import Alert from '../../../components/elements/Alert';

const settings = {
    'low': 0.25,
    'middle': 0.5,
    'high': 0.75,
    'short': 96,
    'long': 192
};

class Heading extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modalCredits: false,
            defaultCreativitySelected: true,
            defaultLengthSelected: true,
            creativitySelected: '',
            textLengthSelected: '',
            successParam: false,
        }
        this.handleOpenReloadCredits = this.handleOpenReloadCredits.bind(this);
        this.getCreativity = this.getCreativity.bind(this);
        this.getTextLength = this.getTextLength.bind(this);
        this.handleModalCloseNoSaved = this.handleModalCloseNoSaved.bind(this);
        this.handleSavedParameters = this.handleSavedParameters.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
    }
    
    componentWillReceiveProps(nextProps) {
        this.setState({
            creativitySelected: nextProps.creativity,
            textLengthSelected: nextProps.textLength
        })
    }

    handleOpenReloadCredits() {
        this.setState({
            modalCredits: this.state.modalCredits ? false : true
        });
    }

    getCreativity(e) {
        let getType = e.target.dataset.type;
        this.setState({
            defaultCreativitySelected: false,
            creativitySelected: getType
        });
    }

    getTextLength(e) {
        let getType = e.target.dataset.type;
        this.setState({
            defaultLengthSelected: false,
            textLengthSelected: getType
        });
    }

    handleModalCloseNoSaved() {
        this.setState({
            defaultCreativitySelected: true,
            defaultLengthSelected: true
        });
    }

    handleSavedParameters() {
        var token = localStorage.getItem('af_token');
        fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/articles/save', {
            headers: {
                'Authorization': token
            },
            method: 'POST',
            body: JSON.stringify({ creativity: this.state.creativitySelected, textLength: this.state.textLengthSelected, id_article: this.props.articleId })
        })
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            this.props.updateSettings({ creativity: settings[this.state.creativitySelected], tokens: settings[this.state.textLengthSelected ]});
            this.setState({
                successParam: true
            }, () => {
                setTimeout(() => {
                    this.setState({
                        successParam: false
                    });
                }, 5000);
            });
        })
    }

    handleCloseAlert() {
        this.setState({
            successParam: false
        });
    }

    render() {

        const { t } = this.props;

        const renderTooltipCredits = (props) => (
            <Tooltip id="button-tooltip" {...props}>{t('tooltip.credits')}</Tooltip>
        );

        const parameters = 
            <div class="writing-franklin-header-bottom state-interaction-element">
                <Settings width="16" fill="#2B2B2B"/>
            </div>;

        return (
            <div class="writing-franklin-header position-relative">

                {this.state.successParam && <Alert onClick={this.handleCloseAlert} className={this.state.successParam && !this.props.bannerIsActive ? 'alert-msg-visible alert-msg-no-banner' : this.state.successParam ? 'alert-msg-visible' : ''} alertId="successMessage" msg={t('alert.writingFranklin.iaParam')}/> }
               
                <div class="writing-franklin-header-top d-flex flex-column-reverse flex-md-row align-items-center pb-3">
                    {this.props.lastSaved &&
                        <p class="mt-3 mt-md-0 ml-auto ml-md-0 mr-md-auto fz-14">
                            <Tick width="14" fill="#00C851"/>
                            <span class="ml-2">{t('writingFranklin.heading.lastUpdate') + this.props.lastSaved.getHours() + ':' + this.props.lastSaved.getMinutes()}</span>
                        </p>
                    }
                    <div class="d-flex flex-column flex-md-row align-items-center ml-auto">
                        <p class="mb-2 mb-md-0 w-md-100 text-right">
                            {this.props.credits.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + t('writingFranklin.heading.credits')}
                            <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltipCredits}>
                                <span class="ml-2"><Information fill="#2B2B2B" width="16"/></span>
                            </OverlayTrigger>
                        </p>

                        {/* Modal CTA reload credits */}
                        <ReloadCreditsModal 
                            trigger={<PmyBtn onClick={this.handleOpenReloadCredits} type="button" btnIsMediumPmyFull textBtn={t('writingFranklin.heading.mainCta')} containerStyle="ml-md-3"/>} 
                            modalTitle={t('writingFranklin.heading.mainCta')} 
                            isPro={this.props.isPro}
                        />
                    </div>
                </div>

                <div class="d-flex flex-row justify-content-between align-items-center mt-3">
                    <p>{this.props.wordsCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}{t('writingFranklin.heading.wordCount')}{this.props.wordsCount > 1 && 's'}</p>
                    {/* Modal AI Parameters */}
                    <LateralModal 
                        handleModalCloseNoSaved={this.handleModalCloseNoSaved}
                        handleClickCtaBtn={this.handleSavedParameters} 
                        trigger={parameters} 
                        triggerTitle={t('titleElementBrowser.writingFranklin.params')}
                        modalTitle={t('writingFranklin.lateralModal.title')}
                        ctaFooterPrimary={t('writingFranklin.lateralModal.ctaFooterPrimary')}
                        updateSettings={this.props.updateSettings}
                    >
                        <H3 title={t('writingFranklin.lateralModal.content.creativity.title')} className="mb-3"/>
                        <div class="btn-choices-container d-flex flex-row">
                            <button onClick={this.getCreativity} type="button" data-type="low" class={this.state.creativitySelected === 'low' && 'parameter-selected'}>{t('writingFranklin.lateralModal.content.creativity.level.low')}</button>
                            <button onClick={this.getCreativity} type="button" data-type="middle" class={this.state.creativitySelected === 'middle' && 'parameter-selected'}>{t('writingFranklin.lateralModal.content.creativity.level.medium')}</button>
                            <button onClick={this.getCreativity} type="button" data-type="high" class={this.state.creativitySelected  === 'high' && 'parameter-selected'}>{t('writingFranklin.lateralModal.content.creativity.level.high')}</button>
                        </div>
                        <div class="bgc-info mt-3 p-3 rounded">
                            <p class="mb-3">{t('writingFranklin.lateralModal.content.creativity.subtitle')}</p>
                            <p class="fz-14">{t('writingFranklin.lateralModal.content.creativity.p1')}<br/>{t('writingFranklin.lateralModal.content.creativity.p2')}</p>
                        </div>
                        <H3 title={t('writingFranklin.lateralModal.content.textLength.title')} className="mt-5 mb-3"/>
                        <div class="btn-choices-container d-flex flex-row">
                            <button onClick={this.getTextLength} type="button" data-type="short" class={this.state.textLengthSelected === 'short' && 'parameter-selected'}>{t('writingFranklin.lateralModal.content.textLength.level.short')}</button>
                            <button onClick={this.getTextLength} type="button" data-type="long" class={this.state.textLengthSelected === 'long' && 'parameter-selected'}>{t('writingFranklin.lateralModal.content.textLength.level.long')}</button>
                        </div>
                        <div class="bgc-info mt-3 p-3 rounded">
                            <p class="mb-3">{t('writingFranklin.lateralModal.content.textLength.subtitle')}</p>
                            <p class="fz-14">{t('writingFranklin.lateralModal.content.textLength.p1')}<br/>{t('writingFranklin.lateralModal.content.textLength.p2')}</p>
                        </div>
                    </LateralModal>
                </div>

            </div>
        )
    }
}

export default withTranslation()(Heading);