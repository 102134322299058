import React from 'react';
import { refreshTokenFnc } from '../../../../utils/refreshToken';
import { withTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import PmyBtn from '../../button/PmyBtn';
import Add from '../../../../assets/img/svg/Add';
import Input from '../../form/Input';
import Loader from '../../elements/Loader';
import NoData from '../../../../assets/img/svg/illustrations/NoData';
import KebabMenu from '../../../../assets/img/svg/KebabMenu';
import qs from 'qs';

class Sidebar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            listIsCharged : false,
            searchValue: '',
            nbrArticles: 0,
            currentArticle: null,
            optsIsOpen: false,
            currentOpt: false,
            articles: []
        }
        this.refreshArticles = this.refreshArticles.bind(this);
        this.handleCreateNewSubject = this.handleCreateNewSubject.bind(this);
        this.handleSearchSubject = this.handleSearchSubject.bind(this);
        this.handleSelectArticle = this.handleSelectArticle.bind(this);
        this.openMenuOpts = this.openMenuOpts.bind(this);
        this.closeMenuOpts = this.closeMenuOpts.bind(this);
        this.duplicateArticle = this.duplicateArticle.bind(this);
        this.exportArticle = this.exportArticle.bind(this);
        this.deleteArticle = this.deleteArticle.bind(this);
    }

    refreshArticles() {
        var token = localStorage.getItem('af_token');
        fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/articles', {
            headers: {
                'Authorization': token
            },
            method: 'GET',
        })
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            this.setState({
                articles: res.data,
                listIsCharged: true
            });
        })
        .catch((error) => {
            refreshTokenFnc(this.refreshArticles, false);
        })       
    }

    componentDidMount() {
        this.refreshArticles();
        if(qs.parse(this.props.currentLocation.search, { ignoreQueryPrefix: true }).selected){
            this.setState({
                currentArticle: qs.parse(this.props.currentLocation.search, { ignoreQueryPrefix: true }).selected
            }, () => {
                this.props.handleArticleChange(qs.parse(this.props.currentLocation.search, { ignoreQueryPrefix: true }).selected);
            });
        }
    }

    componentWillReceiveProps() {
        this.refreshArticles();
    }

    handleCreateNewSubject() {
        var token = localStorage.getItem('af_token');
        fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/articles/create', {
            headers: {
                'Authorization': token
            },
            method: 'POST',
        })
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            this.refreshArticles();
        })
    }

    handleSearchSubject(e) {
        this.setState({
            searchValue: e.target.value.toLowerCase()
        });
        if(e.target.value.length > 2) {
            this.setState({
                articles: this.state.articles.filter((article) => article[1].toLowerCase().includes(e.target.value.toLowerCase()))
            });
        } 
        else {
            this.refreshArticles();
        }
    }
    
    handleSelectArticle(e) {
        var idArticle = parseInt(e.target.dataset.key);
        this.setState({
            currentArticle: idArticle,
            searchValue: ''
        }, () => {
            this.props.handleArticleChange(idArticle);
        });
    }

    openMenuOpts(e) {
        e.stopPropagation();
        this.setState({
            optsIsOpen: true,
            currentOpt: parseInt(e.target.dataset.key)
        });
    }

    closeMenuOpts() {
        this.setState({
            optsIsOpen: false
        });
    }

    duplicateArticle() {
        console.log('duplicate article');
        this.optsBlock.blur();
    }

    transformSection(text, secType) {
        if (secType === 'h2' || secType === 'h3') {
            return '\n\n<' + secType + '>' + text + '</' + secType + '>\n\n';
        }
        else if (secType === 'paragraph') {
            return '<p>' + text + '</p>';
        }
        else {
            return text;
        }
    }

    exportArticle(e) {
        var token = localStorage.getItem('af_token');
        fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/articles/' + e.target.dataset.id, {
            headers: {
                'Authorization': token
            },
            method: 'GET'
        })
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            const element = document.createElement('a');
            var exportText = res.data.sections.map((section) => this.transformSection(section[1], section[2]));
            exportText.unshift('<h1>' + res.data.title + '</h1>' + '\n\n');
            const file = new Blob(exportText, {type: 'text/plain'});
            element.href = URL.createObjectURL(file);
            element.download = res.data.title + '.txt';
            document.body.appendChild(element);
            element.click();
            this.optsBlock.blur();
        })
    }

    deleteArticle(e) {
        var token = localStorage.getItem('af_token');
        fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/articles/' + e.target.dataset.id + '/delete', {
            headers: {
                'Authorization': token
            },
            method: 'GET',
        })
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            this.refreshArticles();
        })
    }

    render() {

        const { t } = this.props;

        const articleClass = ' article-item d-flex flex-row justify-content-between align-items-center p-3';
        const articleOpt = ' article-submenu position-absolute flex-column rounded';
        
        return (
            <Col xl="3" id="sidebarMenu" className="block-style d-flex d-xl-block flex-column h-100 p-0 mr-xl-4 mb-5 mb-xl-0">
                <div class="m-3">
                    <PmyBtn onClick={this.handleCreateNewSubject} type="button" isDisabled={!this.state.listIsCharged} btnIsMediumPmyOutlineLight textBtn={t('writingFranklin.sidebar.ctaNewArticle')} title={t('writingFranklin.sidebar.ctaNewArticle')} iconBtnBefore={<Add width="14" fill="#673AB7"/>} className="ml-auto fz-16-index tour-create-article"/>
                    <p class={!this.state.listIsCharged ? 'invisible my-3 text-right' : 'my-3 text-right' }><span>{this.state.articles.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</span> article{this.state.articles.length > 1 && 's'}</p>
                    <Input value={this.state.searchValue}  type="search" onChange={this.handleSearchSubject} hideLabel={true} for="filterArticles" placeholder={t('form.input.placeholderWritingFranklin.searchBar')} containerStyle="mb-0 pb-0"/>
                </div>
                <div class="articles-wrapper">
                    {
                        !this.state.listIsCharged ?
                            <div class="mb-5">
                                <Loader loaderDisplayed content={t('actions.loading')}/>
                            </div>

                        : this.state.articles.length === 0 ?
                            <div class="m-3 text-center">
                                <p class="mb-4 fz-18">{t('writingFranklin.sidebar.noResult')}</p>
                                <NoData/>
                            </div>
                        
                        : this.state.articles.map((article, index) => { 
                            return (
                                <div 
                                    onClick={this.handleSelectArticle} 
                                    data-key={article[0]}
                                    key={index} 
                                    class={this.props.currentArticle === article[0] ? 'article-selected' + articleClass : articleClass}
                                    title={article.title}
                                >
                                    {
                                        article[1] ? 
                                            <p class="w-100 mr-3 overflow-hidden pointer-events-none">{article[1]}</p>
                                        :
                                            <p class="w-100 mr-3 overflow-hidden pointer-events-none color-disabled">{t('writingFranklin.sidebar.untitledArticle')}</p>
                                    }
                                    <div onFocus={this.openMenuOpts} onBlur={this.closeMenuOpts} tabIndex={0} ref={c => this.optsBlock = c} data-key={article[0]} class="article-menu">
                                        <KebabMenu/>
                                        <ul class={this.state.currentOpt === article[0] ? 'd-flex' + articleOpt : 'd-none' + articleOpt}>
                                            {/*<li onClick={this.duplicateArticle} data-id={article[0]}>{t('actions.duplicate')}</li>*/}
                                            <li onClick={this.exportArticle} data-id={article[0]}>{t('actions.export')}</li>
                                            <li onClick={this.deleteArticle} data-id={article[0]}>{t('actions.delete')}</li>
                                        </ul>
                                    </div>
                                </div>
                            ); 
                        })
                    }
                </div>
            </Col>
        )
    }
}

export default withTranslation()(Sidebar);