import React from 'react';
import { withTranslation } from 'react-i18next';
import Tick from '../../../../assets/img/svg/Tick';
import PmyBtn from '../../../components/button/PmyBtn';
import ReloadCreditsModal from '../../../components/partials/modals/ReloadCreditsModal';

class Heading extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modalCredits: false,
            lastSaved: new Date(),
            credits: 2000,
        }
        this.handleOpenReloadCredits = this.handleOpenReloadCredits.bind(this);
    }

    handleOpenReloadCredits() {
        this.setState({
            modalCredits: this.state.modalCredits ? false : true
        });
    }

    render() {

        const { t } = this.props;

        return (
            <div class="writing-franklin-header position-relative">

       
               
                <div class="writing-franklin-header-top d-flex flex-column-reverse flex-md-row align-items-center pb-4">
                    {this.state.lastSaved &&
                        <p class="mt-3 mt-md-0 ml-auto ml-md-0 mr-md-auto fz-14">
                            <Tick width="14" fill="#00C851"/>
                            <span class="ml-2">{t('writingFranklin.heading.lastUpdate') + '15:49'}</span>
                        </p>
                    }
                    <div class="d-flex flex-column flex-md-row align-items-center ml-auto">
                        <p class="mb-2 mb-md-0 w-md-100 text-right">{this.state.credits + t('writingFranklin.heading.credits')}</p>
                        {/* Modal CTA reload credits */}
                        <ReloadCreditsModal 
                            trigger={<PmyBtn onClick={this.handleOpenReloadCredits} type="button" btnIsMediumPmyFull textBtn={t('writingFranklin.heading.mainCta')} containerStyle="ml-md-3"/>} 
                            modalTitle={t('writingFranklin.heading.mainCta')} 
                            isPro={this.props.isPro}
                        />
                    </div>
                </div>

            </div>
        )
    }
}

export default withTranslation()(Heading);