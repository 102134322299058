import React from 'react';
import { withTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import Intro from './Intro';
import Section from './Section';

class Wysiwyg extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            articleIsCharged: false,
            title: '',
            sections: [],
            wordsCount: 0,
            lastSaved: new Date(),
            credits: 2000,
            countdown: 0,
            currentCreativity: 0.75,
            currentTokens: 96,
            creativityStr: 'middle',
            textLengthStr: 'short'
        }
        this.refreshArticleContent = this.refreshArticleContent.bind(this);
        this.handleTitle = this.handleTitle.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleAddSection = this.handleAddSection.bind(this);
        this.handleDeleteSection = this.handleDeleteSection.bind(this);
        this.refreshLastSaved = this.refreshLastSaved.bind(this);
        this.refreshCredits = this.refreshCredits.bind(this);
        this.handleCountDown = this.handleCountDown.bind(this);
        this.updateSettings = this.updateSettings.bind(this);
    }

    refreshLastSaved() {
        this.setState({
            lastSaved: new Date()
        });
    }

    refreshCredits(credits) {
        this.setState({
            credits: credits
        });
    }
    
    updateSettings(settings) {
        var token = localStorage.getItem('af_token');
        fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/articles/' + this.props.currentArticle + '/settings', {
            headers: {
                'Authorization': token
            },
            method: 'GET'
        })
        .then((res2) => {
            return res2.json();
        })
        .then((res2) => {
            this.setState({
                creativityStr:(res2.data.creativity && res2.data.creativity.length > 0) ? res2.data.creativity : "middle",
                textLengthStr:(res2.data.textLength && res2.data.textLength.length > 0) ? res2.data.textLength : "short",
                currentCreativity: settings.creativity,
                currentTokens: settings.tokens,
            })

        })  
    }

    refreshArticleContent(articleId) {
        var token = localStorage.getItem('af_token');
        fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/articles/' + articleId, {
            headers: {
                'Authorization': token
            },
            method: 'GET'
        })
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/articles/' + articleId + '/settings', {
                headers: {
                    'Authorization': token
                },
                method: 'GET'
            })
            .then((res2) => {
                return res2.json();
            })
            .then((res2) => {
                this.setState({
                    title: res.data.title ? res.data.title : '',
                    sections: res.data.sections,
                    articleCharged: true,
                    creativityStr:(res2.data.creativity && res2.data.creativity.length > 0) ? res2.data.creativity : 'middle',
                    textLengthStr:(res2.data.textLength && res2.data.textLength.length > 0) ? res2.data.textLength  : 'short'
                });
                var count = res.data.title ? res.data.title.trim().split(/\s+/).length : 0
                res.data.sections.map((section) => {
                    count = count + section[1].trim().split(/\s+/).length
                });
                var sections = res.data.sections.map((section) => section[1]).join(' ');
                this.props.updateSections(sections);
                this.setState({
                    wordsCount: count
                });
            })  
        })
    }

    componentDidMount() {
        var token = localStorage.getItem('af_token');

        this.refreshArticleContent(this.props.currentArticle, true);

        fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/user/credit', {
            headers: {
                'Authorization': token
            },
            method: 'GET'
        })
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            this.setState({
                credits: res.message.credits
            });
        })
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.currentArticle !== this.props.currentArticle) {
            this.refreshArticleContent(nextProps.currentArticle, true);
        }
    }

    handleTitle(e) {
        var content = e.target.value;
        this.setState({
            title: content
        }, () => {
            var token = localStorage.getItem('af_token');
            fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/articles/update-title', {
                headers: {
                    'Authorization': token
                },
                method: 'POST',
                body: JSON.stringify({ id: this.props.currentArticle, title: content })
            })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                this.refreshLastSaved();
            })
        })
    }

    handleKeyDown(e) {
        if (e.keyCode === 13) {
            e.preventDefault();
        }
    }

    handleCountDown(ct) {
        this.setState({
            countdown: ct
        });
    }

    handleAddSection() {
        var token = localStorage.getItem('af_token');
        fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/articles/sections/create', {
            headers: {
                'Authorization': token
            },
            method: 'POST',
            body: JSON.stringify({ article_id: this.props.currentArticle, content: '', type: 'paragraph' })
        })
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            this.refreshArticleContent(this.props.currentArticle);
        })
    }

    handleDeleteSection(sectionId) {
        if (this.state.sections.length > 1) {
            var token = localStorage.getItem('af_token');
            fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/articles/sections/' + sectionId + '/delete', {
                headers: {
                    'Authorization': token
                },
                method: 'GET',
            })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                this.refreshArticleContent(this.props.currentArticle);
            })
        }
    }

    render() {

        const { t } = this.props;

        return (
            <Col lg="8" className="block-writing px-0 pr-lg-4 mb-5 mb-lg-0">
                <TextareaAutosize onChange={this.handleTitle} placeholder={t('form.input.placeholderWritingFranklin.title')} class="title" value={this.state.title}/>
                <Intro articleId={this.props.currentArticle} sections={this.state.sections}/>
                {this.state.sections.map((section) => {
                    return (
                        section[2] !== 'intro' && 
                            <Section 
                                key={section[0]}
                                articleId={this.props.currentArticle}
                                type={section[2]}
                                sectionId={section[0]}
                                content={section[1]}
                                handleAddSection={this.handleAddSection}
                                handleDeleteSection={this.handleDeleteSection}
                                refreshArticleContent={this.refreshArticleContent}
                                refreshLastSaved={this.refreshLastSaved}
                                tokens={this.props.tokens}
                                creativity={this.props.creativity}
                                refreshCredits={this.refreshCredits}
                                countdown={this.state.countdown}
                                handleCountDown={this.handleCountDown}
                                isPro={this.props.isPro}
                                minLength={this.props.minLength}
                                prevLength={this.props.prevLength}
                                content={this.props.content}
                                contentLength={this.props.contentLength}
                                isLoading={this.props.isLoading}
                                noCredits={this.props.noCredits}
                            />
                    );
                })}
            </Col>
        )
    }
}

export default withTranslation()(Wysiwyg);