import React from 'react';

export default class Reinsurance extends React.Component {

    render() {
        switch(this.props.icon) {
            case 'lock':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 32" width={this.props.width} height={this.props.height} fill="#2B2B2B">
                        <path d="M23.2,11.6A4.8,4.8,0,0,1,28,16.4V29.2A4.8,4.8,0,0,1,23.2,34H8.8A4.8,4.8,0,0,1,4,29.2V16.4a4.8,4.8,0,0,1,4.8-4.8V9.2a7.2,7.2,0,0,1,14.4,0ZM8.8,13.2a3.2,3.2,0,0,0-3.2,3.2V29.2a3.2,3.2,0,0,0,3.2,3.2H23.2a3.2,3.2,0,0,0,3.2-3.2V16.4a3.2,3.2,0,0,0-3.2-3.2Zm12.8-1.6V9.2a5.6,5.6,0,0,0-11.2,0v2.4ZM16,21.2a2.4,2.4,0,1,0,2.4,2.4A2.4,2.4,0,0,0,16,21.2Zm0-1.6a4,4,0,1,1-4,4A4,4,0,0,1,16,19.6Z" transform="translate(-4 -2)"/>
                    </svg>
                );
                        
            case 'thumb':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.673 32" width={this.props.width} height={this.props.height} fill="#2B2B2B">
                        <path d="M2.7,15.476h5.1a1.7,1.7,0,0,1,1.7,1.7V32.464a1.7,1.7,0,0,1-1.7,1.7H2.7a1.7,1.7,0,0,1-1.7-1.7V17.175A1.7,1.7,0,0,1,2.7,15.476Zm0,1.7V32.464h5.1V17.175ZM29.4,30.807l5.1-8.493a3.382,3.382,0,0,0,.48-1.742v-1.7a3.4,3.4,0,0,0-3.4-3.4h-9.53l2.476-9.24.008-.031a1.7,1.7,0,0,0-.44-1.641L13.885,14.771h0a3.387,3.387,0,0,0-1,2.4V29.066a3.4,3.4,0,0,0,3.4,3.4H26.481A3.389,3.389,0,0,0,29.4,30.807Zm7.277-10.235a5.072,5.072,0,0,1-.8,2.739l-4.936,8.226a5.1,5.1,0,0,1-4.458,2.625H16.288a5.1,5.1,0,0,1-5.1-5.1V17.175a5.08,5.08,0,0,1,1.489-3.6L24.092,2.162l1.2,1.2a3.4,3.4,0,0,1,.862,3.344l-1.894,7.07h7.316a5.1,5.1,0,0,1,5.1,5.1Z" transform="translate(-1 -2.162)"/>
                    </svg>
                );

            case 'support':
                return  (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.368 32" width={this.props.width} height={this.props.height} fill="#2B2B2B">
                        <path d="M2.684,25.881l6.039-6.039H24.579a3.368,3.368,0,0,0,3.368-3.368V8.053a3.368,3.368,0,0,0-3.368-3.368H6.053A3.368,3.368,0,0,0,2.684,8.053Zm0,2.382H1V8.053A5.053,5.053,0,0,1,6.053,3H24.579a5.053,5.053,0,0,1,5.053,5.053v8.421a5.053,5.053,0,0,1-5.053,5.053H9.421Zm32,4.355V14.789a3.368,3.368,0,0,0-3.368-3.368V9.737a5.053,5.053,0,0,1,5.053,5.053V35H34.684l-6.737-6.737H12.79a5.054,5.054,0,0,1-4.65-3.073l1.342-1.342a3.369,3.369,0,0,0,3.308,2.731H28.645Z" transform="translate(-1 -3)"/>
                    </svg>
                );
        }   
    }
}