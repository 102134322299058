import React from 'react';

export default class ToggleSwitch extends React.Component {
    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        e.preventDefault();
        this.props.handleSwitch(this.props.value);
    }

    render() {

        const wrapperSwitch = ' toggle-switch w-max-content';

        return (
            <div class={this.props.containerStyle ? this.props.containerStyle + wrapperSwitch : wrapperSwitch}>
                <label onClick={this.handleClick} for={this.props.for} className="d-flex flex-row align-items-center cursor-pointer">
                    {this.props.labelBefore && <span class="mr-2 text-right">{this.props.labelBefore}</span> }
                    <div class="d-flex">
                        <input type="checkbox" checked={this.props.isChecked} value={this.props.value} id={this.props.for} name={this.props.for}/>
                        <span class={this.props.isChecked ? 'switch-dot switch-dot-active' : 'switch-dot'}></span>
                    </div>
                    {this.props.labelAfter && 
                        <span class="ml-2 text-left position-relative">
                            {this.props.labelAfter}
                            {this.props.afterInfo && <span class="label-info ml-2 position-absolute py-1 px-2 rounded w-max-content pointer-events-none fz-14">{this.props.afterInfo}</span> }
                        </span>
                    }
                    
                </label>
            </div>
        )
    }
}