import React from 'react';

export default class Logo extends React.Component {

    render() {
        switch(this.props.icon) {
            case 'global':
                return <svg class="logo-asking-franklin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 51.521" width={this.props.width} height={this.props.height}>
                            <g transform="translate(-0.359 -1.922)">
                                <g transform="translate(0.359 4.7)">
                                    <g transform="translate(0 2.474)">
                                        <path class="logo-c-pmy" d="M60.291,9.087,11.842,8.2A11.278,11.278,0,0,0,.4,20.356c.355,4.969.8,10.559,1.154,15.174A11.441,11.441,0,0,0,12.286,46l38.866,1.952A14.076,14.076,0,0,0,59.4,53.809a.733.733,0,0,0,.8-1.065,15.1,15.1,0,0,1-1.6-4.437h.532a11.313,11.313,0,0,0,11.89-11l.444-16.593A11.344,11.344,0,0,0,60.291,9.087Z" transform="translate(-0.359 -7.568)" fill="#673ab7"/>
                                        <path class="logo-c-pmy" d="M65.373,7.5,16.924,9.54A11.291,11.291,0,0,0,6.187,22.317c.621,4.969,1.42,10.471,2.041,15.085a11.312,11.312,0,0,0,11.358,9.761l38.954-.444a13.794,13.794,0,0,0,8.518,5.324.689.689,0,0,0,.71-1.065,16.118,16.118,0,0,1-1.863-4.348h.532a11.311,11.311,0,0,0,11.18-11.713l-.532-16.593A11.132,11.132,0,0,0,65.373,7.5Z" transform="translate(-1.004 -7.488)" fill="#673ab7"/>
                                        <path class="logo-c-scy" d="M62.343,8.2,13.895,9a11.319,11.319,0,0,0-11,12.512c.532,4.969,1.154,10.559,1.686,15.174A11.3,11.3,0,0,0,15.669,46.71l38.954.621a13.821,13.821,0,0,0,8.43,5.59.748.748,0,0,0,.8-1.065,15.664,15.664,0,0,1-1.775-4.437h.532A11.363,11.363,0,0,0,74.145,36.062L74.056,19.38A11.6,11.6,0,0,0,62.343,8.2Z" transform="translate(-0.636 -7.568)" fill="#e94969"/>
                                    </g>
                                    <g transform="translate(17.761)">
                                        <path d="M50.034,14c-2.485-4.792-5.5-6.833-7.9-6.211s-3.549,2.662-7.276,6.655c-3.017,3.283-7.9,5.679-11.8,6.744-1.42.355-2.13.266-2.4,1.242-.621,2.485-.532,8.518,3.283,11.979a1.617,1.617,0,0,0,1.6.355,13.688,13.688,0,0,0,1.508-.444.6.6,0,0,1,.71.266A11.378,11.378,0,0,1,29.093,38.4a9.678,9.678,0,0,1,0,3.993c-.089.444.089,1.065,1.065,1.331s4.348.887,5.324,1.065,1.42-.532.887-1.331a30.384,30.384,0,0,1-2.218-4.171l.621-.8a.905.905,0,0,0,.177-.8l-.71-2.13a.954.954,0,0,0-.8-.71c-.266,0-.532-.089-.8-.089-.177-.8-.266-1.242-.266-1.242a11.731,11.731,0,0,1,7.542.71c4.171,1.775,7.276,3.106,9.849,2.485s3.638-4.88,3.816-8.075C53.761,25.533,52.519,18.789,50.034,14Z" transform="translate(-20.375 -5.035)" fill="none" stroke="#673ab7" strokeMiterlimit="10" strokeWidth="5.12"/>
                                        <path d="M45.961,10.2c-1.686.444-2.218,3.993-1.686,8.7a4.707,4.707,0,0,1,3.816,3.638,4.8,4.8,0,0,1-1.508,5.147c1.775,4.525,4.171,7.542,5.856,7.1,2.218-.621,2.485-6.566.71-13.31C51.374,14.632,48.179,9.663,45.961,10.2Z" transform="translate(-23.045 -5.315)" fill="none" stroke="#673ab7" strokeMiterlimit="10" strokeWidth="5.12"/>
                                        <path d="M58.554,12.154a.8.8,0,0,1-.887-.266.965.965,0,0,1,0-1.331l5.5-5.59A.941.941,0,0,1,64.5,6.3L59,11.8C58.82,11.976,58.642,12.065,58.554,12.154Z" transform="translate(-24.546 -4.7)" fill="none" stroke="#673ab7" strokeMiterlimit="10" strokeWidth="5.12"/>
                                        <path d="M70.783,32.8H70.25l-7.542-2.041a.958.958,0,1,1,.444-1.863l7.542,2.041a.989.989,0,0,1,.71,1.154A1.014,1.014,0,0,1,70.783,32.8Z" transform="translate(-25.062 -7.424)" fill="none" stroke="#673ab7" strokeMiterlimit="10" strokeWidth="5.12"/>
                                        <path d="M70.655,18.758l-8.607,2.307A.958.958,0,0,1,61.6,19.2l8.607-2.307a.887.887,0,0,1,1.154.71A1.018,1.018,0,0,1,70.655,18.758Z" transform="translate(-24.934 -6.069)" fill="none" stroke="#673ab7" strokeMiterlimit="10" strokeWidth="5.12"/>
                                        <path d="M50.034,14c-2.485-4.792-5.5-6.833-7.9-6.211s-3.549,2.662-7.276,6.655c-3.017,3.283-7.9,5.679-11.8,6.744-1.42.355-2.13.266-2.4,1.242-.621,2.485-.532,8.518,3.283,11.979a1.617,1.617,0,0,0,1.6.355,13.688,13.688,0,0,0,1.508-.444.6.6,0,0,1,.71.266A11.378,11.378,0,0,1,29.093,38.4a9.678,9.678,0,0,1,0,3.993c-.089.444.089,1.065,1.065,1.331s4.348.887,5.324,1.065,1.42-.532.887-1.331a30.384,30.384,0,0,1-2.218-4.171l.621-.8a.905.905,0,0,0,.177-.8l-.71-2.13a.954.954,0,0,0-.8-.71c-.266,0-.532-.089-.8-.089-.177-.8-.266-1.242-.266-1.242a11.731,11.731,0,0,1,7.542.71c4.171,1.775,7.276,3.106,9.849,2.485s3.638-4.88,3.816-8.075C53.761,25.533,52.519,18.789,50.034,14Z" transform="translate(-20.375 -5.035)" fill="#fff"/>
                                        <path d="M45.961,10.2c-1.686.444-2.218,3.993-1.686,8.7a4.707,4.707,0,0,1,3.816,3.638,4.8,4.8,0,0,1-1.508,5.147c1.775,4.525,4.171,7.542,5.856,7.1,2.218-.621,2.485-6.566.71-13.31C51.374,14.632,48.179,9.663,45.961,10.2Z" transform="translate(-23.045 -5.315)" fill="#673ab7"/>
                                        <path d="M58.554,12.154a.8.8,0,0,1-.887-.266.965.965,0,0,1,0-1.331l5.5-5.59A.941.941,0,0,1,64.5,6.3L59,11.8C58.82,11.976,58.642,12.065,58.554,12.154Z" transform="translate(-24.546 -4.7)" fill="#fff"/>
                                        <path d="M70.783,32.8H70.25l-7.542-2.041a.958.958,0,1,1,.444-1.863l7.542,2.041a.989.989,0,0,1,.71,1.154A1.014,1.014,0,0,1,70.783,32.8Z" transform="translate(-25.062 -7.424)" fill="#fff"/>
                                        <path d="M70.655,18.758l-8.607,2.307A.958.958,0,0,1,61.6,19.2l8.607-2.307a.887.887,0,0,1,1.154.71A1.018,1.018,0,0,1,70.655,18.758Z" transform="translate(-24.934 -6.069)" fill="#fff"/>
                                    </g>
                                </g>
                                <g transform="translate(93.076 10.067)">
                                    <path d="M122.662,15.8,134.02,42.42h-6.211l-1.775-4.082h-9.761L114.5,42.42h-7.1L119.823,15.8Zm-1.686,10.382-3.017,6.744h6.211Z" transform="translate(-107.4 -13.493)" fill="#673ab7"/>
                                    <path d="M149.606,24.8a12.406,12.406,0,0,1,7.809,2.4l-2.928,4.259a6.582,6.582,0,0,0-4.7-1.952,2.668,2.668,0,0,0-1.6.355,1.08,1.08,0,0,0-.532.887.832.832,0,0,0,.532.71,8.253,8.253,0,0,0,1.863.532,14.993,14.993,0,0,1,5.945,2.13,4.21,4.21,0,0,1,1.775,3.461,5.275,5.275,0,0,1-2.4,4.614,11.354,11.354,0,0,1-6.389,1.6q-5.856,0-8.785-2.662L143.04,36.6a10.516,10.516,0,0,0,2.751,1.952,7.232,7.232,0,0,0,3.283.621c1.508,0,2.307-.355,2.307-1.154a.853.853,0,0,0-.532-.8A7.118,7.118,0,0,0,148.9,36.6a17.76,17.76,0,0,1-5.856-2.13,4.142,4.142,0,0,1-1.775-3.549,5.36,5.36,0,0,1,2.307-4.525A9.957,9.957,0,0,1,149.606,24.8Z" transform="translate(-111.095 -14.507)" fill="#673ab7"/>
                                    <path d="M170.978,14.2V29.906l6.123-5.945h8.341l-7.276,6.921,7.542,11.358h-7.72l-4.7-7.01-2.4,2.307v4.792H164.5V14.2Z" transform="translate(-113.833 -13.313)" fill="#673ab7"/>
                                    <path d="M198.489,25.2V43.479H192.1V25.2Z" transform="translate(-116.942 -14.552)" fill="#673ab7"/>
                                    <circle cx="3.549" cy="3.549" r="3.549" transform="translate(74.448)" fill="none" stroke="#e94969" strokeMiterlimit="10" strokeWidth="2"/>
                                    <path d="M217.424,24.9a7.215,7.215,0,0,1,5.856,2.485,9.584,9.584,0,0,1,2.13,6.566v9.583h-6.389V34.306a4.282,4.282,0,0,0-.887-2.928,3.1,3.1,0,0,0-2.485-1.065,3.222,3.222,0,0,0-2.485,1.065,4.233,4.233,0,0,0-.976,2.839v9.14H205.8V25.166h6.389V27.03A6.27,6.27,0,0,1,217.424,24.9Z" transform="translate(-118.486 -14.518)" fill="#673ab7"/>
                                    <path d="M241.783,24.9a7.746,7.746,0,0,1,3.283.621,5.515,5.515,0,0,1,2.218,1.6V25.255h6.389V43.534a8.068,8.068,0,0,1-3.194,6.833,13.026,13.026,0,0,1-8.075,2.4c-4.171,0-7.454-1.154-9.849-3.549l3.727-4.525a7.256,7.256,0,0,0,6.034,2.751,6.248,6.248,0,0,0,3.372-.976,3.188,3.188,0,0,0,1.42-2.928V41.671a6.222,6.222,0,0,1-2.218,1.6,8.021,8.021,0,0,1-3.283.621,9.106,9.106,0,0,1-9.406-9.406,9.233,9.233,0,0,1,2.662-6.744A9.517,9.517,0,0,1,241.783,24.9Zm1.331,5.324a4.217,4.217,0,0,0-3.106,1.154,3.882,3.882,0,0,0-1.154,3.017,3.882,3.882,0,0,0,1.154,3.017,4.4,4.4,0,0,0,3.106,1.065,4.217,4.217,0,0,0,3.106-1.154,3.813,3.813,0,0,0,1.154-2.928v-.089a3.747,3.747,0,0,0-1.242-2.928A3.882,3.882,0,0,0,243.114,30.224Z" transform="translate(-121.46 -14.518)" fill="#673ab7"/>
                                </g>
                                <g transform="translate(241.879 10.7)">
                                    <path d="M295.72,15.8v6.123H282.5v5.058h11.18v5.768H282.5V42.42h-7.1V15.8h20.32Z" transform="translate(-275.4 -13.937)" fill="#673ab7"/>
                                    <path d="M313.989,24.9v5.5a8.18,8.18,0,0,0-4.525,1.154,3.335,3.335,0,0,0-1.775,3.106v8.785H301.3V25.166h6.389v2.218C308.842,25.7,310.972,24.9,313.989,24.9Z" transform="translate(-278.318 -14.962)" fill="#673ab7"/>
                                    <path d="M327.406,24.9a7.746,7.746,0,0,1,3.283.621,5.515,5.515,0,0,1,2.218,1.6V25.255H339.3V43.534h-6.389V41.671a6.222,6.222,0,0,1-2.218,1.6,8.021,8.021,0,0,1-3.283.621A9.106,9.106,0,0,1,318,34.483a9.233,9.233,0,0,1,2.662-6.744A8.728,8.728,0,0,1,327.406,24.9Zm1.331,5.324a4.552,4.552,0,0,0-3.106,1.065,3.882,3.882,0,0,0-1.154,3.017,4.012,4.012,0,0,0,1.154,3.017,4.447,4.447,0,0,0,3.106,1.154A4.095,4.095,0,0,0,333,34.395v-.089a3.688,3.688,0,0,0-1.154-2.928A4.217,4.217,0,0,0,328.737,30.224Z" transform="translate(-280.199 -14.962)" fill="#673ab7"/>
                                    <path d="M360.224,24.9a7.215,7.215,0,0,1,5.856,2.485,9.584,9.584,0,0,1,2.13,6.566v9.583h-6.389V34.306a4.282,4.282,0,0,0-.887-2.928,3.1,3.1,0,0,0-2.485-1.065,3.222,3.222,0,0,0-2.485,1.065,4.233,4.233,0,0,0-.976,2.839v9.14H348.6V25.166h6.389V27.03A6.27,6.27,0,0,1,360.224,24.9Z" transform="translate(-283.647 -14.962)" fill="#673ab7"/>
                                    <path d="M383.389,14.2V29.906l6.123-5.945h8.341l-7.276,6.921,7.542,11.358H390.4l-4.7-7.01-2.4,2.307v4.792H377V14.2Z" transform="translate(-286.846 -13.756)" fill="#673ab7"/>
                                    <path d="M410.989,14.2V42.24H404.6V14.2Z" transform="translate(-289.956 -13.756)" fill="#673ab7"/>
                                    <path d="M424.589,25.2V43.479H418.2V25.2Z" transform="translate(-291.488 -14.996)" fill="#673ab7"/>
                                    <circle cx="3.549" cy="3.549" r="3.549" transform="translate(126.357)" fill="none" stroke="#e94969" strokeMiterlimit="10" strokeWidth="2"/>
                                    <path d="M443.524,24.9a7.215,7.215,0,0,1,5.856,2.485,9.584,9.584,0,0,1,2.13,6.566v9.583h-6.389V34.306a4.283,4.283,0,0,0-.887-2.928,3.1,3.1,0,0,0-2.485-1.065,3.222,3.222,0,0,0-2.485,1.065,4.233,4.233,0,0,0-.976,2.839v9.14H431.9V25.166h6.389V27.03C439.354,25.61,441.128,24.9,443.524,24.9Z" transform="translate(-293.031 -14.962)" fill="#673ab7"/>
                                </g>
                            </g>
                        </svg>

            case 'white':
                return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 51.521" width={this.props.width} height={this.props.height}>
                            <g transform="translate(-0.359 -1.922)">
                                <g transform="translate(0.359 4.7)">
                                    <g transform="translate(0 2.474)">
                                        <path class="logo-c-pmy" d="M60.291,9.087,11.842,8.2A11.278,11.278,0,0,0,.4,20.356c.355,4.969.8,10.559,1.154,15.174A11.441,11.441,0,0,0,12.286,46l38.866,1.952A14.076,14.076,0,0,0,59.4,53.809a.733.733,0,0,0,.8-1.065,15.1,15.1,0,0,1-1.6-4.437h.532a11.313,11.313,0,0,0,11.89-11l.444-16.593A11.344,11.344,0,0,0,60.291,9.087Z" transform="translate(-0.359 -7.568)" fill="#673ab7"/>
                                        <path class="logo-c-pmy" d="M65.373,7.5,16.924,9.54A11.291,11.291,0,0,0,6.187,22.317c.621,4.969,1.42,10.471,2.041,15.085a11.312,11.312,0,0,0,11.358,9.761l38.954-.444a13.794,13.794,0,0,0,8.518,5.324.689.689,0,0,0,.71-1.065,16.118,16.118,0,0,1-1.863-4.348h.532a11.311,11.311,0,0,0,11.18-11.713l-.532-16.593A11.132,11.132,0,0,0,65.373,7.5Z" transform="translate(-1.004 -7.488)" fill="#673ab7"/>
                                        <path class="logo-c-scy" d="M62.343,8.2,13.895,9a11.319,11.319,0,0,0-11,12.512c.532,4.969,1.154,10.559,1.686,15.174A11.3,11.3,0,0,0,15.669,46.71l38.954.621a13.821,13.821,0,0,0,8.43,5.59.748.748,0,0,0,.8-1.065,15.664,15.664,0,0,1-1.775-4.437h.532A11.363,11.363,0,0,0,74.145,36.062L74.056,19.38A11.6,11.6,0,0,0,62.343,8.2Z" transform="translate(-0.636 -7.568)" fill="#e94969"/>
                                    </g>
                                    <g transform="translate(17.761)">
                                        <path d="M50.034,14c-2.485-4.792-5.5-6.833-7.9-6.211s-3.549,2.662-7.276,6.655c-3.017,3.283-7.9,5.679-11.8,6.744-1.42.355-2.13.266-2.4,1.242-.621,2.485-.532,8.518,3.283,11.979a1.617,1.617,0,0,0,1.6.355,13.688,13.688,0,0,0,1.508-.444.6.6,0,0,1,.71.266A11.378,11.378,0,0,1,29.093,38.4a9.678,9.678,0,0,1,0,3.993c-.089.444.089,1.065,1.065,1.331s4.348.887,5.324,1.065,1.42-.532.887-1.331a30.384,30.384,0,0,1-2.218-4.171l.621-.8a.905.905,0,0,0,.177-.8l-.71-2.13a.954.954,0,0,0-.8-.71c-.266,0-.532-.089-.8-.089-.177-.8-.266-1.242-.266-1.242a11.731,11.731,0,0,1,7.542.71c4.171,1.775,7.276,3.106,9.849,2.485s3.638-4.88,3.816-8.075C53.761,25.533,52.519,18.789,50.034,14Z" transform="translate(-20.375 -5.035)" fill="none" stroke="#673ab7" strokeMiterlimit="10" strokeWidth="5.12"/>
                                        <path d="M45.961,10.2c-1.686.444-2.218,3.993-1.686,8.7a4.707,4.707,0,0,1,3.816,3.638,4.8,4.8,0,0,1-1.508,5.147c1.775,4.525,4.171,7.542,5.856,7.1,2.218-.621,2.485-6.566.71-13.31C51.374,14.632,48.179,9.663,45.961,10.2Z" transform="translate(-23.045 -5.315)" fill="none" stroke="#673ab7" strokeMiterlimit="10" strokeWidth="5.12"/>
                                        <path d="M58.554,12.154a.8.8,0,0,1-.887-.266.965.965,0,0,1,0-1.331l5.5-5.59A.941.941,0,0,1,64.5,6.3L59,11.8C58.82,11.976,58.642,12.065,58.554,12.154Z" transform="translate(-24.546 -4.7)" fill="none" stroke="#673ab7" strokeMiterlimit="10" strokeWidth="5.12"/>
                                        <path d="M70.783,32.8H70.25l-7.542-2.041a.958.958,0,1,1,.444-1.863l7.542,2.041a.989.989,0,0,1,.71,1.154A1.014,1.014,0,0,1,70.783,32.8Z" transform="translate(-25.062 -7.424)" fill="none" stroke="#673ab7" strokeMiterlimit="10" strokeWidth="5.12"/>
                                        <path d="M70.655,18.758l-8.607,2.307A.958.958,0,0,1,61.6,19.2l8.607-2.307a.887.887,0,0,1,1.154.71A1.018,1.018,0,0,1,70.655,18.758Z" transform="translate(-24.934 -6.069)" fill="none" stroke="#673ab7" strokeMiterlimit="10" strokeWidth="5.12"/>
                                        <path d="M50.034,14c-2.485-4.792-5.5-6.833-7.9-6.211s-3.549,2.662-7.276,6.655c-3.017,3.283-7.9,5.679-11.8,6.744-1.42.355-2.13.266-2.4,1.242-.621,2.485-.532,8.518,3.283,11.979a1.617,1.617,0,0,0,1.6.355,13.688,13.688,0,0,0,1.508-.444.6.6,0,0,1,.71.266A11.378,11.378,0,0,1,29.093,38.4a9.678,9.678,0,0,1,0,3.993c-.089.444.089,1.065,1.065,1.331s4.348.887,5.324,1.065,1.42-.532.887-1.331a30.384,30.384,0,0,1-2.218-4.171l.621-.8a.905.905,0,0,0,.177-.8l-.71-2.13a.954.954,0,0,0-.8-.71c-.266,0-.532-.089-.8-.089-.177-.8-.266-1.242-.266-1.242a11.731,11.731,0,0,1,7.542.71c4.171,1.775,7.276,3.106,9.849,2.485s3.638-4.88,3.816-8.075C53.761,25.533,52.519,18.789,50.034,14Z" transform="translate(-20.375 -5.035)" fill="#fff"/>
                                        <path d="M45.961,10.2c-1.686.444-2.218,3.993-1.686,8.7a4.707,4.707,0,0,1,3.816,3.638,4.8,4.8,0,0,1-1.508,5.147c1.775,4.525,4.171,7.542,5.856,7.1,2.218-.621,2.485-6.566.71-13.31C51.374,14.632,48.179,9.663,45.961,10.2Z" transform="translate(-23.045 -5.315)" fill="#673ab7"/>
                                        <path d="M58.554,12.154a.8.8,0,0,1-.887-.266.965.965,0,0,1,0-1.331l5.5-5.59A.941.941,0,0,1,64.5,6.3L59,11.8C58.82,11.976,58.642,12.065,58.554,12.154Z" transform="translate(-24.546 -4.7)" fill="#fff"/>
                                        <path d="M70.783,32.8H70.25l-7.542-2.041a.958.958,0,1,1,.444-1.863l7.542,2.041a.989.989,0,0,1,.71,1.154A1.014,1.014,0,0,1,70.783,32.8Z" transform="translate(-25.062 -7.424)" fill="#fff"/>
                                        <path d="M70.655,18.758l-8.607,2.307A.958.958,0,0,1,61.6,19.2l8.607-2.307a.887.887,0,0,1,1.154.71A1.018,1.018,0,0,1,70.655,18.758Z" transform="translate(-24.934 -6.069)" fill="#fff"/>
                                    </g>
                                </g>
                                <g transform="translate(93.076 10.067)">
                                    <path d="M122.662,15.8,134.02,42.42h-6.211l-1.775-4.082h-9.761L114.5,42.42h-7.1L119.823,15.8Zm-1.686,10.382-3.017,6.744h6.211Z" transform="translate(-107.4 -13.493)" fill="#fff"/>
                                    <path d="M149.606,24.8a12.406,12.406,0,0,1,7.809,2.4l-2.928,4.259a6.582,6.582,0,0,0-4.7-1.952,2.668,2.668,0,0,0-1.6.355,1.08,1.08,0,0,0-.532.887.832.832,0,0,0,.532.71,8.253,8.253,0,0,0,1.863.532,14.993,14.993,0,0,1,5.945,2.13,4.21,4.21,0,0,1,1.775,3.461,5.275,5.275,0,0,1-2.4,4.614,11.354,11.354,0,0,1-6.389,1.6q-5.856,0-8.785-2.662L143.04,36.6a10.516,10.516,0,0,0,2.751,1.952,7.232,7.232,0,0,0,3.283.621c1.508,0,2.307-.355,2.307-1.154a.853.853,0,0,0-.532-.8A7.118,7.118,0,0,0,148.9,36.6a17.76,17.76,0,0,1-5.856-2.13,4.142,4.142,0,0,1-1.775-3.549,5.36,5.36,0,0,1,2.307-4.525A9.957,9.957,0,0,1,149.606,24.8Z" transform="translate(-111.095 -14.507)" fill="#fff"/>
                                    <path d="M170.978,14.2V29.906l6.123-5.945h8.341l-7.276,6.921,7.542,11.358h-7.72l-4.7-7.01-2.4,2.307v4.792H164.5V14.2Z" transform="translate(-113.833 -13.313)" fill="#fff"/>
                                    <path d="M198.489,25.2V43.479H192.1V25.2Z" transform="translate(-116.942 -14.552)" fill="#fff"/>
                                    <circle cx="3.549" cy="3.549" r="3.549" transform="translate(74.448)" fill="none" stroke="#e94969" strokeMiterlimit="10" strokeWidth="2"/>
                                    <path d="M217.424,24.9a7.215,7.215,0,0,1,5.856,2.485,9.584,9.584,0,0,1,2.13,6.566v9.583h-6.389V34.306a4.282,4.282,0,0,0-.887-2.928,3.1,3.1,0,0,0-2.485-1.065,3.222,3.222,0,0,0-2.485,1.065,4.233,4.233,0,0,0-.976,2.839v9.14H205.8V25.166h6.389V27.03A6.27,6.27,0,0,1,217.424,24.9Z" transform="translate(-118.486 -14.518)" fill="#fff"/>
                                    <path d="M241.783,24.9a7.746,7.746,0,0,1,3.283.621,5.515,5.515,0,0,1,2.218,1.6V25.255h6.389V43.534a8.068,8.068,0,0,1-3.194,6.833,13.026,13.026,0,0,1-8.075,2.4c-4.171,0-7.454-1.154-9.849-3.549l3.727-4.525a7.256,7.256,0,0,0,6.034,2.751,6.248,6.248,0,0,0,3.372-.976,3.188,3.188,0,0,0,1.42-2.928V41.671a6.222,6.222,0,0,1-2.218,1.6,8.021,8.021,0,0,1-3.283.621,9.106,9.106,0,0,1-9.406-9.406,9.233,9.233,0,0,1,2.662-6.744A9.517,9.517,0,0,1,241.783,24.9Zm1.331,5.324a4.217,4.217,0,0,0-3.106,1.154,3.882,3.882,0,0,0-1.154,3.017,3.882,3.882,0,0,0,1.154,3.017,4.4,4.4,0,0,0,3.106,1.065,4.217,4.217,0,0,0,3.106-1.154,3.813,3.813,0,0,0,1.154-2.928v-.089a3.747,3.747,0,0,0-1.242-2.928A3.882,3.882,0,0,0,243.114,30.224Z" transform="translate(-121.46 -14.518)" fill="#fff"/>
                                </g>
                                <g transform="translate(241.879 10.7)">
                                    <path d="M295.72,15.8v6.123H282.5v5.058h11.18v5.768H282.5V42.42h-7.1V15.8h20.32Z" transform="translate(-275.4 -13.937)" fill="#fff"/>
                                    <path d="M313.989,24.9v5.5a8.18,8.18,0,0,0-4.525,1.154,3.335,3.335,0,0,0-1.775,3.106v8.785H301.3V25.166h6.389v2.218C308.842,25.7,310.972,24.9,313.989,24.9Z" transform="translate(-278.318 -14.962)" fill="#fff"/>
                                    <path d="M327.406,24.9a7.746,7.746,0,0,1,3.283.621,5.515,5.515,0,0,1,2.218,1.6V25.255H339.3V43.534h-6.389V41.671a6.222,6.222,0,0,1-2.218,1.6,8.021,8.021,0,0,1-3.283.621A9.106,9.106,0,0,1,318,34.483a9.233,9.233,0,0,1,2.662-6.744A8.728,8.728,0,0,1,327.406,24.9Zm1.331,5.324a4.552,4.552,0,0,0-3.106,1.065,3.882,3.882,0,0,0-1.154,3.017,4.012,4.012,0,0,0,1.154,3.017,4.447,4.447,0,0,0,3.106,1.154A4.095,4.095,0,0,0,333,34.395v-.089a3.688,3.688,0,0,0-1.154-2.928A4.217,4.217,0,0,0,328.737,30.224Z" transform="translate(-280.199 -14.962)" fill="#fff"/>
                                    <path d="M360.224,24.9a7.215,7.215,0,0,1,5.856,2.485,9.584,9.584,0,0,1,2.13,6.566v9.583h-6.389V34.306a4.282,4.282,0,0,0-.887-2.928,3.1,3.1,0,0,0-2.485-1.065,3.222,3.222,0,0,0-2.485,1.065,4.233,4.233,0,0,0-.976,2.839v9.14H348.6V25.166h6.389V27.03A6.27,6.27,0,0,1,360.224,24.9Z" transform="translate(-283.647 -14.962)" fill="#fff"/>
                                    <path d="M383.389,14.2V29.906l6.123-5.945h8.341l-7.276,6.921,7.542,11.358H390.4l-4.7-7.01-2.4,2.307v4.792H377V14.2Z" transform="translate(-286.846 -13.756)" fill="#fff"/>
                                    <path d="M410.989,14.2V42.24H404.6V14.2Z" transform="translate(-289.956 -13.756)" fill="#fff"/>
                                    <path d="M424.589,25.2V43.479H418.2V25.2Z" transform="translate(-291.488 -14.996)" fill="#fff"/>
                                    <circle cx="3.549" cy="3.549" r="3.549" transform="translate(126.357)" fill="none" stroke="#e94969" strokeMiterlimit="10" strokeWidth="2"/>
                                    <path d="M443.524,24.9a7.215,7.215,0,0,1,5.856,2.485,9.584,9.584,0,0,1,2.13,6.566v9.583h-6.389V34.306a4.283,4.283,0,0,0-.887-2.928,3.1,3.1,0,0,0-2.485-1.065,3.222,3.222,0,0,0-2.485,1.065,4.233,4.233,0,0,0-.976,2.839v9.14H431.9V25.166h6.389V27.03C439.354,25.61,441.128,24.9,443.524,24.9Z" transform="translate(-293.031 -14.962)" fill="#fff"/>
                                </g>
                            </g>
                        </svg>
            case 'picto':
                return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.306 26.93" width={this.props.width} height={this.props.height} class={this.props.className} style={this.props.style}>
                            <g transform="translate(0 2.56)">
                                <g transform="translate(0 1.24)">
                                    <path d="M30.328,8.643,6.1,8.2A5.639,5.639,0,0,0,.383,14.277c.177,2.484.4,5.279.577,7.587A5.72,5.72,0,0,0,6.326,27.1l19.433.976A7.038,7.038,0,0,0,29.882,31a.366.366,0,0,0,.4-.532,7.55,7.55,0,0,1-.8-2.218h.266a5.656,5.656,0,0,0,5.945-5.5l.222-8.3a5.672,5.672,0,0,0-5.587-5.813Z" transform="translate(-0.366 -7.881)" fill="#673ab7" />
                                    <path d="M35.73,7.492,11.506,8.512A5.645,5.645,0,0,0,6.137,14.9c.31,2.484.71,5.235,1.02,7.542a5.656,5.656,0,0,0,5.679,4.88L32.313,27.1a6.9,6.9,0,0,0,4.259,2.662.344.344,0,0,0,.355-.532A8.059,8.059,0,0,1,36,27.057h.266a5.655,5.655,0,0,0,5.59-5.856l-.266-8.3A5.566,5.566,0,0,0,35.73,7.492Z" transform="translate(-3.549 -7.485)" fill="#673ab7" />
                                    <path d="M32.587,8.2,8.363,8.6a5.659,5.659,0,0,0-5.5,6.256c.266,2.484.577,5.279.843,7.587A5.65,5.65,0,0,0,9.25,27.454l19.477.31a6.91,6.91,0,0,0,4.215,2.795.374.374,0,0,0,.4-.532,7.832,7.832,0,0,1-.887-2.218h.266a5.681,5.681,0,0,0,5.767-5.678l-.044-8.341A5.8,5.8,0,0,0,32.587,8.2Z" transform="translate(-1.737 -7.882)" fill="#e94969" />
                                </g>
                                <g transform="translate(8.874 0)">
                                    <path d="M35.2,10.84c-1.242-2.4-2.75-3.416-3.95-3.105s-1.774,1.331-3.638,3.327a13.241,13.241,0,0,1-5.9,3.372c-.71.177-1.065.133-1.2.621-.31,1.242-.266,4.259,1.641,5.989a.809.809,0,0,0,.8.177A6.845,6.845,0,0,0,23.709,21a.3.3,0,0,1,.355.133,5.689,5.689,0,0,1,.667,1.907,4.839,4.839,0,0,1,0,2c-.044.222.044.532.532.665s2.174.443,2.662.532.71-.266.443-.665a15.192,15.192,0,0,1-1.109-2.085l.31-.4a.453.453,0,0,0,.088-.4L27.3,21.618a.477.477,0,0,0-.4-.355c-.133,0-.266-.045-.4-.045-.088-.4-.133-.621-.133-.621a5.865,5.865,0,0,1,3.771.355c2.085.887,3.638,1.553,4.924,1.242s1.819-2.44,1.908-4.037A17.219,17.219,0,0,0,35.2,10.84Z" transform="translate(-20.369 -6.353)" fill="none" stroke="#673ab7" strokeMiterlimit="10" strokeWidth="5.12" />
                                    <path d="M45.019,10.18c-.843.222-1.109,2-.843,4.35a2.353,2.353,0,0,1,1.908,1.819,2.4,2.4,0,0,1-.754,2.573c.887,2.262,2.085,3.771,2.928,3.55,1.109-.31,1.242-3.283.355-6.655C47.725,12.4,46.128,9.912,45.019,10.18Z" transform="translate(-33.558 -7.733)" fill="none" stroke="#673ab7" strokeMiterlimit="10" strokeWidth="5.12" />
                                    <path d="M57.977,8.422a.4.4,0,0,1-.443-.133.482.482,0,0,1,0-.665l2.75-2.795a.471.471,0,0,1,.666.666L58.2,8.245C58.11,8.333,58.021,8.378,57.977,8.422Z" transform="translate(-40.971 -4.691)" fill="none" stroke="#673ab7" strokeMiterlimit="10" strokeWidth="5.12" />
                                    <path d="M66.364,30.831H66.1l-3.771-1.02a.479.479,0,1,1,.222-.931l3.771,1.02a.494.494,0,0,1,.355.577A.507.507,0,0,1,66.364,30.831Z" transform="translate(-43.501 -18.139)" fill="none" stroke="#673ab7" strokeMiterlimit="10" strokeWidth="5.12" />
                                    <path d="M65.76,17.8l-4.3,1.153a.48.48,0,0,1-.224-.932l4.3-1.153a.443.443,0,0,1,.577.355A.509.509,0,0,1,65.76,17.8Z" transform="translate(-42.897 -11.454)" fill="none" stroke="#673ab7" strokeMiterlimit="10" strokeWidth="5.12" />
                                    <path d="M35.2,10.84c-1.242-2.4-2.75-3.416-3.95-3.105s-1.774,1.331-3.638,3.327a13.241,13.241,0,0,1-5.9,3.372c-.71.177-1.065.133-1.2.621-.31,1.242-.266,4.259,1.641,5.989a.809.809,0,0,0,.8.177A6.845,6.845,0,0,0,23.709,21a.3.3,0,0,1,.355.133,5.689,5.689,0,0,1,.667,1.907,4.839,4.839,0,0,1,0,2c-.044.222.044.532.532.665s2.174.443,2.662.532.71-.266.443-.665a15.192,15.192,0,0,1-1.109-2.085l.31-.4a.453.453,0,0,0,.088-.4L27.3,21.618a.477.477,0,0,0-.4-.355c-.133,0-.266-.045-.4-.045-.088-.4-.133-.621-.133-.621a5.865,5.865,0,0,1,3.771.355c2.085.887,3.638,1.553,4.924,1.242s1.819-2.44,1.908-4.037A17.219,17.219,0,0,0,35.2,10.84Z" transform="translate(-20.369 -6.353)" fill="#fff" />
                                    <path d="M45.019,10.18c-.843.222-1.109,2-.843,4.35a2.353,2.353,0,0,1,1.908,1.819,2.4,2.4,0,0,1-.754,2.573c.887,2.262,2.085,3.771,2.928,3.55,1.109-.31,1.242-3.283.355-6.655C47.725,12.4,46.128,9.912,45.019,10.18Z" transform="translate(-33.558 -7.733)" fill="#673ab7" />
                                    <path d="M57.977,8.422a.4.4,0,0,1-.443-.133.482.482,0,0,1,0-.665l2.75-2.795a.471.471,0,0,1,.666.666L58.2,8.245C58.11,8.333,58.021,8.378,57.977,8.422Z" transform="translate(-40.971 -4.691)" fill="#fff" />
                                    <path d="M66.364,30.831H66.1l-3.771-1.02a.479.479,0,1,1,.222-.931l3.771,1.02a.494.494,0,0,1,.355.577A.507.507,0,0,1,66.364,30.831Z" transform="translate(-43.501 -18.139)" fill="#fff" />
                                    <path d="M65.76,17.8l-4.3,1.153a.48.48,0,0,1-.224-.932l4.3-1.153a.443.443,0,0,1,.577.355A.509.509,0,0,1,65.76,17.8Z" transform="translate(-42.897 -11.454)" fill="#fff" />
                                </g>
                            </g>
                        </svg>
        }
    }
}