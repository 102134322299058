import React from 'react';
import i18n from '../../../../i18n';
import { withTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import TagMultipleElement from '../../form/elements/TagMultipleElement';
import PmyBtn from '../../button/PmyBtn';
import Joyride from 'react-joyride';

class Intro extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sectionId: null,
            intro: '',           
            introList: [],
            introFocus: false,
            introHover: false,
            errorLimit: false,
            errorLength: false,
            errorChar: false,
            errorDuplicate: false,
            isLoading: false,
            introIsGenerated: false,
            steps: [
                {
                    target: '.title',
                    content: this.props.t('tour.title'),
                  },
                  {
                    target: '.introduction',
                    content: this.props.t('tour.intro'),
                  },
                  {
                    target: '.section',
                    content: this.props.t('tour.section'),
                  },
              ]
        }
        this.handleIntroHover = this.handleIntroHover.bind(this);
        this.handleIntroHoverLeave = this.handleIntroHoverLeave.bind(this);
        this.handleIntroFocus = this.handleIntroFocus.bind(this);
        this.handleIntro = this.handleIntro.bind(this);
        this.handleCreateIntroTag = this.handleCreateIntroTag.bind(this);
        this.handleRemoveTag = this.handleRemoveTag.bind(this);
        this.generateIntro = this.generateIntro.bind(this);
    }

    handleIntroHover() {
        this.setState({
            introHover: true
        });
    }

    handleIntroHoverLeave() {
        this.setState({
            introHover: false
        });
    }

    handleIntroFocus() {
        this.setState({
            introFocus: this.state.introFocus ? false : true
        });
    }

    componentDidMount() {
        
        for(var i = 0; i < this.props.sections.length; i++) {
            if(this.props.sections[i][2] === 'intro') {
                this.setState({
                    intro: this.props.sections[i][1],
                    introIsGenerated: true,
                    sectionId: this.props.sections[i][0]
                });
            } 
            else {
                this.setState({
                    intro: '',
                    introIsGenerated: false,
                    sectionId: null
                });
            }
        }
    }

    componentWillReceiveProps() { 
        for(var i = 0; i < this.props.sections.length; i++) {
            if(this.props.sections[i][2] === 'intro') {
                console.log(this.props.sections[i][1])
                this.setState({
                    intro: this.props.sections[i][1],
                    introIsGenerated: true,
                    sectionId: this.props.sections[i][0]
                });
            }
        }
    }

    handleIntro(e) {
        var content = e.target.value;
        var token = localStorage.getItem('af_token');
        fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/articles/sections/write', {
            headers: {
                'Authorization': token
            },
            method: 'POST',
            body: JSON.stringify({ id: this.state.sectionId, content: content})
        })
        .then((res) => {
            return res.json();
        })
        .then((res) => {

        })
        this.setState({
            intro: content
        }, () => {
            if(this.state.intro.length >= 2 || this.state.intro.match(/^[a-zA-Z0-9\u00C0-\u00FF\s]*$/)) {
                this.setState({
                    errorLength: false,
                    errorChar: false,
                    errorDuplicate: false,
                    errorLimit: false
                });
            }
        });
    }

    handleCreateIntroTag(e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            if (this.state.introList.length === 10) {
                this.setState({ errorLimit: true });
            }
            else if (this.state.intro.length <= 1 && !this.state.intro.match(/^[a-zA-Z0-9\u00C0-\u00FF\s]*$/)) {
                this.setState({
                    errorLength: true,
                    errorChar: true
                });
            }
            else if (this.state.intro.length <= 1) {
                this.setState({ errorLength: true });
            }
            else if (!this.state.intro.match(/^[a-zA-Z0-9\u00C0-\u00FF\s]*$/)) {
                this.setState({ errorChar: true });
            }
            else if (this.state.introList.includes(this.state.intro)) {
                this.setState({ errorDuplicate: true });
            }
            else {
                var tagList = this.state.introList;
                this.setState({
                    intro: '',
                    tagList: this.state.introList
                });
                tagList.push(this.state.intro);
            }
        }
    }

    handleRemoveTag(value) {
        var arr = this.state.introList;
        var index = arr.indexOf(value);
        if (index > -1) {
            this.setState({
                arr: arr.splice(index, 1)
            });
        }
    }

    generateIntro(e) {
        var token = localStorage.getItem('af_token');
        if(e.type == 'mousedown') {
            this.setState({
                isLoading: true
            });
            fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/generate-intro', {
                headers: {
                    'Authorization': token
                },
                method: 'POST',
                body: JSON.stringify({ keywords: this.state.introList.toString(), lang: i18n.languages[0] })
            })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                this.setState({
                    isLoading: false,
                    introIsGenerated: true,
                    intro: res.message.choices[0].text.trim(),
                    introList: []
                });
                if(!this.state.sectionId) {
                    fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/articles/sections/create', {
                        headers: {
                            'Authorization': token
                        },
                        method: 'POST',
                        body: JSON.stringify({ article_id: this.props.articleId, content: res.message.choices[0].text.trim(), type: 'intro' })
                    })
                    .then((res) => {
                        return res.json();
                    })
                    .then((res) => {
                        this.setState({
                            sectionId:res.message
                        });
                    })
                } 
                else {
                    fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/articles/sections/write', {
                        headers: {
                            'Authorization': token
                        },
                        method: 'POST',
                        body: JSON.stringify({ id: this.state.sectionId, content: res.message.choices[0].text.trim() })
                    })
                    .then((res) => {
                        return res.json();
                    })
                }
            })
        }
        e.preventDefault();  
    }

    render() {
        const { t } = this.props;
        const { steps } = this.state;

        const tags = ' tags-container d-flex flex-row flex-wrap';

        return (
            <>
                                                  <Joyride
                steps={steps}
                showProgress={true}
                disableScrolling={true}
                />  
                <p class={!this.state.introFocus ? 'd-none' : 'mb-2'}>{t('form.label.writingFranklin.intro')} <br/> <span class="fz-14">{t('form.label.writingFranklin.introDetails')}</span></p>
                <div 
                    onMouseEnter={this.handleIntroHover}
                    onMouseLeave={this.handleIntroHoverLeave}
                    class={
                        this.state.introFocus && this.state.introList.length >= 1 ? 'tags-container-focus p-2' + tags 
                        : this.state.introFocus ? 'tags-container-focus' + tags 
                        : this.state.introHover && this.state.introList.length >= 1 ? 'tags-container-hover p-2' + tags 
                        : this.state.introHover ? 'tags-container-hover' + tags
                        : this.state.introList.length >= 1 ? 'p-2' + tags 
                        : tags
                    }
                >
                    {this.state.introList.map((intro, index) => {
                        return (
                            <TagMultipleElement
                                handleSelect={this.handleRemoveTag}
                                key={intro}
                                index={index}
                                value={intro}
                                label={intro} 
                                isSelected={true}
                            />
                        );
                    })}
                </div>

                <TextareaAutosize 
                    onChange={this.handleIntro} 
                    onKeyDown={this.handleCreateIntroTag} 
                    onFocus={this.handleIntroFocus} 
                    onBlur={this.handleIntroFocus} 
                    onMouseEnter={this.handleIntroHover} 
                    onMouseLeave={this.handleIntroHoverLeave} 
                    placeholder={t('form.input.placeholderWritingFranklin.intro')}
                    value={this.state.intro}
                    class={
                        this.state.errorLimit || this.state.errorLength || this.state.errorChar || this.state.errorDuplicate ? 'mb-0'
                        : this.state.introList.length >= 1 && this.state.introFocus ? 'introduction introduction-focus top-radius-0'
                        : this.state.introList.length >= 1 && this.state.introHover ? 'introduction introduction-hover top-radius-0'
                        : this.state.introList.length >= 1 ? 'introduction top-radius-0'
                        : 'introduction'
                    }                    
                />

                {this.state.errorLimit && this.state.introFocus && !this.state.introIsGenerated && <p class="intro-error fz-14">{t('alert.writingFranklin.introLimit')}</p> }
                {this.state.errorLength && this.state.introFocus && !this.state.introIsGenerated && <p class="intro-error fz-14">{t('alert.writingFranklin.introLength')}</p> }
                {this.state.errorChar && this.state.introFocus && !this.state.introIsGenerated && <p class="intro-error fz-14">{t('alert.writingFranklin.introChar')}</p> }
                {this.state.errorDuplicate && this.state.introFocus && !this.state.introIsGenerated && <p class="intro-error fz-14">{t('alert.writingFranklin.introDuplicate')}</p> }
                
                {this.state.introFocus &&
                    <PmyBtn 
                        onMouseDown={this.generateIntro} 
                        type="button" 
                        isDisabled={this.state.introList.length === 0} btnIsSmallPmyFull textBtn={this.state.isLoading ? <div class="spinner spinner-light"></div> : t('writingFranklin.writingMode.ctaGenerateIntro')} 
                        title={this.state.introList.length === 0 ? t('titleElementBrowser.writingFranklin.generateIntro') : this.state.isLoading ? t('actions.loading') : t('writingFranklin.writingMode.ctaGenerateIntro')} 
                        containerStyle={this.state.intro.length === 0 ? 'mb-5' : this.state.introIsGenerated ? 'd-none' : 'mb-5'} 
                        className={this.state.errorLimit || this.state.errorLength || this.state.errorChar || this.state.errorDuplicate ? 'generate-intro fz-16-index mt-2' : 'generate-intro fz-16-index'}
                    />
                }
            </>
        )
    }
}

export default withTranslation()(Intro);