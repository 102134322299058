import React from 'react';
import i18n from './i18n';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import CookieConsent from 'react-cookie-consent';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Banner from './pages/components/partials/Banner';
import Navbar from './pages/components/partials/Navbar';
import Footer from './pages/components/partials/Footer';
import Home from './pages/sorted-pages/free-area/Home';
import AskingFranklin from './pages/sorted-pages/deep-area/features/AskingFranklin';
import WritingFranklin from './pages/sorted-pages/deep-area/features/WritingFranklin';
import WritingMode from './pages/sorted-pages/deep-area/features/WritingMode';
import Pricing from './pages/sorted-pages/free-area/Pricing';
import SignIn from './pages/sorted-pages/free-area/sign/SignIn';
import SignUp from './pages/sorted-pages/free-area/sign/SignUp';
import SignUpConfirmation from './pages/sorted-pages/free-area/sign/SignUpConfirmation';
import ForgotPassword from './pages/sorted-pages/free-area/sign/ForgotPassword';
import MaximumRequests from './pages/sorted-pages/deep-area/MaximumRequests';
import Faq from './pages/sorted-pages/free-area/Faq';
import Contact from './pages/sorted-pages/free-area/Contact';
import LegalNotice from './pages/sorted-pages/free-area/law/LegalNotice';
import TermsOfServices from './pages/sorted-pages/free-area/law/TermsOfServices';
import TermsOfSales from './pages/sorted-pages/free-area/law/TermsOfSales';
import Error404 from './pages/sorted-pages/free-area/Error404';
import Profile from './pages/sorted-pages/deep-area/Profile';
import ConfirmationPayment from './pages/sorted-pages/deep-area/ConfirmationPayment';

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isConnected: false,
            isPro: false,
            bannerIsShowed: false,
            navIsHidden: false,
            footerIsHidden: false,
            redirect: false
          
        }
        this.handleLanguageManual = this.handleLanguageManual.bind(this);
        this.handleLanguage = this.handleLanguage.bind(this);
        this.handleConnect = this.handleConnect.bind(this);
        this.handleHideBanner = this.handleHideBanner.bind(this);
        this.handleShowedNavbar = this.handleShowedNavbar.bind(this);
        this.handleShowedFooter = this.handleShowedFooter.bind(this);
    }

    componentDidMount() {
        var token = localStorage.getItem('af_token');
        var is_sub = localStorage.getItem('af_is_sub');
        this.setState({
            isConnected: (token && token.length > 0) ? true : false
        });
        var userLanguage = window.navigator.userLanguage || window.navigator.language;
        if(!userLanguage.includes("fr") && window.location.href === "https://www.askingfranklin.com/"){
            this.handleLanguageManual('en')
        }
        if (token && token.length > 0 && is_sub == '1') {
            this.setState({
                isPro: true,
                bannerIsShowed: false
            });
        }
        // Navigator langage detector
        var userLanguage = window.navigator.userLanguage || window.navigator.language;
        // console.log(window.location.href);
        if (!userLanguage.includes('fr') && window.location.href === 'https://www.askingfranklin.com/') {
            this.handleLanguageManual('en');
        }
    }

    customHeadElement() {
        return (
            <Helmet>
                <link rel="alternate" hreflang="fr-fr" href="https://askingfranklin.com/"/>
                <link rel="alternate" hreflang="en-us" href="https://askingfranklin.com/en"/>
            </Helmet>
        );
    }

    handleLanguageManual(lang) {
        i18n.changeLanguage(lang, () => {
            this.setState({
                redirect: true
            });
        });
    }

    handleLanguage(e) {
        const lang = e.target.dataset.lang;
        i18n.changeLanguage(lang, () => {
            this.setState({
                redirect: true
            });
        });
    }

    handleConnect() {
        var is_sub = localStorage.getItem('af_is_sub');
        this.setState({
            isConnected: this.state.isConnected ? false : true,
            isPro: is_sub == '1' && true,
            bannerIsShowed: is_sub <= '0' && this.state.bannerIsShowed ? true : false || is_sub == '1' && false,
            redirect: true,
            toPlan: (is_sub === null || is_sub[0] === null) ? true : false
        });
    }
    
    handleHideBanner() {
        this.setState({
            bannerIsShowed: false
        });
    }

    handleShowedNavbar() {
        this.setState({
            navIsHidden: true
        });
    }
    
    handleShowedFooter() {
        this.setState({
            footerIsHidden: true
        });
    }

    render() {
        const { t } = this.props;
        const { redirect } = this.state;

        return (
            <>



                {this.customHeadElement()}
                {this.state.bannerIsShowed && <Banner onClick={this.handleHideBanner} bannerIsActive={this.state.bannerIsShowed}/>}
                <Router>

                    {redirect && <Redirect to={t('url.home')}/>}
                    <Navbar className={this.state.bannerIsShowed && 'banner-showed'} hidden={this.state.navIsHidden} isConnected={this.state.isConnected} isPro={this.state.isPro}/>
                    <Switch>
                        <Route path={t('url.signIn')} render={(props) => <SignIn {...props} bannerIsActive={this.state.bannerIsShowed} handleConnect={this.handleConnect}/>}/>
                        <Route exact path={t('url.signUpConfirm')} render={(props) => <SignUpConfirmation {...props} bannerIsActive={this.state.bannerIsShowed}/>}/>
                        <Route path={t('url.signUp')} render={(props) => <SignUp {...props} bannerIsActive={this.state.bannerIsShowed}/>}/>
                        <Route path={t('url.forgotPassword')}  render={(props) => <ForgotPassword {...props} bannerIsActive={this.state.bannerIsShowed}/>}/>
                        <Route path={t('url.resultPageAF')}  render={(props) => <AskingFranklin {...props} bannerIsActive={this.state.bannerIsShowed}/>}/>
                        <Route path={t('url.editorWF')} render={(props) => <WritingFranklin {...props} bannerIsActive={this.state.bannerIsShowed} isConnected={this.state.isConnected} isPro={this.state.isPro}/>}/>
                        <Route path={t('url.editorModeWF')} render={(props) => <WritingMode {...props} bannerIsActive={this.state.bannerIsShowed} isConnected={this.state.isConnected} isPro={this.state.isPro} handleFuncNavbar={this.handleShowedNavbar} handleFuncFooter={this.handleShowedFooter}/>}/>
                        <Route path={t('url.searchLimit')} render={(props) => <MaximumRequests {...props} bannerIsActive={this.state.bannerIsShowed}/>} isConnected={this.state.isConnected}/>
                        <Route path={t('url.faq')} render={(props) => <Faq {...props} bannerIsActive={this.state.bannerIsShowed}/>}/>
                        <Route path={t('url.contact')} render={(props) => <Contact {...props} bannerIsActive={this.state.bannerIsShowed}/>}/>
                        <Route exact path={t('url.home')} render={(props) => <Home {...props} bannerIsActive={this.state.bannerIsShowed} isConnected={this.state.isConnected} isPro={this.state.isPro}/>}/>
                        <Route exact path={t('url.pricing')} render={(props) => <Pricing {...props} bannerIsActive={this.state.bannerIsShowed} isConnected={this.state.isConnected}/>}/>
                        <Route exact path={t('url.profile')} render={(props) => <Profile {...props} bannerIsActive={this.state.bannerIsShowed} isPro={this.state.isPro}/>}/>
                        <Route exact path={t('url.paymentConfirm')} render={(props) => <ConfirmationPayment {...props} bannerIsActive={this.state.bannerIsShowed}/>}/>
                        <Route path={t('url.notice')} render={(props) => <LegalNotice {...props} bannerIsActive={this.state.bannerIsShowed}/>}/>
                        <Route path={t('url.tcs')} render={(props) => <TermsOfServices {...props} bannerIsActive={this.state.bannerIsShowed}/>}/>
                        <Route path={t('url.gtcs')} render={(props) => <TermsOfSales {...props} bannerIsActive={this.state.bannerIsShowed}/>}/>
                        <Route path="*" render={(props) => <Error404 {...props} bannerIsActive={this.state.bannerIsShowed}/>} status={404}/>
                    </Switch>
                    <Footer onClickLanguage={this.handleLanguage} hidden={this.state.footerIsHidden} isPro={this.state.isPro}/>
                </Router>
                <a href="https://www.producthunt.com/posts/asking-franklin?utm_source=badge-featured&amp;utm_medium=badge&amp;utm_souce=badge-asking-franklin" target="_blank" class="d-none d-md-flex position-fixed w-fit-content" style={{ bottom: '16px', left: '16px', zIndex: 95 }}>
                    <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=301122&amp;theme=light" alt="Asking Franklin is on Product Hunt" style={{ height: '48px' }}/>
                </a>
                <CookieConsent location="bottom" acceptOnScroll={true} buttonText={t('cookiesBar.btn')} cookieName="user-has-accepted-cookies" expires={182}>
                    {t('cookiesBar.text')} &nbsp;<a href={t('url.gtcs')} target="_blank" rel="noopener" title={t('titleElementBrowser.tcs')}>{t('cookiesBar.information')}</a>
                </CookieConsent>
            </>
        )
    }
}

export default withTranslation()(App);