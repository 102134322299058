import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import ArrowLight from '../../../../assets/img/svg/ArrowLight';
import MenuLink from '../../../components/elements/link/MenuLink';
import Settings from '../../../../assets/img/svg/navigation/Settings';
import LateralModal from '../../../components/partials/modals/LateralModal';
import H3 from '../../../components/elements/title/H3';
import Alert from '../../../components/elements/Alert';

const settings = {
    'low': 0.68,
    'middle': 0.75,
    'high': 0.83,
    'short': 48,
    'long': 64
};

class Topbar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentCreativity: 0.75,
            currentTokens: 48,
            defaultCreativitySelected: true,
            defaultLengthSelected: true,
            creativitySelected: '',
            textLengthSelected: '',
            successParam: false
        }
        this.backHome = this.backHome.bind(this);
        this.getCreativity = this.getCreativity.bind(this);
        this.getTextLength = this.getTextLength.bind(this);
        this.handleModalCloseNoSaved = this.handleModalCloseNoSaved.bind(this);
        this.handleSavedParameters = this.handleSavedParameters.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            creativitySelected: nextProps.creativity,
            textLengthSelected: nextProps.textLength
        })
    }

    backHome() {
        window.history.back();
    }
    
    getCreativity(e) {
        let getType = e.target.dataset.type;
        this.setState({
            defaultCreativitySelected: false,
            creativitySelected: getType
        });
    }

    getTextLength(e) {
        let getType = e.target.dataset.type;
        this.setState({
            defaultLengthSelected: false,
            textLengthSelected: getType
        });
    }

    handleModalCloseNoSaved() {
        this.setState({
            defaultCreativitySelected: true,
            defaultLengthSelected: true
        });
    }

    handleSavedParameters() {
        var token = localStorage.getItem('af_token');
        fetch('https://te3t29re5k.execute-api.eu-west-1.amazonaws.com/dev/askingfranklin/articles/save', {
            headers: {
                'Authorization': token
            },
            method: 'POST',
            body: JSON.stringify({ creativity: this.state.creativitySelected, textLength: this.state.textLengthSelected, id_article: this.props.articleId })
        })
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            this.props.updateSettings({ creativity: settings[this.state.creativitySelected], tokens: settings[this.state.textLengthSelected ]});
            this.setState({
                successParam: true
            }, () => {
                setTimeout(() => {
                    this.setState({
                        successParam: false
                    });
                }, 5000);
            });
        })
    }

    handleCloseAlert() {
        this.setState({
            successParam: false
        });
    }

    render() {

        const { t } = this.props;

        const navClass = ' nav nav-funnel px-4 px-xl-5 py-2 w-100 d-flex justify-content-center position-absolute';

        const parameters = 
            <div class="writing-franklin-header-bottom state-interaction-element">
                <Settings width="16" fill="#2B2B2B"/>
            </div>;

        return (
            <>
                {this.state.successParam && <Alert onClick={this.handleCloseAlert} className={this.state.successParam && !this.props.bannerIsActive ? 'alert-msg-visible alert-msg-no-banner' : this.state.successParam ? 'alert-msg-visible' : ''} alertId="successMessage" msg={t('alert.writingFranklin.iaParam')}/> }

                <nav class={this.props.bannerIsActive ? 'banner-showed' + navClass : navClass}>
                    <Container className="px-0 d-flex flex-lg-row align-items-center justify-content-lg-center">
                        <div class="d-flex flex-row align-items-center w-100 mr-sm-4">
                            <MenuLink href={t('url.editorWF')} textLink={t('writingFranklin.topbar.back')} linkHasIcon={<ArrowLight width="16" fill="#2B2B2B"/>}/>
                        </div>
                        <div class="d-flex flex-row justify-content-between align-items-center">
                            {/* Modal AI Parameters */}
                            <LateralModal 
                                handleModalCloseNoSaved={this.handleModalCloseNoSaved}
                                handleClickCtaBtn={this.handleSavedParameters} 
                                trigger={parameters} 
                                triggerTitle={t('titleElementBrowser.writingFranklin.params')}
                                modalTitle={t('writingFranklin.lateralModal.title')}
                                ctaFooterPrimary={t('writingFranklin.lateralModal.ctaFooterPrimary')}
                                updateSettings={this.props.updateSettings}
                            >
                                <H3 title={t('writingFranklin.lateralModal.content.creativity.title')} className="mb-3"/>
                                <div class="btn-choices-container d-flex flex-row">
                                    <button onClick={this.getCreativity} type="button" data-type="low" class={this.state.creativitySelected === 'low' && 'parameter-selected'}>{t('writingFranklin.lateralModal.content.creativity.level.low')}</button>
                                    <button onClick={this.getCreativity} type="button" data-type="middle" class={this.state.creativitySelected ? 'parameter-selected' : this.state.creativitySelected === 'middle' && 'parameter-selected'}>{t('writingFranklin.lateralModal.content.creativity.level.medium')}</button>
                                    <button onClick={this.getCreativity} type="button" data-type="high" class={this.state.creativitySelected === 'high' && 'parameter-selected'}>{t('writingFranklin.lateralModal.content.creativity.level.high')}</button>
                                </div>
                                <div class="bgc-info mt-3 p-3 rounded">
                                    <p class="mb-3">{t('writingFranklin.lateralModal.content.creativity.subtitle')}</p>
                                    <p class="fz-14">{t('writingFranklin.lateralModal.content.creativity.p1')}<br/>{t('writingFranklin.lateralModal.content.creativity.p2')}</p>
                                </div>
                                <H3 title={t('writingFranklin.lateralModal.content.textLength.title')} className="mt-5 mb-3"/>
                                <div class="btn-choices-container d-flex flex-row">
                                    <button onClick={this.getTextLength} type="button" data-type="short" class={this.state.textLengthSelected ? 'parameter-selected' : this.state.textLengthSelected === 'short' && 'parameter-selected'}>{t('writingFranklin.lateralModal.content.textLength.level.short')}</button>
                                    <button onClick={this.getTextLength} type="button" data-type="long" class={this.state.textLengthSelected === 'long' && 'parameter-selected'}>{t('writingFranklin.lateralModal.content.textLength.level.long')}</button>
                                </div>
                                <div class="bgc-info mt-3 p-3 rounded">
                                    <p class="mb-3">{t('writingFranklin.lateralModal.content.textLength.subtitle')}</p>
                                    <p class="fz-14">{t('writingFranklin.lateralModal.content.textLength.p1')}<br/>{t('writingFranklin.lateralModal.content.textLength.p2')}</p>
                                </div>
                            </LateralModal>
                        </div>
                    </Container>
                </nav>
            </>
        )
    }
}

export default withTranslation()(Topbar);