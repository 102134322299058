import React from 'react';

export default class FeaturesCta extends React.Component {

    render() {

        switch(this.props.icon) {

            case 'idea':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 23.385" width="16" height="23.385">
                        <path d="M16.077,23.923a2.461,2.461,0,0,1-2.462,2.462H12.385a2.462,2.462,0,0,1-2.462-2.462h1.231a1.231,1.231,0,0,0,1.231,1.231h1.231a1.231,1.231,0,0,0,1.231-1.231Zm1.231-3.692a2.461,2.461,0,0,1-2.462,2.462H11.154a2.462,2.462,0,0,1-2.462-2.462V17.742a8,8,0,1,1,8.615,0Zm-7.385,0a1.231,1.231,0,0,0,1.231,1.231h3.692a1.231,1.231,0,0,0,1.231-1.231v-3.2a6.769,6.769,0,1,0-6.154,0ZM8.851,11.765l2.919-2.919,2.462,2.462,2.154-2.154.87.87-3.024,3.024-2.462-2.462L9.721,12.635Z" transform="translate(-5 -3)"/>
                    </svg>
                );

            case 'trends':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16">
                        <path d="M13.6,5H20v6.4H18.937V6.821L4.759,21,4,20.246,18.183,6.067H13.6Z" transform="translate(-4.004 -5)"/>
                    </svg>
                )

            case 'write':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width={this.props.width} fill={this.props.fill}>
                        <path d="M18.723,7.8,16.528,10,13,6.469l2.195-2.195a.94.94,0,0,1,1.331,0l2.2,2.2A.94.94,0,0,1,18.723,7.8ZM3,16.469,12.472,7,16,10.526,6.529,20H3ZM15.819,4.982,14.371,6.431l2.2,2.2L18.018,7.18ZM14.63,10.568l-2.2-2.2L3.941,16.86v2.2h2.2Z" transform="translate(-2.999 -3.998)"/>
                    </svg>
                )

        }

    }
}